import React from "react";
import { Link } from "react-router-dom";
import { Flex, Image, MenuButton, Text } from "theme-ui";

interface Navbarprops {
  broken?: boolean;
  setToggled?: (() => void) | undefined;
}

export const Navbar: React.FC<Navbarprops> = ({
  broken = false,
  setToggled = () => null,
}) => {
  return (
    <Flex as="nav" px={20} py={[3, 4]} className="navbar" backgroundColor="white">
      <Flex sx={{ alignItems: 'center', flexWrap: ["wrap", 'nowrap'] }}>
        <Link to={'/'} >
          <Image
            sx={{}}
            src="/logo.svg"
            alt="logo"
            mr={[3, 48]}
            variant="NavbarLogo"
          />
        </Link>
        <Text variant="Maison24Demi33lh" color="black">
          Analytical-Dashboard
        </Text>
      </Flex>
      {/*   <Flex
        sx={{
          gap: [20, null, null, 80],
          display: ["none", null, "flex"],
          flex: 1,
          justifyContent: "flex-end",
        }}
      >
         <Searchbar placeholder="Search" />
        <Flex sx={{ gap: 30 }}>
          <Flex sx={{ gap: 18, alignItems: "center" }}>
            <Image
              sx={{ width: 28, height: 28 }}
              src="/Share.svg"
              alt="Share"
            />
            <Image
              sx={{ width: 28, height: 28 }}
              src="/NewTab.svg"
              alt="NewTab"
            />
          </Flex>
          <Flex sx={{ alignItems: "center" }}>
            <Image sx={{}} src="/User.svg" alt="User" />
            <Flex sx={{ flexDirection: "column" }} ml="7px">
              <Text variant="Maison16Demi16lh" mb={10} color="grey11">
                vikas@xyz.com
              </Text>
              <Text variant="Maison12Regular15lh" color="grey11">
                Admin
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>*/}
      {broken && <MenuButton aria-label="Toggle Menu" onClick={setToggled} />}
    </Flex>
  );
};

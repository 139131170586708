import React from "react";
import { Tooltip } from "react-tooltip";
import { Box, Card, Flex, Image, Text } from "theme-ui";
import { PieChart } from "../Charts/pie-chart";
import { LoaderChartCard } from "./LoderChartCard";
import { NoDataCard } from "./NoData";
import { Link, To } from "react-router-dom";
interface PieChartCardprops {
  title?: string;
  backgroundColor?: Array<string>;
  Data?: Array<number>;
  showCounts?: boolean;
  labels?: Array<string>;
  id?: string;
  isLoader?: boolean;
  Counts?: string;
  toolTip?: string;
  link?: To;
}
export const PieChartCard: React.FC<PieChartCardprops> = ({
  title = "",
  backgroundColor = [],
  Data = [],
  showCounts = false,
  labels = [],
  id = "",
  isLoader,
  Counts,
  toolTip,
  link,
}) => {
  return (
    <Card backgroundColor="white" py={20} px={3} variant="DashboardCard" sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ borderBottom: "1px solid #ECE1D7" }} pb={2} mb={12}>
        <Flex data-tooltip-id="Card-tooltip" sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Text variant="Maison12RegularNormalLh" color="black">
            {title}
          </Text>
          {link && (
            <Link to={link}>
              <Image src="/fluent_document-data-16-regular.svg" alt="img" />
            </Link>
          )}
        </Flex>
        {!isLoader && showCounts ? (
          <Text variant="Maison18DemiNormalLh" color="black">
            {Counts}
          </Text>
        ) : null}
      </Box>
      {isLoader ? (
        <LoaderChartCard />
      ) : (
        <React.Fragment>
          {Data.length > 0 && Data.filter(e => e).length > 0 ? (
            <PieChart
              backgroundColor={backgroundColor}
              Data={Data}
              labels={labels}
              id={id}
            />
          ) : (
            <NoDataCard />
          )}
        </React.Fragment>
      )}
      <Tooltip
        id="Card-tooltip"
        content={toolTip}
        place="bottom"
        style={{ zIndex: 999999 }}
      />
    </Card>
  );
};

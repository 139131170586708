import React from "react";
import { Navigate } from "react-router-dom";
import { Box, Flex } from "theme-ui";
import { Navbar } from "./Navbar";
import { SideBar } from "./SideBar/Sidebar";

interface PageLayOutprops {
  children: React.ReactNode;
  isPrivate?: boolean;
  isProtected?: boolean;
  noLayout?: boolean;
}

export const PageLayOut: React.FC<PageLayOutprops> = ({ children, isPrivate = false, isProtected = false, noLayout = false }) => {
  const isAuthenticated = localStorage.getItem('token');
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(
    window.matchMedia("(max-width: 1039px)").matches
  );
  if (isPrivate && !isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  else if (isProtected && isAuthenticated) {
    return <Navigate to="/" replace />
  }
  return noLayout ? (
    <React.Fragment>
      {children}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Navbar broken={broken} setToggled={() => setToggled(!toggled)} />
      <Flex>
        <SideBar
          toggled={toggled}
          setBroken={setBroken}
          onBackdropClick={() => setToggled(false)}
        />
        <Box sx={{ flex: 1 }}>{children}</Box>
      </Flex>
    </React.Fragment>
  )
    ;
};

import { appReducer } from "../../../@types/reducer";
import { SET_ALL_DATE_RANGE, SET_COM_DATE_RANGE } from "../types";
var currentDate = new Date();
var preDate = new Date(new Date().setDate(new Date().getDate() - 1));
const INIT_STATE = {
    dateRange: [{
        startDate: new Date(currentDate.setHours(0, 0, 0, 0)),
        endDate: new Date(currentDate.setHours(23, 59, 59, 59)),
        key: "selection",
    }],
    comDateRange: [{
        startDate: new Date(preDate.setHours(0, 0, 0, 0)),
        endDate: new Date(preDate.setHours(23, 59, 59, 59)),
        key: "selection",
    }],
} as appReducer;

function app(state = INIT_STATE, action: { type: any; payload: any; }) {
    switch (action.type) {
        case SET_ALL_DATE_RANGE: {
            return {
                ...state,
                dateRange: action.payload
            }
        }
        case SET_COM_DATE_RANGE: {
            return {
                ...state,
                comDateRange: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

export default app;

import { ChartData } from "chart.js";
import React from "react";
import { Link, To } from "react-router-dom";
import { Box, Card, Flex, Image, Text, ThemeUIStyleObject } from "theme-ui";
import { BarChart } from "../Charts/bar-chart";
import { LoaderChartCard } from "./LoderChartCard";
interface BarChartCardprops {
  title?: string;
  isLoader?: boolean;
  data: ChartData<"bar", number[], number> | any;
  isHorizontal?: boolean;
  titleSx?: ThemeUIStyleObject | undefined;
  link?: To;
}
export const BarChartCard: React.FC<BarChartCardprops> = ({ title = "", isLoader, data, isHorizontal, titleSx, link }) => {
  return (
    <Card backgroundColor="white" py={20} px={3} variant="DashboardCard">
      <Box sx={{ borderBottom: "1px solid #ECE1D7" }} mb={20}>
        <Flex
          sx={{ justifyContent: "space-between", alignItems: "center" }}
          mb={27}
        >
          <Text variant="Maison12RegularNormalLh" color="black" sx={titleSx}>
            {title}
          </Text>
          {link && (
            <Link to={link}>
              <Image src="/fluent_document-data-16-regular.svg" alt="img" />
            </Link>
          )}
        </Flex>
      </Box>
      {isLoader ?
        <LoaderChartCard />
        :
        <BarChart data={data} isHorizontal={isHorizontal} />}
    </Card>
  );
};

import ReactCohortGraph from "react-cohort-graph";
import { Box, Card } from "theme-ui";
interface CohortChartProps {
  data: object;
}
export const CohortChart: React.FC<CohortChartProps> = ({ data = {} }) => {
  return (
    <Box className="cohort-graph" sx={{ height: "100%", cursor: "default", overflowX: "auto" }}>
      <ReactCohortGraph
        data={data}
        // dataType="months"
        shadeColor="#00A611"
        cellClickEvent={(e) => {
          console.log("asassas");
        }}
        columnClickEvent={(e) => {
          alert(1);
        }}
        cellFormatter={(cell: any) => {
          return cell.value.toFixed(1) + " %";
        }}
        key={"idsjhj"}
        bodyCellStyles={{ borderColor: "#ffffff", }}
        fixedTablePartStyles={{ display: "none" }}
        wrapperStyles={{ height: "100%" }}
        tableStyles={{ height: "100%", border: 0 }}
        scrollableTableContentStyles={{ height: "100%" }}
        scrollableTablePartStyles={{ overflow: "auto" }}
        tableHeadingStyles={{ border: 0 }}
        tableCellStyles={{
          minWidth: 28,
          fontSize: 10,
          fontWeight: 400,
          color: "black",
          cursor: "default",
          dispaly: "none"
        }}
        headerCellStyles={{ backgroundColor: "#ffffff", border: 0 }}
        headerFormatter={(cell: any) => {
          return cell.label.split(" ")[1];
        }}

      //   defaultValueType={["value"]}
      />
    </Box>
  );
};
interface DetailedCohortChartProps {
  data: object;
}
export const DetailedCohortChart: React.FC<DetailedCohortChartProps> = ({
  data = {},
}) => {
  return (
    <Card backgroundColor="white" py={20} px={3} mt={3} sx={{ borderRadius: 12 }}>
      <ReactCohortGraph
        data={data}
        // dataType="months"
        shadeColor="#00A611"
        wrapperStyles={{ overflowX: "scroll" }}
        bodyCellStyles={{ borderColor: "#ffffff" }}
        cellClickEvent={(e) => {
          console.log("asassas");
        }}
        columnClickEvent={(e) => {
          alert(1);
        }}
        headerCellStyles={{ backgroundColor: "#ffffff", border: 0 }}
        headerFormatter={(cell: any) => {
          // console.log("headerFormatter cell >>>", cell);
          return cell?.label
        }}
      //   defaultValueType={["value"]}
      />
    </Card>
  );
};

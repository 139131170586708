import React, { ReactElement, ReactPortal } from "react";

import { Modal } from "react-responsive-modal";
interface CustomModalProps {
  isOpen: boolean;
  closeModal: () => void;
  children:
    | ReactElement
    | string
    | number
    | ReactPortal
    | boolean
    | null
    | undefined;
  styles?: {
    root?: React.CSSProperties;
    overlay?: React.CSSProperties;
    modalContainer?: React.CSSProperties;
    modal?: React.CSSProperties;
    closeButton?: React.CSSProperties;
    closeIcon?: React.CSSProperties;
  };
  showCloseIcon?: boolean | undefined;
}
export const CustomModal: React.FC<CustomModalProps> = ({
  isOpen = false,
  closeModal = () => null,
  children,
  showCloseIcon,
  styles,
}) => {
  return (
    <Modal
      showCloseIcon={showCloseIcon}
      blockScroll={false}
      closeOnEsc={false}
      open={isOpen}
      onClose={closeModal}
      animationDuration={400}
      center
      classNames={{ modal: "custom-modal font-family-maison" }}
      // closeIcon={<Image src="/icons/close-green.svg" alt="close-green" />}
      styles={styles}
      focusTrapped={false}
    >
      {children}
    </Modal>
  );
};

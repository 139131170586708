import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Button, Card, Checkbox, Flex, Label, Text } from "theme-ui";
import { FormInput } from "../Inputs/Inputs";
const user = [
  { email: "admin@digiflux.io", password: "Asd@1234" },
  { email: "jr@delicut.ae", password: "112233" },
]
interface LoginCardprops { }
export const LoginCard: React.FC<LoginCardprops> = ({
}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  return (
    <Card backgroundColor="white" p={20} variant="LoginCard">
      <Flex sx={{ flexDirection: "column" }}>
        <Text variant="Maison24Demi33lh" color="black" >
          Sign In
        </Text>
        <FormInput
          label="Email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <FormInput
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Box mt={20}>
          <Label>
            <Checkbox defaultChecked={true} />
            <Text variant="Maison14Medium23lh" color="grey11">
              Remember me
            </Text>
          </Label>
        </Box>
        <Button
          mt={20}
          onClick={() => {
            const find = user.find((e) => username === e.email && password === e.password)
            if (find?.email) {
              localStorage.setItem('token', "admin")
              navigate("/")
              return
            } else {
              toast.error("Please enter correct user name & password")
            }
          }}>Login</Button>
      </Flex>
    </Card>
  );
};

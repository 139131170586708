import { FETCH_ERROR, SET_ALL_DATE_RANGE, SET_COM_DATE_RANGE } from "../types";

export const fetchApiError = (payload) => ({
    type: FETCH_ERROR,
    payload
});
export const setAllDateRange = (payload) => ({
    type: SET_ALL_DATE_RANGE,
    payload
});
export const setComDateRange = (payload) => ({
    type: SET_COM_DATE_RANGE,
    payload
});

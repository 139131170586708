import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Divider, Grid } from "theme-ui";
import { RootState } from "../../../@types/reducer";
import { TopBtns } from "../../Components/Buttons/TopBtns";
import { ListItemsCard } from "../../Components/Cards/ListItemsCard";
import { TitleTag } from "../../Components/Helmet";
import {
    getAllDashboardData,
    getCompareDashboardData
} from "../../store/action/dashboard.action";
import { GET_SALES_BY_SOCIAL_SOURCE_SUCCESS, GET_SESSIONS_BY_SOCIAL_SOURCE_SUCCESS, GET_SESSION_BY_BROWSER_SUCCESS } from "../../store/types";
import { dataList } from "../../utils/dashboardlist";

const Campaigns = () => {
    const [selectedTab, setSelectedTab] = useState("All");
    const { dateRange, comDateRange } = useSelector((state: RootState) => state.app);
    const {
        apiCallList,
        session_by_social_source,
        sales_by_social_source,
        session_by_browser,
    } = useSelector((state: RootState) => state.dashboard);
    const dispatch = useDispatch();
    const apicall = useMemo(() =>
        (isFirst = false) => {
            dispatch(getAllDashboardData({
                start_date: moment(dateRange?.[0]?.startDate).format("YYYY/MM/DD"),
                end_date: moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD"),
            }, isFirst));
        },
        [dateRange, dispatch]
    );
    const compareApicall = useMemo(
        () => () => {
            dispatch(
                getCompareDashboardData({
                    start_date: moment(comDateRange?.[0]?.startDate).format("YYYY/MM/DD"),
                    end_date: moment(comDateRange?.[0]?.endDate).format("YYYY/MM/DD"),
                })
            );

        },
        [comDateRange, dispatch]
    );
    useEffect(() => {
        apicall(true);
        compareApicall();
    }, [apicall, compareApicall]);

    return (
        <Box as="section">
            <TitleTag title={"Campaings"} />
            <Container px={3}>
                <Box
                    sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 99,
                        backgroundColor: "#F9F4ED",
                    }}
                >
                    <TopBtns
                        showFilter
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                    <Divider mt={0} mb={2} color="ECE1D7" />
                </Box>
                <Grid columns={[1, 2, 3]} gap={20} mb={4}>
                    {dataList
                        .filter((e) => e.filter.find((i) => i === selectedTab))
                        ?.map((item) => {
                            switch (item.id) {
                                case "Sessions by Social Source":
                                    return (
                                        <ListItemsCard
                                            title="Sessions by Social Source"
                                            isLoader={
                                                apiCallList.find(
                                                    (e) => e === GET_SESSIONS_BY_SOCIAL_SOURCE_SUCCESS
                                                )
                                                    ? true
                                                    : false
                                            }
                                            listItems={session_by_social_source}
                                            key={item.id}
                                        />
                                    );
                                case "Sales by Social Source":
                                    return (
                                        <ListItemsCard
                                            title="Sales by Social Source"
                                            link={"/report/sales_by_social_source"}
                                            isLoader={apiCallList.find((e) => e === GET_SALES_BY_SOCIAL_SOURCE_SUCCESS) ? true : false}
                                            listItems={sales_by_social_source}
                                            key={item.id}
                                        />
                                    )
                                case "Session by Browser":
                                    return (
                                        <ListItemsCard
                                            link={"/report/session_by_browser"}
                                            title="Session by Browser"
                                            isLoader={
                                                apiCallList.find(
                                                    (e) => e === GET_SESSION_BY_BROWSER_SUCCESS
                                                )
                                                    ? true
                                                    : false
                                            }
                                            listItems={session_by_browser}
                                            key={item.id}
                                        />
                                    );
                                // case "Sales by Traffic Source":
                                //     return (
                                //         <ListItemsCard

                                //             isLoader={apiCallList.find((e) => e === GET_SALES_BY_TRAFFIC_SOURCE_SUCCESS) ? true : false}
                                //             title="Sales by Traffic Source"
                                //             listItems={sales_by_traffic_source}
                                //             key={item.id} />
                                //     )
                                default:
                                    return null;
                            }
                        })}
                </Grid>
            </Container>
        </Box>
    );
};

export default Campaigns;

import { Route, Routes } from "react-router-dom";
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { PageLayOut } from "./Components/Layouts/PageLayOut";
import Index from "./Pages/Reports";
import CampingReport from "./Pages/camping-report";
import Dashboard from "./Pages/dashbords/Dashboard";
import Campaigns from "./Pages/dashbords/campaigns";
import Login from "./Pages/login";
import ViewReport from "./Pages/price-report";
const AppRoutes = () => {
    return (
        <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Routes>
                <Route path="/" element={<PageLayOut isPrivate><Dashboard /></PageLayOut>} />
                <Route path="/campaigns" element={<PageLayOut isPrivate><Campaigns /></PageLayOut>} />
                <Route path="/report/convertion_rate_by_campaign" element={<PageLayOut><CampingReport /></PageLayOut>} />
                <Route path="/report/convertion_rate_by_price_type" element={<PageLayOut><ViewReport /></PageLayOut>} />
                <Route path="/report/:slug" element={<PageLayOut><Index /></PageLayOut>} />
                <Route path="/login" element={<PageLayOut noLayout isProtected><Login /></PageLayOut>} />
            </Routes>
        </QueryParamProvider>
    )
};

export default AppRoutes;

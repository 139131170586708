/** @jsxImportSource theme-ui */
import React from "react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ReactDOM from "react-dom/client";
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from 'react-redux';
import 'react-responsive-modal/styles.css';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import { ThemeUIProvider } from "theme-ui";
import AppRoutes from "./AppRoutes";
import reportWebVitals from "./reportWebVitals";
import store from './store';
import "./styles/fonts.css";
import "./styles/index.css";
import "./styles/responsive.css";
import { theme } from "./styles/theme";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.Fragment>
    <BrowserRouter>
      <main className="font-family-maison">
        <Provider store={store}>
          <ThemeUIProvider theme={theme}>
            <AppRoutes />
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              // pauseOnFocusLoss
              draggable
              // pauseOnHover
              theme="colored"
            />
          </ThemeUIProvider>
        </Provider>
      </main>
    </BrowserRouter>
  </React.Fragment>
);
reportWebVitals();

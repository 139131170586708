import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Divider, Grid, Image } from "theme-ui";
import { RootState } from "../../../@types/reducer";
import { TopBtns } from "../../Components/Buttons/TopBtns";
import { BarChartCard } from "../../Components/Cards/BarChartCard";
import { CohortChartCard } from "../../Components/Cards/CohortChartCard";
import { DoughnutChartCard } from "../../Components/Cards/DoughnutChartCard";
import LineChartCard from "../../Components/Cards/LineChartCard";
import { ListItemsCard } from "../../Components/Cards/ListItemsCard";
import { PieChartCard } from "../../Components/Cards/PieChartCard";
import {
  ProgressBarCard,
  ProgressBarCard2,
  ProgressBarCard3,
} from "../../Components/Cards/ProgressBarCard";
import { Cancel_orderCard } from "../../Components/Cards/cancel_order";
import { TabText } from "../../Components/Tabs";
import {
  getAllDashboardData,
  getCohortAnalysis,
  getCompareDashboardData,
  getCompareDeliveries,
  getDeliveries,
} from "../../store/action/dashboard.action";
import {
  GET_ABANDONED_CARTS_SUCCESS,
  GET_ACTIVE_ORDER_BY_MEALS_SUCCESS,
  GET_ACTIVE_VS_TOTAL_CUSTOMER_SUCCESS,
  GET_AUTO_SELECTION_SUCCESS,
  GET_AVG_SALES_SUCCESS,
  GET_CANCEL_ORDER_SUCCESS,
  GET_COHORT_ANALYSIS_SUCCESS,
  GET_COMPARE_AVG_SALES_SUCCESS,
  GET_COMPARE_CONVERSION_RATE_SUCCESS,
  GET_COMPARE_DELIVERIES_SUCCESS,
  GET_COMPARE_NON_RENEWALS_SUCCESS,
  GET_COMPARE_ORDER_SUCCESS,
  GET_COMPARE_SESSIONS_SUCCESS,
  GET_COMPARE_SKIPPED_DELIVERY_SUCCESS,
  GET_CONVERSION_RATE_SUCCESS,
  GET_CUSTOMER_AUTO_SELECTION_SUCCESS,
  GET_CUSTOMER_ING_DISLIKE_SUCCESS,
  GET_CUSTOMER_MIGRATION_SUCCESS,
  GET_DELIVERIES_SUCCESS,
  GET_DELIVERY_BY_MEAL_SIZE_SUCCESS,
  GET_MONTHLY_NON_RENEWALS_SUCCESS,
  GET_NDD_DROP_OUT_SUCCESS,
  GET_NDD_FREQUENCY_SUCCESS,
  GET_NON_RENEWALS_SUCCESS,
  GET_ORDER_SUCCESS,
  GET_OVERALL_COMPARE_SALES_SUCCESS,
  GET_OVERALL_SALES_SUCCESS,
  GET_SALES_BY_CATEGORY_SUCCESS,
  GET_SALES_BY_CUSTOMER_SUCCESS,
  GET_SALES_BY_LOCATION_SUCCESS,
  GET_SALES_BY_SOCIAL_SOURCE_SUCCESS,
  GET_SESSIONS_BY_DEVICE_SUCCESS,
  GET_SESSIONS_BY_LOCATION_SUCCESS,
  GET_SESSIONS_BY_SOCIAL_SOURCE_SUCCESS,
  GET_SESSIONS_SUCCESS,
  GET_SESSION_BY_BROWSER_SUCCESS,
  GET_SKIPPED_DELIVERY_SUCCESS,  
  GET_SKIP_DELIVERIES_SUCCESS,
  GET_TOP_ING_DISLIKE_SUCCESS
} from "../../store/types";
import { dataList } from "../../utils/dashboardlist";
import { SumFromArray, convertToTitleCase, formatNumber } from "../../utils/functions";
import { colorPalettes } from "../../utils/globle";
const DeliveriesFilter = [{ item: "Total", val: null },
{ item: "MP", val: "subscription" },
{ item: "NDD", val: "NDD" }
]
const Dashboard = () => {
  const [selectedDeliveriesFilter, setDeliveriesFilter] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState("All");
  const { dateRange, comDateRange } = useSelector((state: RootState) => state.app);
  const {
    overAllSales,
    salesByCustomers,
    compareAllSales,
    apiCallList,
    avg_cmp_sales,
    avg_sales,
    total_Cmp_orders,
    total_orders,
    total_cmp_delivery,
    total_delivery,
    sales_by_location,
    sessionsBylocation,
    auto_selection_delivery,
    sales_by_category,
    abandoned_cart,
    ndd_order_frequency,
    customer_ingredient_dislike,
    active_customer,
    customer_migration,
    sessionData,
    cmp_sessionData,
    conversion_rate,
    cmp_conversion_rate,
    sessionByDevice,
    session_by_social_source,
    sales_by_social_source,
    deliveries_by_meal_size,
    ndd_drop_outs,
    monthly_plan_non_renewals,
    active_order_by_no_of_meals,
    non_renewals,
    cmp_non_renewals,
    session_by_browser,
    sales_by_traffic_source,
    AllSalesData,
    orders,
    averageSalesData,
    nonRenewalsData,
    dataSession,
    deliveries,
    customer_auto_select,
    skipped_delivery,
    cmp_skipped_delivery_data,
    skipped_delivery_data,
    top_ingredient_dislike,
    cmp_failed_orders_data,
    failed_orders,
    failed_orders_data,
    cohert_analysis,
    skip_deliveries,
  } = useSelector((state: RootState) => state.dashboard);
  const dispatch = useDispatch();
  const deliveryapicallack = useMemo(() => () => {
    dispatch(getDeliveries({
      start_date: moment(dateRange?.[0]?.startDate).format("YYYY/MM/DD"),
      end_date: moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD"),
      delivery_type: selectedDeliveriesFilter
    }));
  }, [dateRange, dispatch, selectedDeliveriesFilter])
  const deliverycompareapicallack = useMemo(() => () => {
    dispatch(getCompareDeliveries({
      start_date: moment(comDateRange?.[0]?.startDate).format("YYYY/MM/DD"),
      end_date: moment(comDateRange?.[0]?.endDate).format("YYYY/MM/DD"),
      delivery_type: selectedDeliveriesFilter
    }));
  }, [comDateRange, dispatch, selectedDeliveriesFilter])
  const apicall = useMemo(
    () =>
      (isFirst = false) => {
        dispatch(getAllDashboardData({
          start_date: moment(dateRange?.[0]?.startDate).format("YYYY/MM/DD"),
          end_date: moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD"),
        }, isFirst));
      },
    [dateRange, dispatch]
  );
  const compareApicall = useMemo(
    () => () => {
      dispatch(
        getCompareDashboardData({
          start_date: moment(comDateRange?.[0]?.startDate).format("YYYY/MM/DD"),
          end_date: moment(comDateRange?.[0]?.endDate).format("YYYY/MM/DD"),
        })
      );

    },
    [comDateRange, dispatch]
  );
  useEffect(() => {
    apicall(true);
    compareApicall();
    // const timerId = setInterval(() => {
    //   apicall()
    //   compareApicall()
    // }, 30000);
    // return () => {
    //   clearInterval(timerId)
    // }
  }, [apicall, compareApicall]);
  useEffect(() => {
    deliveryapicallack()
    deliverycompareapicallack()
  }, [deliveryapicallack, deliverycompareapicallack, selectedDeliveriesFilter])
  useEffect(() => {
    dispatch(getCohortAnalysis({
      value: "Last Year",
      order_type: "subscription"
    }));
  }, [dispatch])

  let skipDeliveriesData = Object.entries(skip_deliveries).map(
    ([key, value]) => {
      return {
        listItemTitle: convertToTitleCase(key),
        listItemSubTitle: String(value),
      };
    }
  );


  return (
    <Box as="section">
      <Container px={3}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 99,
            backgroundColor: "#F9F4ED",
          }}
        >
          <TopBtns
            showFilter
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <Divider mt={0} mb={2} color="ECE1D7" />
        </Box>
        <Grid columns={[1, 2, 3]} gap={20} mb={4}>
          {dataList
            .filter((e) => e.filter.find((i) => i === selectedTab))
            ?.map((item) => {
              switch (item.id) {
                case "Total Sales":
                  return (
                    <LineChartCard
                      link={"/report/sales_details"}
                      isLoader={apiCallList.find((e) =>
                        e === GET_OVERALL_SALES_SUCCESS ||
                        e === GET_OVERALL_COMPARE_SALES_SUCCESS
                      )
                        ? true
                        : false
                      }
                      title="Total Sales"
                      count={
                        "AED " + formatNumber(overAllSales?.totalNewAmount || 0)
                      }
                      comparePer={
                        moment(dateRange?.[0]?.startDate).format("YYYY/MM/DD") !== moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD") ?
                          Math.abs((overAllSales.totalNewAmount - compareAllSales.totalNewAmount) * 100 / overAllSales.totalNewAmount)
                          : Math.abs(((SumFromArray(overAllSales.result, "net_amount") -
                            SumFromArray(compareAllSales.result.slice(0, overAllSales.result.length), "net_amount")) * 100) /
                            (SumFromArray(overAllSales.result, "net_amount")))
                      }
                      showPercentage={
                        moment(dateRange?.[0]?.startDate).format("YYYY/MM/DD") !== moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD") ?
                          overAllSales.totalNewAmount !== 0 && compareAllSales.totalNewAmount !== 0
                          : SumFromArray(compareAllSales.result.slice(0, overAllSales.result.length), "net_amount") !== 0 &&
                          SumFromArray(overAllSales.result, "net_amount") !== 0
                      }
                      isPlus={
                        moment(dateRange?.[0]?.startDate).format("YYYY/MM/DD") !== moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD") ?
                          overAllSales.totalNewAmount - compareAllSales.totalNewAmount >= 0 ? true : false
                          : SumFromArray(overAllSales.result, "net_amount") - SumFromArray(compareAllSales.result.slice(0, overAllSales.result.length), "net_amount") >=
                            0
                            ? true
                            : false
                      }
                      data={AllSalesData}
                      id={item.id}
                      key={item.id}
                    />
                  );
                case "Sales by Customers":
                  return (
                    <DoughnutChartCard
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_SALES_BY_CUSTOMER_SUCCESS
                        )
                          ? true
                          : false
                      }
                      title="Sales by Customers"
                      labels={salesByCustomers?.label}
                      Data={salesByCustomers?.data}
                      id={item.id}
                      key={item.id}
                    />
                  );
                case "Sales by Category":
                  return (
                    <DoughnutChartCard
                      title="Sales by Category"
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_SALES_BY_CATEGORY_SUCCESS
                        )
                          ? true
                          : false
                      }
                      labels={sales_by_category?.label}
                      Data={sales_by_category.data}
                      id={item.id}
                      key={item.id}
                    />
                  );
                case "Sales by Location":
                  return (
                    <PieChartCard
                      id={item?.id}
                      title="Sales by Deliveries Location"
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_SALES_BY_LOCATION_SUCCESS
                        )
                          ? true
                          : false
                      }
                      backgroundColor={colorPalettes}
                      labels={sales_by_location?.label}
                      Data={sales_by_location.data}
                      key={item.id}
                    />
                  );
                case "Sessions":
                  return (
                    <LineChartCard
                      // link={"/report/sessions"}
                      title="Sessions"
                      isLoader={
                        apiCallList.find(
                          (e) =>
                            e === GET_SESSIONS_SUCCESS ||
                            e === GET_COMPARE_SESSIONS_SUCCESS
                        )
                          ? true
                          : false
                      }
                      count={formatNumber(sessionData?.totalSessionsCount || 0)}
                      comparePer={
                        moment(dateRange?.[0]?.startDate).format("YYYY/MM/DD") !== moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD") ?
                          Math.abs((sessionData.totalSessionsCount - cmp_sessionData.totalSessionsCount) * 100 / sessionData.totalSessionsCount)
                          : Math.abs(((SumFromArray(sessionData.session, "count") - SumFromArray(cmp_sessionData?.session.slice(0, sessionData.session.length), "count")) * 100) /
                            (SumFromArray(sessionData.session, "count")))
                      }
                      showPercentage={
                        moment(dateRange?.[0]?.startDate).format("YYYY/MM/DD") !== moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD") ?
                          sessionData.totalSessionsCount !== 0 && cmp_sessionData.totalSessionsCount !== 0
                          : SumFromArray(sessionData.session, "count") !== 0 &&
                          SumFromArray(cmp_sessionData?.session.slice(0, sessionData.session.length), "count") !== 0

                      }
                      isPlus={
                        moment(dateRange?.[0]?.startDate).format("YYYY/MM/DD") !== moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD") ?
                          sessionData.totalSessionsCount - cmp_sessionData.totalSessionsCount >= 0 ? true : false
                          : SumFromArray(sessionData.session, "count") -
                            SumFromArray(cmp_sessionData?.session.slice(0, sessionData.session.length), "count") >=
                            0
                            ? true
                            : false
                      }
                      data={dataSession}
                      id={item.id}
                      key={item.id}
                    />
                  );
                case "Conversion Rate":
                  return (
                    <ListItemsCard
                      link={"/report/session_by_convertion_rate"}
                      title="Conversion Rate"
                      isPlus
                      count={`${conversion_rate.ratio} % out of ${conversion_rate.totalVisitorsCount} visitors`}
                      isLoader={
                        apiCallList.find(
                          (e) =>
                            e === GET_CONVERSION_RATE_SUCCESS ||
                            e === GET_COMPARE_CONVERSION_RATE_SUCCESS
                        )
                          ? true
                          : false
                      }
                      listItems={conversion_rate.conversion_rate_list}
                      key={item.id}
                    />
                  );
                case "Orders":
                  return (
                    <LineChartCard
                      title="Orders"
                      key={item.id}
                      isLoader={
                        apiCallList.find(
                          (e) =>
                            e === GET_ORDER_SUCCESS ||
                            e === GET_COMPARE_ORDER_SUCCESS
                        )
                          ? true
                          : false
                      }
                      count={formatNumber(total_orders?.totalNewAmount || 0)}
                      comparePer={
                        moment(dateRange?.[0]?.startDate).format("YYYY/MM/DD") !== moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD") ?
                          Math.abs((total_orders.totalNewAmount - total_Cmp_orders.totalNewAmount) * 100 / total_orders.totalNewAmount)
                          : Math.abs(((SumFromArray(total_orders.result, "amount") - SumFromArray(total_Cmp_orders.result.slice(0, total_orders.result.length), "amount")) * 100) /
                            (SumFromArray(total_orders.result, "amount")))
                      }
                      showPercentage={
                        moment(dateRange?.[0]?.startDate).format("YYYY/MM/DD") !== moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD") ?
                          total_orders.totalNewAmount !== 0 && total_Cmp_orders.totalNewAmount !== 0
                          : SumFromArray(total_orders.result, "amount") !== 0 &&
                          SumFromArray(total_Cmp_orders.result.slice(0, total_orders.result.length), "amount") !== 0
                      }
                      isPlus={
                        moment(dateRange?.[0]?.startDate).format("YYYY/MM/DD") !== moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD") ?
                          total_orders.totalNewAmount - total_Cmp_orders.totalNewAmount >= 0 ? true : false
                          : SumFromArray(total_orders.result, "amount") -
                            SumFromArray(total_Cmp_orders.result.slice(0, total_orders.result.length), "amount") >=
                            0
                            ? true
                            : false
                      }
                      data={orders}
                      id={item.id}
                    />
                  );
                // case "failed_orders":
                //   return (
                //     <LineChartCard
                //       title="Failed Orders"
                //       key={item.id}
                //       isLoader={
                //         apiCallList.find(
                //           (e) =>
                //             e === GET_COMPARE_FAILED_ORDERS_SUCCESS ||
                //             e === GET_FAILED_ORDERS_SUCCESS
                //         )
                //           ? true
                //           : false
                //       }
                //       count={`AED ${formatNumber(failed_orders_data?.totalNewAmount || 0)}`}
                //       comparePer={
                //         (cmp_failed_orders_data.totalNewAmount * 100) /
                //         (failed_orders_data?.totalNewAmount || 1)
                //       }
                //       showPercentage={
                //         failed_orders_data?.totalNewAmount !== 0 &&
                //         cmp_failed_orders_data.totalNewAmount !== 0
                //       }
                //       isPlus={
                //         failed_orders_data?.totalNewAmount -
                //           cmp_failed_orders_data.totalNewAmount >=
                //           0
                //           ? true
                //           : false
                //       }
                //       data={failed_orders}
                //       id={item.id}
                //     />
                //   );
                case "Cohort Analysis":
                  return (
                    <CohortChartCard
                      title="Cohort Analysis"
                      data={cohert_analysis}
                      link={"/report/cohert_analysis"}
                      isLoader={apiCallList.find((e) => e === GET_COHORT_ANALYSIS_SUCCESS) ? true : false} />
                  )
                case "Abandoned Carts":
                  return (
                    <DoughnutChartCard
                      isLoader={apiCallList.find((e) => e === GET_ABANDONED_CARTS_SUCCESS) ? true : false}
                      title="Abandoned Carts"
                      labels={abandoned_cart?.label}
                      Data={abandoned_cart?.data}
                      id={item.id}
                      key={item.id}
                    />
                  );
                case "Average Sales Meal Plan & NDD":
                  return (
                    <BarChartCard
                      isHorizontal
                      title="Average Sales Meal Plan & NDD"
                      isLoader={apiCallList.find((e) => e === GET_AVG_SALES_SUCCESS || e === GET_COMPARE_AVG_SALES_SUCCESS) ? true : false}
                      data={averageSalesData}
                      key={item.id}
                    />
                  );
                case "Non-Renewals":
                  return (
                    <LineChartCard
                      // link={"/report/non-renewals"}
                      title="Non-Renewals"
                      isLoader={
                        apiCallList.find(
                          (e) =>
                            e === GET_NON_RENEWALS_SUCCESS ||
                            e === GET_COMPARE_NON_RENEWALS_SUCCESS
                        )
                          ? true
                          : false
                      }
                      count={`${formatNumber(
                        non_renewals?.totalCustomers || 0
                      )} out of ${formatNumber(
                        non_renewals?.totalPlans || 0
                      )} plans`}
                      comparePer={
                        Math.abs(((SumFromArray(non_renewals.result, "total") - SumFromArray(cmp_non_renewals.result.splice(0, non_renewals.result.length), "total")) * 100) /
                          (SumFromArray(non_renewals.result, "total")))
                      }
                      isPlus={
                        SumFromArray(non_renewals.result, "total") -
                          SumFromArray(cmp_non_renewals.result.splice(0, non_renewals.result.length), "total") >=
                          0
                          ? true
                          : false
                      }
                      showPercentage={
                        SumFromArray(non_renewals.result, "total") !== 0 &&
                        SumFromArray(cmp_non_renewals.result.splice(0, non_renewals.result.length), "total") !== 0
                      }
                      data={nonRenewalsData}
                      id={item.id}
                      key={item.id}
                      isSingleDate={
                        moment(dateRange?.[0]?.startDate).format("ll") ===
                        moment(dateRange?.[0]?.endDate).format("ll")
                      }
                    />
                  );
                case "Sessions by Device Type":
                  return (
                    <DoughnutChartCard
                      link={"/report/session_by_device"}
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_SESSIONS_BY_DEVICE_SUCCESS
                        )
                          ? true
                          : false
                      }
                      title="Sessions by Device Type"
                      labels={sessionByDevice?.label}
                      Data={sessionByDevice?.data}
                      id={item.id}
                      key={item.id}
                    />
                  );
                case "Deliveries By Meal Size":
                  return (
                    <DoughnutChartCard
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_DELIVERY_BY_MEAL_SIZE_SUCCESS
                        )
                          ? true
                          : false
                      }
                      subTitle={`Deliveries ${deliveries_by_meal_size.totalDeliveries}`}
                      title="Deliveries By Meal Size"
                      Data={deliveries_by_meal_size.data}
                      labels={deliveries_by_meal_size?.label}
                      id={item.id}
                      key={item.id}
                    />
                  );                  
                  case "Skip Deliveries":
                    return (
                      <ListItemsCard
                        title="Skip Deliveries"
                        isLoader={
                          apiCallList.find(
                            (e) => e === GET_SKIP_DELIVERIES_SUCCESS
                          )
                            ? true
                            : false
                        }
                      listItems={skipDeliveriesData.slice(0, 2)}
                      key={item.id}
                      subTitle={`${skip_deliveries?.percentage_skipped} %`}
                      />
                    );

                case "Sessions by Location":
                  return (
                    <PieChartCard
                      link={"/report/session_by_location"}
                      id={item?.id}
                      title="Sessions by Location"
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_SESSIONS_BY_LOCATION_SUCCESS
                        )
                          ? true
                          : false
                      }
                      backgroundColor={colorPalettes}
                      labels={sessionsBylocation?.label}
                      Data={sessionsBylocation?.data}
                      Counts={`${sessionsBylocation.totalSession}`}
                      showCounts
                      key={item.id}
                    />
                  );
                case "Active Customers V/S Total Customers Base":
                  return (
                    <ProgressBarCard
                      title="Active Customers V/S Total Customers Base"
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_ACTIVE_VS_TOTAL_CUSTOMER_SUCCESS
                        )
                          ? true
                          : false
                      }
                      listItems={active_customer.listItems}
                      count={`customers ${active_customer.total_customers}`}
                      key={item.id}
                    />
                  );
                case "Deliveries":
                  return (
                    <LineChartCard
                      filterBtn={
                        <div className="dropdown" style={{ width: "auto", height: '100%' }}>
                          <Image
                            id="filterBtn"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            src="icons/filter.png"
                            sx={{
                              width: 12,
                              cursor: "pointer"
                            }}
                          />
                          <ul className="dropdown-menu bg-dark"
                            aria-labelledby="filterBtn">
                            {DeliveriesFilter.map((item) => {
                              return (
                                <li key={item.item}>
                                  <TabText
                                    className="dropdown-item"
                                    name={item.item}
                                    isSelected={selectedDeliveriesFilter === item.val}
                                    onClick={() => {
                                      setDeliveriesFilter(item.val)
                                    }}
                                  />
                                </li>
                              )
                            })
                            }
                          </ul>
                        </div>
                      }
                      // link={"/report/deliveries"}
                      isSingleDate={
                        moment(dateRange?.[0]?.startDate).format("ll") ===
                        moment(dateRange?.[0]?.endDate).format("ll")
                      }
                      title={`Deliveries (${DeliveriesFilter.find((e) => e.val === selectedDeliveriesFilter)?.item})`}
                      key={item.id}
                      isLoader={
                        apiCallList.find(
                          (e) =>
                            e === GET_DELIVERIES_SUCCESS ||
                            e === GET_COMPARE_DELIVERIES_SUCCESS
                        )
                          ? true
                          : false
                      }
                      count={formatNumber(total_delivery?.totalNewAmount || 0)}
                      comparePer={
                        Math.abs(((total_delivery?.totalNewAmount - total_cmp_delivery.totalNewAmount) * 100) /
                          (total_delivery?.totalNewAmount))
                      }
                      showPercentage={
                        total_delivery?.totalNewAmount !== 0 &&
                        total_cmp_delivery.totalNewAmount !== 0
                      }
                      isPlus={
                        total_delivery?.totalNewAmount -
                          total_cmp_delivery.totalNewAmount >=
                          0
                          ? true
                          : false
                      }
                      data={deliveries}
                      id={item.id}
                    />
                  );
                case "Active Orders by No. of meals":
                  return (
                    <ListItemsCard
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_ACTIVE_ORDER_BY_MEALS_SUCCESS
                        )
                          ? true
                          : false
                      }
                      title="Active Orders by No. of meals"
                      listItems={active_order_by_no_of_meals}
                      key={item.id}
                    />
                  );
                case "Customer Migration":
                  return (
                    <ProgressBarCard2
                      title="Customer Migration"
                      isLoader={apiCallList.find((e) => e === GET_CUSTOMER_MIGRATION_SUCCESS) ? true : false}
                      listItems={[
                        {
                          listItemTitle: "MP TO NDD",
                          value: (customer_migration?.result?.mp_to_ndd * 100 / (customer_migration?.result?.total_customer || 1)).toFixed(2) || 0,
                        },
                        {
                          listItemTitle: "NDD TO MP",
                          value: (customer_migration?.result?.ndd_to_mp * 100 / (customer_migration?.result?.total_customer || 1)).toFixed(2) || 0,
                        },
                        {
                          listItemTitle: "Weekly to Monthly",
                          value: (customer_migration?.result?.weekly_to_monthly * 100 / (customer_migration?.result?.total_customer || 1)).toFixed(2) || 0,
                        },
                        {
                          listItemTitle: "Monthly to weekly",
                          value: (customer_migration?.result?.monthly_to_weekly * 100 / (customer_migration?.result?.total_customer || 1)).toFixed(2) || 0,
                        },
                      ]}
                      key={item.id} />
                  )
                case "Monthly Plans Non Renewals":
                  return (
                    <ProgressBarCard3
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_MONTHLY_NON_RENEWALS_SUCCESS
                        )
                          ? true
                          : false
                      }
                      title="Monthly Plans Non Renewals(Overall)"
                      listItems={monthly_plan_non_renewals.listItems}
                      key={item.id}
                    />
                  );
                case "NDD Order Frequency":
                  return (
                    <ListItemsCard
                      title="NDD Order Frequency (Last 3 months)"
                      isLoader={
                        apiCallList.find((e) => e === GET_NDD_FREQUENCY_SUCCESS)
                          ? true
                          : false
                      }
                      listItems={ndd_order_frequency}
                      key={item.id}
                    />
                  );
                case "NDD Drop Outs":
                  return (
                    <ProgressBarCard3
                      isLoader={
                        apiCallList.find((e) => e === GET_NDD_DROP_OUT_SUCCESS)
                          ? true
                          : false
                      }
                      title="NDD Drop Outs (Over all)"
                      listItems={ndd_drop_outs.listItems}
                      count={`customers ${ndd_drop_outs.total_customers}`}
                      key={item.id}
                    />
                  );
                // case "NDD Mix":
                //   return (
                //     <ProgressBarCard3 title="NDD Mix" listItems={[{ listItemTitle: "Only meals", value: 70, }, {
                //       listItemTitle: "Meals + Breakfast", value: 50,
                //     }, { listItemTitle: "All Categories", value: 80, }, {
                //       listItemTitle: "Breakfast only", value: 70,
                //     }, { listItemTitle: "Add ons", value: 50, },]} listItemSx={{
                //       mb: 36
                //     }} key={item.id} />
                //   )
                case "Auto Selection":
                  return (
                    <DoughnutChartCard
                      title="Auto Selection"
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_AUTO_SELECTION_SUCCESS
                        )
                          ? true
                          : false
                      }
                      labels={auto_selection_delivery?.label}
                      Data={auto_selection_delivery?.data}
                      id={item.id}
                      key={item.id}
                    />
                  );
                case "Customers with ingredient dislikes":
                  return (
                    <DoughnutChartCard
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_CUSTOMER_ING_DISLIKE_SUCCESS
                        )
                          ? true
                          : false
                      }
                      title="Plans with ingredient category dislikes"
                      subTitle={`${(
                        (customer_ingredient_dislike.resultForCustomer
                          .avoid_category_customer *
                          100) /
                        (customer_ingredient_dislike.resultForCustomer
                          .total_customer || 1)
                      ).toFixed(2)} % out of ${customer_ingredient_dislike.resultForCustomer
                        .total_customer
                        } active plans`}
                      labels={customer_ingredient_dislike?.labels}
                      Data={customer_ingredient_dislike?.data}
                      id={item.id}
                      key={item.id}
                    />
                  );
                case "skipped_delivery":
                  return (
                    <LineChartCard
                      title="Skipped Delivery"
                      key={item.id}
                      isLoader={
                        apiCallList.find(
                          (e) =>
                            e === GET_SKIPPED_DELIVERY_SUCCESS ||
                            e === GET_COMPARE_SKIPPED_DELIVERY_SUCCESS
                        )
                          ? true
                          : false
                      }
                      count={formatNumber(
                        skipped_delivery_data?.totalNewAmount || 0
                      )}
                      comparePer={
                        Math.abs(((skipped_delivery_data?.totalNewAmount - cmp_skipped_delivery_data.totalNewAmount) * 100) /
                          (skipped_delivery_data?.totalNewAmount))
                      }
                      showPercentage={
                        skipped_delivery_data?.totalNewAmount !== 0 &&
                        cmp_skipped_delivery_data.totalNewAmount !== 0
                      }
                      isPlus={
                        skipped_delivery_data?.totalNewAmount -
                          cmp_skipped_delivery_data.totalNewAmount >=
                          0
                          ? true
                          : false
                      }
                      data={skipped_delivery}
                      id={item.id}
                      isSingleDate={
                        moment(dateRange?.[0]?.startDate).format("ll") ===
                        moment(dateRange?.[0]?.endDate).format("ll")
                      }
                    />
                  );
                case "customer_auto_select":
                  return (
                    <DoughnutChartCard
                      id={item?.id}
                      title="Customer Auto Select"
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_CUSTOMER_AUTO_SELECTION_SUCCESS
                        )
                          ? true
                          : false
                      }
                      labels={customer_auto_select.label}
                      Data={customer_auto_select.data}
                      key={item.id}
                    />
                  );
                case "top_ingredient_dislike":
                  return (
                    <ListItemsCard
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_TOP_ING_DISLIKE_SUCCESS
                        )
                          ? true
                          : false
                      }
                      title="Top Ingredient Dislike"
                      listItems={top_ingredient_dislike.list}
                      count={
                        top_ingredient_dislike.total_plan !== 0 &&
                          top_ingredient_dislike.dislike_plan !== 0
                          ? `${(
                            (top_ingredient_dislike.dislike_plan * 100) /
                            (top_ingredient_dislike.total_plan || 1)
                          ).toFixed(2)} % out of ${top_ingredient_dislike.total_plan
                          } active plans`
                          : ""
                      }
                      key={item.id}
                    />
                  );
                case "cancel_order":
                  return (
                    <Cancel_orderCard // eslint-disable-line react/jsx-pascal-case
                      isLoader={apiCallList.find((e) => e === GET_CANCEL_ORDER_SUCCESS) ? true : false}
                      key={item.id}
                    />
                  );
                case "Sessions by Social Source":
                  return (
                    <ListItemsCard
                      title="Sessions by Social Source"
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_SESSIONS_BY_SOCIAL_SOURCE_SUCCESS
                        )
                          ? true
                          : false
                      }
                      listItems={session_by_social_source}
                      key={item.id}
                    />
                  );
                case "Sales by Social Source":
                  return (
                    <ListItemsCard
                      title="Sales by Social Source"
                      link={"/report/sales_by_social_source"}
                      isLoader={apiCallList.find((e) => e === GET_SALES_BY_SOCIAL_SOURCE_SUCCESS) ? true : false}
                      listItems={sales_by_social_source}
                      key={item.id}
                    />
                  )
                case "Session by Browser":
                  return (
                    <ListItemsCard
                      link={"/report/session_by_browser"}
                      title="Session by Browser"
                      isLoader={
                        apiCallList.find(
                          (e) => e === GET_SESSION_BY_BROWSER_SUCCESS
                        )
                          ? true
                          : false
                      }
                      listItems={session_by_browser}
                      key={item.id}
                    />
                  );

                default:
                  return null;
              }
            })}
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;

import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import generatePDF from "react-to-pdf";
import { Box, Card, Container, Divider, Flex, Text } from "theme-ui";
import { StringParam, useQueryParam, } from "use-query-params";
import { RootState } from "../../../@types/reducer";
import { TopBtns } from "../../Components/Buttons/TopBtns";
import LineChartCard from "../../Components/Cards/LineChartCard";
import { LoaderChartCard } from "../../Components/Cards/LoderChartCard";
import { TableCard } from "../../Components/Cards/TableCard";
import { DetailedCohortChart } from "../../Components/Charts/cohort-chart";
import { TitleTag } from "../../Components/Helmet";
import { FormSelect } from "../../Components/Inputs/FormSelect";
import {
  getCohortAnalysisReportsBySlug,
  getReportsBySlug,
  getReportsBySlugSuccess,
  getReportsFilter,
} from "../../store/action/reports.action";
import { convertToTitleCase, getlabels } from "../../utils/functions";
const Index = () => {
  const location = useLocation()
  const [cohortTypeParam, setCohortTypeParam] = useQueryParam("cohert_type", StringParam);
  const [cohortGraphTypeParam, setCohortGraphTypeParam] = useQueryParam("cohert_graph_type", StringParam);
  const [utm_source, set_utm_source] = useQueryParam("find_by_utm_source", StringParam);
  const [utm_medium, set_utm_Medium] = useQueryParam("find_by_utm_medium", StringParam);
  const [utm_campaign, set_utm_Campaign] = useQueryParam("find_by_campaign", StringParam);
  const [search, setSearch] = useQueryParam("global_search", StringParam);
  const [selectedCohortType, setSelectedCohortType] = useState("subscription");
  const [selectedCohortGraphType, setSelectedCohortGraphType] = useState("percent");
  const [type, setType] = useState("sales_by_social_source");
  const targetRef = useRef(null);
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { report_by_slug, cohort_analysis_report_by_slug, loader, filterData } = useSelector((status: RootState) => status.reports);
  const { dateRange, comDateRange } = useSelector(
    (state: RootState) => state.app
  );
  const [data, setData] = useState<any>({ labels: [], datasets: [], });
  const apicall = useMemo(() => () => {
    slug === "cohert_analysis"
      ? dispatch(
        getCohortAnalysisReportsBySlug(slug, {
          start_date: moment(dateRange?.[0]?.startDate).format(
            "YYYY/MM/DD"
          ),
          end_date: moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD"),
          compare_start_date: moment(comDateRange?.[0]?.startDate).format(
            "YYYY/MM/DD"
          ),
          compare_end_date: moment(comDateRange?.[0]?.endDate).format(
            "YYYY/MM/DD"
          ),
          cohert_range: "Last Year",
          cohert_type: cohortTypeParam || selectedCohortType,
          cohert_graph_type:
            cohortGraphTypeParam || selectedCohortGraphType,
        })
      )
      : dispatch(
        getReportsBySlug(slug + location.search, {
          start_date: moment(dateRange?.[0]?.startDate).format(
            "YYYY/MM/DD"
          ),
          end_date: moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD"),
          compare_start_date: moment(comDateRange?.[0]?.startDate).format(
            "YYYY/MM/DD"
          ),
          compare_end_date: moment(comDateRange?.[0]?.endDate).format(
            "YYYY/MM/DD"
          ),
          report_type: type
        })
      );
  }, [slug, dispatch, dateRange, comDateRange, cohortTypeParam, selectedCohortType, cohortGraphTypeParam, selectedCohortGraphType, location.search, type]);
  useEffect(() => {
    if (slug) {
      apicall();
      if (slug === "sales_by_social_source") {
        dispatch(getReportsFilter("dashboard/get_utm_list_by_type"))
      }
    }
    return () => {
      dispatch(getReportsBySlugSuccess([]))
    }
  }, [apicall, dispatch, slug]);
  useEffect(() => {
    if (report_by_slug.length > 0 && slug == "sales_details") {
      setData({
        labels:
          report_by_slug[report_by_slug.length - 1].date.current !== null
            ? report_by_slug.map((e) => getlabels(e.date.current))
            : report_by_slug.map((e) => getlabels(e.date.compare)),
        datasets: [
          {
            id: "previous",
            data: report_by_slug
              .map((e) => (e.date.compare ? e.net_amount.compare : null))
              .filter((e) => e !== null),
            borderColor: "#068413",
            borderWidth: 0.5,
            backgroundColor: "#00A611",
            borderDash: [5, 5],
            pointBorderWidth: 1,
            pointRadius: 0.5,
            pointHoverBorderWidth: 1,
            Label: report_by_slug
              .map((e) => (e.date.compare ? getlabels(e.date.compare) : null))
              .filter((e) => e),
          },
          {
            id: "current",
            data: report_by_slug
              .map((e) => (e.date.current ? e.net_amount.current : null))
              .filter((e) => e !== null),
            borderColor: "#068413",
            borderWidth: 1,
            backgroundColor: "#068413",
            pointBorderWidth: 1,
            pointRadius: 0.5,
            pointHoverBorderWidth: 1,
            Label: report_by_slug
              .map((e) => (e.date.current ? getlabels(e.date.current) : null))
              .filter((e) => e),
          },
        ],
      });
    } else {
      setData({
        labels: [],
        datasets: [],
      });
    }
  }, [
    report_by_slug,
    slug,
    selectedCohortType,
    cohortTypeParam,
    selectedCohortGraphType,
    cohortGraphTypeParam,
  ]);

  return (
    <Box as="section">
      <TitleTag title={convertToTitleCase(slug)} />
      <Container px={3}>
        <TopBtns
          selectedCohortType={selectedCohortType}
          setSelectedCohortType={setSelectedCohortType}
          setCohortTypeParam={setCohortTypeParam}
          cohortTypeParam={cohortTypeParam}
          selectedCohortGraphType={selectedCohortGraphType}
          setSelectedCohortGraphType={setSelectedCohortGraphType}
          setCohortGraphTypeParam={setCohortGraphTypeParam}
          cohortGraphTypeParam={cohortGraphTypeParam}
          showCohortAnalysisFilter={slug === "cohert_analysis" ? true : false}
          showSearch={slug === "sales_by_social_source" ? true : false}
          searchonChange={(e) => setSearch(e)}
          searchValue={search || ""}
        />
        <Divider mt={0} mb={2} color="ECE1D7" />
        {slug == "sales_details" ? (
          <div ref={targetRef}>
            <LineChartCard
              onClick={() => {
                generatePDF(targetRef, {
                  filename: `chart ${slug} ${moment(
                    dateRange?.[0]?.startDate
                  ).format("YYYY/MM/DD")} to ${moment(
                    dateRange?.[0]?.endDate
                  ).format("YYYY/MM/DD")}`,
                  method: "save",
                  canvas: { qualityRatio: 1 },
                });
              }}
              autoSkip={false}
              title="Total Sales"
              showPercentage={false}
              id="Total Sales"
              data={data}
            />
          </div>
        ) : slug == "cohert_analysis" ? (
          loader ? (
            <Card
              backgroundColor="white"
              py={20}
              px={3}
              mt={3}
              sx={{
                borderRadius: 12,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LoaderChartCard />
            </Card>
          ) : (
            <DetailedCohortChart data={cohort_analysis_report_by_slug} />
          )
        ) : null}
        <Box className="row" mx={-15} mt={12}>
          <Box className={`col-md-${slug == "sales_by_social_source" ? 9 : 12} order-last order-md-first`}>
            {report_by_slug.length > 0 ? (
              <TableCard
                tableData={report_by_slug}
                tableHeader={
                  <React.Fragment>
                    <Flex mb={2} sx={{ justifyContent: "center" }} >
                      <Text variant="Maison24Demi33lh" color="black" >
                        {convertToTitleCase(slug)}
                      </Text>
                    </Flex>
                    {slug === "sales_by_social_source" ?
                      <Flex mb={2}>
                        <Text variant="Maison14Medium23lh" color="black">
                          Total Amount:-
                          <Text sx={{ fontWeight: 'demi', fontSize: 18 }} ml={2}>
                            {report_by_slug.map((e: any) => e?.net_amount).reduce((acc, curr) => acc + curr, 0)} AED
                          </Text>
                        </Text>
                      </Flex>
                      : null}
                  </React.Fragment>
                }
                columns={
                  Object.keys(report_by_slug[0] || {}).map((key, id) => {
                    return {
                      id: key,
                      header: convertToTitleCase(key),
                      accessorKey: key,
                    };
                  }) || []
                }
              />
            ) : null}
          </Box>
          {slug == "sales_by_social_source" ? (
            <Box className="col-md-3">
              <FormSelect
                label="Search by Source"
                data={filterData?.uniqueSources?.filter((e: any) => e).map((item: any) => {
                  return {
                    "val": item,
                    "item": item
                  }
                }) || []}
                customSx={{ width: "100%" }}
                value={utm_source || undefined}
                onChange={(e) => {
                  set_utm_source(e.target.value)
                }}
              />
              <FormSelect
                label="Search by Medium"
                data={filterData?.uniqueMediums?.filter((e: any) => e).map((item: any) => {
                  return {
                    "val": item,
                    "item": item
                  }
                }) || []}
                customSx={{ width: "100%" }}
                value={utm_medium || undefined}
                onChange={(e) => {
                  set_utm_Medium(e.target.value)
                }}
              />
              <FormSelect
                label="Search by Campaign"
                data={filterData?.uniqueCampaigns?.filter((e: any) => e).map((item: any) => {
                  return {
                    "val": item,
                    "item": item
                  }
                }) || []}
                customSx={{ width: "100%" }}
                value={utm_campaign || undefined}
                onChange={(e) => {
                  set_utm_Campaign(e.target.value)
                }}
              />
              <FormSelect
                label="Report Type"
                data={[
                  {
                    "val": "sales_by_social_source",
                    "item": "By Last Click"
                  },
                  {
                    "val": "sales_by_social_source_data_driven",
                    "item": "By Data Driven"
                  }
                ]}
                customSx={{ width: "100%" }}
                value={type}
                onChange={(e) => {
                  console.log("e.target", e.target.value);

                  setType(e.target.value)
                }}
              />
            </Box>
          ) : null}
        </Box>
      </Container >
    </Box >
  );
};

export default Index;

import { call, put, takeLatest } from "redux-saga/effects";
import { fetchApiError } from "../action/app.action";
import { getAbandonedCartsSuccess, getActiveOrderByMealsSuccess, getActiveVsTotalCustomerSuccess, getAutoSelectionSuccess, getAvgSalesSuccess, getCancelOrderSuccess, getCohortAnalysisSuccess, getCompareAvgSalesSuccess, getCompareConversionRateSuccess, getCompareDeliveriesSuccess, getCompareFailedOrdersSuccess, getCompareNonRenewalSuccess, getCompareOrderSuccess, getCompareSessionsSuccess, getCompareSkippedDeliverySuccess, getConversionRateSuccess, getCustomerAutoSelectSuccess, getCustomerMigrationSuccess, getCustomersIngDislikeSuccess, getDeliveriesSuccess, getDeliveryByMealSizeSuccess, getFailedOrdersSuccess, getMonthlyNonRenewalsSuccess, getNDDDropOutsSuccess, getNDDFrequencySuccess, getNDDMixSuccess, getNonRenewalSuccess, getOrderSuccess, getOverAllCompareSalesSuccess, getOverAllSalesSuccess, getSalesByBrowserSourceSuccess, getSalesByCategorySuccess, getSalesByCustomerSuccess, getSalesByLocationSuccess, getSalesBySocialSourceSuccess, getSessionsByDeviceSuccess, getSessionsByLocationSuccess, getSessionsBySocialSourceSuccess, getSessionsSuccess, getSkipDeliveriesSuccess, getSkippedDeliverySuccess, getTopIngredientDislikeSuccess } from "../action/dashboard.action";
import { getAbandonedCartsApi, getActiveOrderByMealsApi, getActiveVsTotalCustomerApi, getAutoSelectionApi, getAvgSalesApi, getCancelOrderApi, getCohortAnalysisApi, getConversionRateApi, getCustomerAutoSelectApi, getCustomerMigrationApi, getCustomersIngDislikeApi, getDeliveriesApi, getDeliveryByMealSizeApi, getFailedOrdersApi, getMonthlyNonRenewalsApi, getNDDDropOutsApi, getNDDFrequencyApi, getNDDMixApi, getNonRenewalApi, getOrderApi, getOverAllSalesApi, getSalesByCategoryApi, getSalesByCustomerApi, getSalesByLocationApi, getSalesBySocialSourceApi, getSalesByTrafficSourceApi, getSessionsApi, getSessionsByDeviceApi, getSessionsByLocationApi, getSessionsBySocialSourceApi, getSkipDeliveriesApi, getSkippedDeliveryApi, getTopIngredientDislikeApi } from "../api";
import { GET_ALL_DASHBOARD_DATA, GET_COHORT_ANALYSIS, GET_COMPARE_DASHBOARD_DATA, GET_COMPARE_DELIVERIES, GET_DELIVERIES } from "../types";
function* getOverAllSalesSaga(action) {
    try {
        const response = yield call(getOverAllSalesApi, action.payload);
        if (response?.data?.data) {
            yield put(getOverAllSalesSuccess(response?.data?.data, action.payload));
        }
    } catch (error) {
        console.log("getOverAllSalesSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getSalesByCustomerSaga(action) {
    try {
        const response = yield call(getSalesByCustomerApi, action.payload);
        if (response?.data?.data) {
            const total = Object.values(response?.data?.data).reduce((acc, value) => acc + value, 0);
            const percentages = Object.fromEntries(
                Object.entries(response?.data?.data).map(([key, value]) => [key + "_per", (((value / total) * 100) || 0).toFixed(2)])
            );
            yield put(getSalesByCustomerSuccess({ ...percentages, ...response?.data?.data }));
        }
    } catch (error) {
        console.log("getSalesByCustomerSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getOverCompareSalesSaga(action) {
    try {
        const response = yield call(getOverAllSalesApi, action.payload, action.payload);
        if (response?.data?.data) {
            yield put(getOverAllCompareSalesSuccess(response?.data?.data, action.payload));
        }
    } catch (error) {
        console.log("getOverCompareSalesSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}

function* getSalesByCategorySaga(action) {
    try {
        const response = yield call(getSalesByCategoryApi, action.payload);
        if (response?.data?.data) {
            const total = Object.values(response?.data?.data).reduce((acc, value) => acc + value, 0);
            const percentages = Object.fromEntries(
                Object.entries(response?.data?.data).map(([key, value]) => [key + "_per", (((value / total) * 100) || 0).toFixed(2)])
            );
            yield put(getSalesByCategorySuccess({ ...percentages, ...response?.data?.data }));
        }
    } catch (error) {
        console.log("getSalesByCategorySaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getSalesByLocationSaga(action) {
    try {
        const response = yield call(getSalesByLocationApi, action.payload);
        if (response?.data?.data) {
            yield put(getSalesByLocationSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getSalesByLocationSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getSessionsSaga(action) {
    try {
        const response = yield call(getSessionsApi, action.payload);
        if (response?.data?.data) {
            yield put(getSessionsSuccess(response?.data?.data, action.payload));
        }
    } catch (error) {
        console.log("getSessionsSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getCompareSessionsSaga(action) {
    try {
        const response = yield call(getSessionsApi, action.payload);
        if (response?.data?.data) {
            yield put(getCompareSessionsSuccess(response?.data?.data, action.payload));
        }
    } catch (error) {
        console.log("getCompareSessionsSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getConversionRateSaga(action) {
    try {
        const response = yield call(getConversionRateApi, action.payload);
        if (response?.data?.data) {
            yield put(getConversionRateSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getConversionRateSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getCompareConversionRateSaga(action) {
    try {
        const response = yield call(getConversionRateApi, action.payload);
        if (response?.data?.data) {
            yield put(getCompareConversionRateSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getCompareConversionRateSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getSessionsByLocationSaga(action) {
    try {
        const response = yield call(getSessionsByLocationApi, action.payload);
        if (response?.data?.data) {
            yield put(getSessionsByLocationSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getSessionsByLocationSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getOrderSaga(action) {
    try {
        const response = yield call(getOrderApi, action.payload);
        if (response?.data?.data) {
            yield put(getOrderSuccess(response?.data?.data, action.payload));
        }
    } catch (error) {
        console.log("getOrderSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getCompareOrderSaga(action) {
    try {
        const response = yield call(getOrderApi, action.payload);
        if (response?.data?.data) {
            yield put(getCompareOrderSuccess(response?.data?.data, action.payload));
        }
    } catch (error) {
        console.log("getCompareOrderSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getAbandonedCartsSaga(action) {
    try {
        const response = yield call(getAbandonedCartsApi, action.payload);
        if (response?.data?.data) {
            yield put(getAbandonedCartsSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getAbandonedCartsSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getAvgSalesSaga(action) {
    try {
        const response = yield call(getAvgSalesApi, action.payload);
        if (response?.data?.data) {
            yield put(getAvgSalesSuccess(response?.data?.data, action.payload));
        }
    } catch (error) {
        console.log("getAvgSalesSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getCompareAvgSalesSaga(action) {
    try {
        const response = yield call(getAvgSalesApi, action.payload);
        if (response?.data?.data) {
            yield put(getCompareAvgSalesSuccess(response?.data?.data, action.payload));
        }
    } catch (error) {
        console.log("getCompareAvgSalesSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getNonRenewalSaga(action) {
    try {
        const response = yield call(getNonRenewalApi, action.payload);
        if (response?.data?.data) {
            yield put(getNonRenewalSuccess(response?.data?.data, action.payload));
        }
    } catch (error) {
        console.log("getNonRenewalSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getCompareNonRenewalSaga(action) {
    try {
        const response = yield call(getNonRenewalApi, action.payload);
        if (response?.data?.data) {
            yield put(getCompareNonRenewalSuccess(response?.data?.data, action.payload));
        }
    } catch (error) {
        console.log("getCompareNonRenewalSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getSessionsByDeviceSaga(action) {
    try {
        const response = yield call(getSessionsByDeviceApi, action.payload);
        if (response?.data?.data) {
            yield put(getSessionsByDeviceSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getSessionsByDeviceSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getSessionsBySocialSourceSaga(action) {
    try {
        const response = yield call(getSessionsBySocialSourceApi, action.payload);
        if (response?.data?.data) {
            yield put(getSessionsBySocialSourceSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getSessionsBySocialSourceSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getSalesBySocialSourceSaga(action) {
    try {
        const response = yield call(getSalesBySocialSourceApi, action.payload);
        if (response?.data?.data) {
            yield put(getSalesBySocialSourceSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getSalesBySocialSourceSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
// function* getSessionsByTrafficSourceSaga(action) {
//     try {
//         const response = yield call(getSessionsByTrafficSourceApi, action.payload);
//         if (response?.data?.data) {
//             yield put(getSalesByTrafficSourceSuccess(response?.data?.data));
//         }
//     } catch (error) {
//         console.log("getSessionsByTrafficSourceSaga API Error:- ", error?.response?.data);
//         yield put(fetchApiError());
//     }
// }
function* getSalesByTrafficSourceSaga(action) {
    try {
        const response = yield call(getSalesByTrafficSourceApi, action.payload);
        if (response?.data?.data) {
            yield put(getSalesByBrowserSourceSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getSalesByTrafficSourceSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getActiveVsTotalCustomerSaga(action) {
    try {
        const response = yield call(getActiveVsTotalCustomerApi, action.payload);
        if (response?.data?.data) {
            yield put(getActiveVsTotalCustomerSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getActiveVsTotalCustomerSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getDeliveriesSaga(action) {
    try {
        const response = yield call(getDeliveriesApi, action.payload);
        if (response?.data?.data) {
            yield put(getDeliveriesSuccess(response?.data?.data, action.payload));
        }
    } catch (error) {
        console.log("getDeliveriesSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getCompareDeliveriesSaga(action) {
    try {
        const response = yield call(getDeliveriesApi, action.payload);
        if (response?.data?.data) {
            yield put(getCompareDeliveriesSuccess(response?.data?.data, action.payload));
        }
    } catch (error) {
        console.log("getCompareDeliveriesSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getActiveOrderByMealsSaga(action) {
    try {
        const response = yield call(getActiveOrderByMealsApi, action.payload);
        if (response?.data?.data) {
            yield put(getActiveOrderByMealsSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getActiveOrderByMealsSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getAutoSelectionSaga(action) {
    try {
        const response = yield call(getAutoSelectionApi, action.payload);
        if (response?.data?.data) {
            yield put(getAutoSelectionSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getAutoSelectionSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getCustomerMigrationSaga(action) {
    try {
        const response = yield call(getCustomerMigrationApi, action.payload);
        if (response?.data?.data) {
            yield put(getCustomerMigrationSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getCustomerMigrationSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getMonthlyNonRenewalsSaga(action) {
    try {
        const response = yield call(getMonthlyNonRenewalsApi, action.payload);
        if (response?.data?.data) {
            yield put(getMonthlyNonRenewalsSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getMonthlyNonRenewalsSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getNDDFrequencySaga(action) {
    try {
        const response = yield call(getNDDFrequencyApi, action.payload);
        if (response?.data?.data) {
            yield put(getNDDFrequencySuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getNDDFrequencySaga API Error:- ", error, error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getNDDDropOutsSaga(action) {
    try {
        const response = yield call(getNDDDropOutsApi, action.payload);
        if (response?.data?.data) {
            yield put(getNDDDropOutsSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getNDDDropOutsSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getNDDMixSaga(action) {
    try {
        const response = yield call(getNDDMixApi, action.payload);
        if (response?.data?.data) {
            yield put(getNDDMixSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getNDDMixSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getCustomersIngDislikeSaga(action) {
    try {
        const response = yield call(getCustomersIngDislikeApi, action.payload);
        if (response?.data?.data) {
            yield put(getCustomersIngDislikeSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getCustomersIngDislikeSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getDeliveryByMealSizeSaga(action) {
    try {
        const response = yield call(getDeliveryByMealSizeApi, action.payload);
        if (response?.data?.data) {
            yield put(getDeliveryByMealSizeSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getDeliveryByMealSizeSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getCustomerAutoSelectSaga(action) {
    try {
        const response = yield call(getCustomerAutoSelectApi, action.payload);
        if (response?.data?.data) {
            yield put(getCustomerAutoSelectSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getCustomerAutoSelectSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getSkippedDeliverySaga(action) {
    try {
        const response = yield call(getSkippedDeliveryApi, action.payload);
        if (response?.data?.data) {
            yield put(getSkippedDeliverySuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getSkippedDeliverySaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getCompareSkippedDeliverySaga(action) {
    try {
        const response = yield call(getSkippedDeliveryApi, action.payload);
        if (response?.data?.data) {
            yield put(getCompareSkippedDeliverySuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getCompareSkippedDeliverySaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getTopIngredientDislikeSaga(action) {
    try {
        const response = yield call(getTopIngredientDislikeApi, action.payload);
        if (response?.data?.data) {
            yield put(getTopIngredientDislikeSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getTopIngredientDislikeSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getFailedOrdersSaga(action) {
    try {
        const response = yield call(getFailedOrdersApi, action.payload);
        if (response?.data?.data) {
            yield put(getFailedOrdersSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getFailedOrdersSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getCompareFailedOrdersSaga(action) {
    try {
        const response = yield call(getFailedOrdersApi, action.payload);
        if (response?.data?.data) {
            yield put(getCompareFailedOrdersSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getCompareFailedOrdersSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getCohortAnalysisSaga(action) {
    try {
        const response = yield call(getCohortAnalysisApi, action.payload);
        if (response?.data?.data) {
            yield put(getCohortAnalysisSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getCohortAnalysisSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getCancelOrderSaga(action) {
    try {
        const response = yield call(getCancelOrderApi, action.payload);
        if (response?.data?.data) {
            yield put(getCancelOrderSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getCancelOrderSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getSkipDeliveriesSaga(action) {
    try {
        const response = yield call(getSkipDeliveriesApi, action.payload);
        if (response?.data?.data) {
            yield put(getSkipDeliveriesSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getSkipDeliveriesSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}

export function* watchDashboardSagas() {
    return [
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getOverAllSalesSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getSalesByCustomerSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getSalesByCategorySaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getSalesByLocationSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getSessionsSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getConversionRateSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getSessionsByLocationSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getOrderSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getAbandonedCartsSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getAvgSalesSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getNonRenewalSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getSessionsByDeviceSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getSessionsBySocialSourceSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getSalesBySocialSourceSaga),
        // yield takeLatest(GET_ALL_DASHBOARD_DATA, getSessionsByTrafficSourceSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getSalesByTrafficSourceSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getActiveVsTotalCustomerSaga),
        yield takeLatest(GET_DELIVERIES, getDeliveriesSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getActiveOrderByMealsSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getAutoSelectionSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getCustomerMigrationSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getMonthlyNonRenewalsSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getNDDFrequencySaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getNDDDropOutsSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getNDDMixSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getCustomersIngDislikeSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getDeliveryByMealSizeSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getCustomerAutoSelectSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getSkipDeliveriesSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getSkippedDeliverySaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getTopIngredientDislikeSaga),
        yield takeLatest(GET_ALL_DASHBOARD_DATA, getCancelOrderSaga),
        // yield takeLatest(GET_ALL_DASHBOARD_DATA, getFailedOrdersSaga),
        // yield takeLatest(GET_COMPARE_DASHBOARD_DATA, getCompareFailedOrdersSaga),
        yield takeLatest(GET_COMPARE_DASHBOARD_DATA, getCompareSkippedDeliverySaga),
        yield takeLatest(GET_COMPARE_DASHBOARD_DATA, getOverCompareSalesSaga),
        yield takeLatest(GET_COMPARE_DASHBOARD_DATA, getCompareSessionsSaga),
        yield takeLatest(GET_COMPARE_DASHBOARD_DATA, getCompareConversionRateSaga),
        yield takeLatest(GET_COMPARE_DASHBOARD_DATA, getCompareOrderSaga),
        yield takeLatest(GET_COMPARE_DASHBOARD_DATA, getCompareAvgSalesSaga),
        yield takeLatest(GET_COMPARE_DASHBOARD_DATA, getCompareNonRenewalSaga),
        yield takeLatest(GET_COMPARE_DELIVERIES, getCompareDeliveriesSaga),
        yield takeLatest(GET_COHORT_ANALYSIS, getCohortAnalysisSaga),
    ];
}

import React from "react";
import { Flex, Text } from "theme-ui";
interface LoginCardprops { }
export const NoDataCard: React.FC<LoginCardprops> = ({ }) => {

    return (
        <Flex
            sx={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                flex: 1
            }}        >
            <Text variant="Maison18DemiNormalLh">No Data Found</Text>
        </Flex>
    );
};

import { ColumnDef } from "@tanstack/react-table";
import moment from "moment";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Card, Divider, Flex, Image } from "theme-ui";
import { RootState } from "../../../@types/reducer";
import { convertToTitleCase } from "../../utils/functions";
import Table from "../Table/Table";
interface TableCardprops {
  columns?: ColumnDef<any, any>[];
  tableData?: Array<any>;
  tableHeader?: React.ReactNode;
}
export const TableCard: React.FC<TableCardprops> = ({
  columns = [],
  tableData = [],
  tableHeader
}) => {
  const { slug } = useParams();
  const targetRef = useRef(null);
  const { dateRange } = useSelector((state: RootState) => state.app);
  const isSameDay =
    moment(dateRange?.[0]?.startDate).format("DD MMM YYYY") ===
    moment(dateRange?.[0]?.endDate).format("DD MMM YYYY");
  const transform = (data: any) => {
    const convertedData = [] as any;
    data.forEach((item: any) => {
      const newItemCurrent = {} as any;
      const newItemCompare = {} as any;
      for (const key in item) {
        if (key === "date") {
          newItemCurrent[key] = moment(item[key]?.current).isValid() ? moment(item[key]?.current).format("DD MMM YYYY") : item[key]?.current;
          newItemCompare[key] = moment(item[key]?.compare).isValid() ? moment(item[key]?.compare).format("DD MMM YYYY") : item[key]?.compare;
        } else {
          newItemCurrent[key] = item[key]?.current;
          newItemCompare[key] = item[key]?.compare;
        }
      }
      convertedData.push(newItemCurrent);
      convertedData.push(newItemCompare);
    });
    return convertedData
  }
  const convertToCSV = (data: any[]) => {
    const header = Object.keys(data[0]).map((e) => convertToTitleCase(e)).join(',');
    var rows = [] as any
    if (typeof Object.values(data[0])?.[0] === "object") {
      rows = transform(data).map((obj: any) => Object.values(obj).join(","))
    } else {
      rows = data.map(obj => Object.values(obj).join(","));
    }
    return [header, ...rows].join('\n');
  };
  const downloadCSV = () => {
    const csvData = convertToCSV(tableData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    if (isSameDay) {
      link.download = `${slug}(${moment(dateRange?.[0].startDate).format('DD MMM YYYY')}).csv`;
    }
    else
      link.download = `${slug}(${moment(dateRange?.[0].startDate).format('DD MMM YYYY')} - ${moment(dateRange?.[0].endDate).format('DD MMM YYYY')}).csv`;
    link.click();
  };
  return (
    <Card
      backgroundColor="white"
      py={20}
      px={3}
      variant="DashboardCard"
    // mt={12}
    >
      <Box>
        <Flex sx={{ justifyContent: "flex-end", alignItems: "center" }}>
          {/* <Image src="/Sort.svg" alt="img" /> */}

          <Image src="/print.svg" alt="print" sx={{ cursor: "pointer", backgroundColor: "black", borderRadius: 5 }}
            onClick={() => {
              downloadCSV()

              // generatePDF(targetRef, {
              //   filename: `Table ${slug} ${moment(dateRange?.[0]?.startDate).format("YYYY/MM/DD")} to ${moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD")}`,
              //   method: 'open', canvas: { qualityRatio: 1 }
              // })
            }}
          />
        </Flex>
      </Box>
      <Divider mt={2} mb={3} color="ECE1D7" />
      {tableHeader}
      <Table columns={columns} tableData={tableData} ref={targetRef} />
    </Card>
  );
};

import {
  ColumnDef,
  SortingState,
  // createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import React, { forwardRef, useRef, useState } from "react";
import { Box, Flex, Image, Text } from "theme-ui";

interface Tableprops {
  columns?: ColumnDef<any, any>[];
  tableData?: Array<any>;
}
export const Filter = ({ column }: any) => {
  return (
    <div className="Filter" style={{ marginTop: 5 }}>

      {column.canFilter && column.render("Filter")}
    </div>
  );
};
const Table: React.ForwardRefRenderFunction<HTMLTableElement, Tableprops> = (
  { columns = [], tableData = [] },
  ref
) => {
  // const [data, setData] = useState(tableData);
  const [sorting, setSorting] = useState<SortingState>([]);
  const finalData = React.useMemo(() => tableData, [tableData]);
  const finalColumnDef = React.useMemo(() => columns, [columns]);
  const targetRef = useRef(null);
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnFilter, setcolumnFilters] = React.useState([]);
  const table = useReactTable({
    columns: finalColumnDef,
    data: finalData,
    state: {
      sorting,
      rowSelection: rowSelection,
      columnFilters: columnFilter,
      expanded: true
    },
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    enableColumnFilters: true,
    enableFilters: true,
    enableGlobalFilter: true,
    manualFiltering: false,
    // onColumnFiltersChange: setcolumnFilters,
    onColumnOrderChange: (data) => {
    },
    getFilteredRowModel: getFilteredRowModel()
  });

  return (
    <Box className="analytics-table" sx={{ overflow: "auto", whiteSpace: 'nowrap', maxHeight: '80vh' }}>
      <table ref={ref} cellPadding={0} cellSpacing={0}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}  >
                  {header.isPlaceholder ? null : (
                    <Flex
                      sx={{
                        cursor: header.column.getCanSort() ? "pointer" : "",
                        justifyContent: "center",
                        mx: 3
                      }}
                      onClick={() => {
                        console.log("getCanGlobalFilter", header.column.setFilterValue("1"));

                      }}
                    >
                      <Filter column={header} />
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.getCanSort() && (
                        <Image
                          onClick={header.column.getToggleSortingHandler()}
                          src="/iconoir_arrow-union.svg"
                          alt="img"
                          className="sort-icon"
                          sx={{ ml: 2, height: '12px', minWidth: '12px' }}
                        />
                      )}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </Flex>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                style={{
                  backgroundColor: row?.getIsSelected() ? "#F9F4ED" : "transparent",

                }}
              >
                {row.getVisibleCells().map((cell) => {
                  const value: any = cell.getValue()

                  return (
                    <td
                      {...{
                        key: cell.id,
                        style: {
                          width: cell.column.getSize(),
                          textAlign: "center",
                        },
                      }}>
                      {typeof cell.renderValue() === "object" ?
                        <Flex sx={{ flexDirection: "column" }} >
                          <Text variant="Maison12RegularNormalLh" color="black">
                            {value?.current}
                          </Text>
                          <Text variant="Maison12RegularNormalLh" color="grey12">
                            {value?.compare}
                          </Text>
                        </Flex>
                        : flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  )
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};
export default forwardRef(Table);

import React, { FocusEventHandler } from "react";
import {
  Box,
  Flex,
  Image,
  Input,
  Text,
  ThemeUICSSObject,
  ThemeUIStyleObject,
} from "theme-ui";
interface FormInputProps {
  label?: string | undefined;
  disabled?: boolean | undefined;
  autoFocus?: boolean | undefined;
  id?: string | undefined;
  name?: string | undefined;
  placeholder?: string;
  value?: string;
  onChange?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  onBlur?: FocusEventHandler | undefined;
  handleSubmit?: () => void;
  customInputStyle?: ThemeUIStyleObject;
  type?: React.HTMLInputTypeAttribute | undefined;
}
export const FormInput: React.FC<FormInputProps> = ({
  label = "",
  placeholder = "",
  value = "",
  onChange = () => null,
  onBlur = () => null,
  handleSubmit = () => null,
  name = undefined,
  id = undefined,
  autoFocus = undefined,
  disabled = undefined,
  customInputStyle,
  type = undefined,
}) => {
  return (
    <React.Fragment>
      <Box mt={20}>
        {label ? (
          <Text
            variant="Masion16Medium20lh"
            sx={{ lineHeight: "normal" }}
            color={"grey12"}
          >
            {label}
          </Text>
        ) : null}
        <Input
          disabled={disabled}
          autoFocus={autoFocus}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          value={value}
          onKeyPress={(e) => {
            if (e?.code === "Enter" || e?.key === "Enter") {
              handleSubmit();
            }
          }}
          sx={customInputStyle}
        />
      </Box>
    </React.Fragment>
  );
};

interface SearchbarProps {
  disabled?: boolean | undefined;
  autoFocus?: boolean | undefined;
  id?: string | undefined;
  name?: string | undefined;
  placeholder?: string;
  value?: string;
  onChange?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  onBlur?: FocusEventHandler | undefined;
  handleSubmit?: () => void;
  sx?: ThemeUICSSObject;
  searchBoxStyles?: ThemeUICSSObject;
  iconStyles?: any;
  iconUrl?: string;
}
export const Searchbar: React.FC<SearchbarProps> = ({
  placeholder = "",
  value = "",
  onChange = () => null,
  onBlur = () => null,
  handleSubmit = () => null,
  name = undefined,
  id = undefined,
  autoFocus = undefined,
  disabled = undefined,
  sx,
  searchBoxStyles,
  iconStyles,
  iconUrl = "",
}) => {
  return (
    <React.Fragment>
      <Flex
        sx={{
          position: "relative",
          // maxWidth: 430,
          flex: 1,
          ...sx,
        }}
      >
        <Image
          src="/search-icon.svg"
          alt="icon"
          sx={{ top: 10, left: 20, position: "absolute" }}
          style={iconStyles}
        />
        <Input
          disabled={disabled}
          autoFocus={autoFocus}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          onKeyPress={(e: any) => {
            if (e?.code === "Enter" || e?.key === "Enter") {
              handleSubmit();
            }
          }}
          variant="Searchbar"
          sx={searchBoxStyles}
        />
        <Image
          src="/chevron-right.svg"
          alt="icon"
          sx={{ top: 10, right: 20, position: "absolute" }}
          style={iconStyles}
        />
      </Flex>
    </React.Fragment>
  );
};

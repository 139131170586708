import React from "react";
import { Link, To } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Box, Card, Flex, Image, Text, ThemeUIStyleObject } from "theme-ui";
import { LoaderChartCard } from "./LoderChartCard";
import { NoDataCard } from "./NoData";
interface ListItemsCardprops {
  title?: string;
  subTitle?: string;
  listItems?: Array<{
    listItemTitle: string,
    listItemSubTitle?: string,
    price?: string | number,
  }>;
  listItemSx?: ThemeUIStyleObject;
  titleSx?: ThemeUIStyleObject;
  isLoader?: boolean;
  count?: string | number;
  comparePer?: number;
  showPercentage?: boolean;
  isPlus?: boolean;
  toolTip?: string;
  link?: To;
}
export const ListItemsCard: React.FC<ListItemsCardprops> = ({
  title = "",
  subTitle = "",
  listItems = [],
  listItemSx,
  isLoader,
  count = "",
  showPercentage = false,
  isPlus,
  comparePer,
  titleSx,
  toolTip,
  link,
}) => {
  return (
    <Card backgroundColor="white" py={20} px={3} variant="DashboardCard" sx={{ flexDirection: "column", display: 'flex' }}>
      <Box sx={{ borderBottom: "1px solid #ECE1D7" }} mb={20}>
        <Flex
          data-tooltip-id="list-Card-tooltip"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
          mb={12}
        >
          <Text variant="Maison12RegularNormalLh" color="black" sx={titleSx}>
            {title}
          </Text>
          {link && (
            <Link to={link}>
              <Image src="/fluent_document-data-16-regular.svg" alt="img" />
            </Link>
          )}
        </Flex>
        {!isLoader && subTitle ? (
            <Text variant="Maison18DemiNormalLh" color="black" as="h4">
              {subTitle}
            </Text>
          ) : null}
        {isLoader ? null :
          <Flex sx={{ alignItems: "center", gap: 2 }}>
            <Text variant="Maison18DemiNormalLh" color="black">
              {count}
            </Text>

            {showPercentage ? (
              <Flex sx={{ alignItems: "center", gap: 1, flex: 1 }}>
                {isPlus ? <Image
                  src="/iconoir_arrow-bl.svg"
                  alt="img"
                /> :
                  <Image
                    src="/down.svg"
                    alt="img"
                  />
                }
                <Text variant="Maison11Regular13lh" color={isPlus ? "green" : "red"}>
                  {comparePer?.toFixed(0)} %
                </Text>
              </Flex>
            ) : null}
          </Flex>
        }
      </Box>
      {isLoader ?
        <LoaderChartCard />
        : listItems.length > 0 ? listItems.slice(0, 6).map((item, i) => {
          return (
            <Flex
              sx={{
                justifyContent: "space-between",
                borderBottom:
                  listItems.slice(0, 6)?.length - 1 === i ? 0 : "0.5px solid #ECE1D7",
                ...listItemSx,
              }}
              mb={listItems?.length - 1 === i ? 0 : 2}
              pb={listItems?.length - 1 === i ? 0 : 20}
              key={i}
            >
              <Text variant="Maison12Regular14lh" color="grey12" sx={{ minWidth: "20%" }}>
                {item?.listItemTitle}
              </Text>
              <Flex sx={{ flexDirection: "column", overflowWrap: "anywhere", alignItems: "flex-end" }}>
                <Text variant="Maison12Regular14lh" color="grey12">
                  {item?.listItemSubTitle}
                </Text>
                <Text variant="Maison12Regular14lh" color="grey12">
                  {item?.price}
                </Text>
              </Flex>
            </Flex>
          );
        }) :
          <NoDataCard />
      }
      <Tooltip
        id="list-Card-tooltip"
        content={toolTip}
        place="bottom"
        style={{ zIndex: 999999 }}
      />
    </Card>
  );
};

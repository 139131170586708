import {
  RealTimeScale,
  StreamingPlugin,
} from "@robloche/chartjs-plugin-streaming";
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-luxon";
import React, { forwardRef } from "react";
import { Line } from "react-chartjs-2";
import { sortNumber } from "../../utils/functions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  RealTimeScale,
  StreamingPlugin
);
interface lineProps {
  data: ChartData<"line", number[], string>;
  autoSkip?: boolean
}
const LineChart: React.ForwardRefRenderFunction<HTMLDivElement, lineProps> = (
  { data, autoSkip = true },
  ref
) => {
  const verticalLinePlugin = {
    id: "verticalLine",
    afterDraw: (chart: any) => {
      if (chart.tooltip?._active?.length) {
        let x = chart.tooltip._active[0].element.x;
        let yAxis = chart.scales.y;
        let ctx = chart.ctx;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, yAxis.top);
        ctx.lineTo(x, yAxis.bottom);
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#EA5D29";
        ctx.stroke();
        ctx.restore();
      }
    },
  };
  return (
    <div ref={ref} style={{ position: "relative" }}>
      <Line
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label(context: any) {
                  const value = ` ${context.dataset.label?.split('-').length === 1 &&
                    context.dataset.Label[0] === "01:00"
                    ?
                    context.dataset.label + ", "
                    : ""
                    }` + context.dataset.Label[context?.dataIndex] + " : " + context.parsed.y || "";
                  return value;
                },
                title(context: any) {
                  return ''
                },
                labelColor(tooltipItem: any) {
                  return {
                    backgroundColor: "white", //tooltipItem?.dataset.backgroundColor,
                    borderColor: tooltipItem?.dataset.borderColor,
                    borderDash: tooltipItem?.dataset?.borderDash || [],
                    borderWidth: tooltipItem?.dataset.borderWidth,
                  };
                },
              },
              titleColor(ctx, options) {
                return "black"
              },
              bodyColor(ctx, options) {
                return "black"
              },
              backgroundColor(ctx, options) {
                return "rgba(240, 240, 240, 0.9)"
              },
              borderColor(ctx, options) {
                return "black"
              },
              usePointStyle(ctx, options) {
                return false
              },
              bodySpacing(ctx, options) {
                return 20
              },
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          spanGaps: true,

          elements: {
            line: {
              tension: 0.4,
            },
          },
          scales: {
            x: {
              labels: data.xLabels,
              ticks: {
                autoSkip: true,
                includeBounds: true,
                maxTicksLimit: autoSkip ? 5 : 12,
                font: {
                  size: 12,
                  weight: 400,
                  family: "Maison",
                },
                color: "#99968B",
              },
              grid: {
                drawOnChartArea: false,
                display: false,
              },
              // type: "realtime",
              // realtime: {
              //   delay: 10000,
              //   frameRate: 1000,
              //   refresh: 10000,
              //   onRefresh: (chart: any) => {
              //     // chart.data.datasets.forEach((dataset: any) => {
              //     //   dataset.data.push({
              //     //     x: Date.now(),
              //     //     y: Math.random(),
              //     //   });
              //     // });
              //   },
              //   duration: 10000,
              // },
            },
            y: {
              beginAtZero: true,
              ticks: {
                callback(tickValue, index, ticks) {
                  return typeof tickValue == "number" ?
                    sortNumber(tickValue)
                    : tickValue
                },
                format: {
                  currency: "USD",
                  useGrouping: true,
                  // localeMatcher: 'en-US',
                  maximumSignificantDigits: 1,
                  maximumFractionDigits: 2,
                  compactDisplay: "short",
                  signDisplay: "auto",
                },
                font: {
                  size: 12,
                  weight: 400,
                  family: "Maison",
                },
                color: "#99968B",
              },
              grid: {
                color: "#ECE1D7",
              },
              border: {
                display: false,
              },
            },
          },

        }}
        data={data}
        height={280}
        plugins={[verticalLinePlugin]}
      />
    </div>
  );
};
export default forwardRef(LineChart);

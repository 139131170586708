import type { Theme } from 'theme-ui'

export const theme: Theme = {
  fonts: {
    // maison: 'Maison',
    // body: 'Maison',
    // heading: 'Maison',
    // monospace: 'Maison'
  },
  fontSizes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42],
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    demi: 600,
    bold: 700,
  },
  colors: {
    primary: "#EA5D29",
    primaryGreen: "#043F12",
    black: '#000000',
    white: '#ffffff',
    F9F4ED: "#F9F4ED",
    ECE1D7: "#ECE1D7",
    grey11: "#7F7F7F",
    grey12: "#99968B",
    green: "#00A611"
  },
  text: {
    Maison11Regular13lh: {
      fontSize: 11,
      fontWeight: "regular",
      lineHeight: "13.2px",
      letterSpacing: "-0.12px"
    },
    Maison12Medium14lh: {
      fontSize: 12,
      fontWeight: "medium",
      lineHeight: "14.4px"
    },
    Maison12MediumNormalLh: {
      fontSize: 12,
      fontWeight: "medium",
      lineHeight: "normal"
    },
    Maison12RegularNormalLh: {
      fontSize: 12,
      fontWeight: "regular",
      lineHeight: "normal"
    },
    Maison12Regular15lh: {
      fontSize: 12,
      fontWeight: "regular",
      lineHeight: "15px"
    },
    Maison12RegularNormal12lh: {
      fontSize: 12,
      fontWeight: "regular",
      lineHeight: "12px"
    },
    Maison12Regular14lh: {
      fontSize: 12,
      fontWeight: "regular",
      lineHeight: "14.4px"
    },
    Maison12Demi34lh: {
      fontSize: 12.303,
      fontWeight: "demi",
      lineHeight: "34px",
      letterSpacing: "-0.12px"
    },
    Maison14Medium23lh: {
      fontSize: [10, 14],
      fontWeight: "medium",
      lineHeight: [undefined, "23px"]
    },
    Maison18DemiNormalLh: {
      fontSize: 18,
      fontWeight: "demi",
      lineHeight: "normal"
    },
    Maison16Demi16lh: {
      fontSize: [12, 16],
      fontWeight: "demi",
      lineHeight: "16px",
      letterSpacing: "-0.16px",
    },
    Maison24Demi33lh: {
      fontSize: [16, 24],
      fontWeight: "demi",
      lineHeight: [undefined, "33.6px"]
    },
    Masion16Medium20lh: {
      fontSize: 16,
      fontWeight: "medium",
      lineHeight: "20px",
      letterSpacing: "-0.16px",
    },
  },
  sizes: {
    wide: 2048,
    container: 1320,
    narrow: 512,
  },
  buttons: {
    primary: {
      fontFamily: 'inherit',
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    DashboardBtn: {
      fontFamily: 'inherit',
      borderRadius: 8,
      border: "0.5px solid #7F7F7F",
      backgroundColor: "background",
      display: "flex",
      padding: "6px 12px",
      justifyContent: "center",
      alignItems: "center",
      // height: 35,
      width: ["auto"],
      cursor: "pointer"
    }

  },
  cards: {
    DashboardCard: {
      height: "100%",
      borderRadius: 12,
      minHeight: 340
    },
    LoginCard: {
      borderRadius: 12,
      width: 430
    }
  },
  images: {
    NavbarLogo: {
      width: [100, 170]
    }
  },
  forms: {
    Searchbar: {
      borderRadius: 12,
      border: "1px solid #ECE1D7",
      background: "white",
      pl: 60,
      pr: 50,
      py: 14,
      fontSize: 16,
      fontWeight: "medium",
      lineHeight: "20px",
      color: "grey11",
      width: ["100%"],
      maxWidth: "100%",
      height: 50,
      '&:focus': {
        outline: 0
      },
      '::placeholder': {
        color: "grey11",
      },
    },
    Select: {
      cursor: 'pointer',
      color: "grey11",
      width: "auto",
      fontSize: [10, 14],
      fontWeight: "medium",
      lineHeight: [undefined, "23px"],
      borderRadius: 8,
      border: "0.5px solid #7F7F7F",
      '&:focus': {
        outline: 0
      },

    }

  },
}

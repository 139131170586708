import React from "react";
import { Link, To } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import {
  Box,
  Card,
  Flex,
  Image,
  Progress,
  Text,
  ThemeUIStyleObject,
} from "theme-ui";
import { LoaderChartCard } from "./LoderChartCard";
interface ProgressBarCardprops {
  title?: string;
  listItems?: Array<{
    listItemTitle: string,
    listItemSubTitle?: string,
    value: string | number,
  }>;
  isLoader?: boolean;
  titleSx?: ThemeUIStyleObject | undefined
  count?: string | number;
  toolTip?: string;
  link?: To;
}
export const ProgressBarCard: React.FC<ProgressBarCardprops> = ({
  title = "",
  listItems = [],
  isLoader,
  titleSx,
  count = undefined,
  toolTip,
  link
}) => {
  return (
    <Card backgroundColor="white" py={20} px={3} variant="DashboardCard">
      <Box sx={{ borderBottom: "1px solid #ECE1D7" }} mb={3}>
        <Flex
          data-tooltip-id="Card-tooltip"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
          mb={12}
        >
          <Text variant="Maison12RegularNormalLh" color="black" sx={titleSx}>
            {title}
          </Text>
          {link && (
            <Link to={link}>
              <Image src="/fluent_document-data-16-regular.svg" alt="img" />
            </Link>
          )}
        </Flex>
        {isLoader ? null : count ? (
          <Text variant="Maison18DemiNormalLh" color="black">
            {count}
          </Text>
        ) : null}
      </Box>
      {isLoader ?
        <LoaderChartCard />
        : listItems.map((item, i) => {
          return (
            <Flex
              sx={{ flexDirection: "column" }}
              mb={listItems?.length - 1 === i ? 0 : 21}
              key={i}
            >
              <Flex
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                pb={12}
              >
                <Text variant="Maison12Regular14lh" color="grey12">
                  {item?.listItemTitle}
                </Text>
                <Flex sx={{ flexDirection: "column" }}>
                  <Text variant="Maison12RegularNormalLh" color="grey12">
                    {item?.value} %
                  </Text>
                  {item?.listItemSubTitle &&
                    <Text variant="Maison12Regular14lh" color="grey12">
                      {item?.listItemSubTitle}
                    </Text>
                  }
                </Flex>
              </Flex>
              <Progress
                max={1}
                value={(typeof item?.value === "string" ? parseFloat(item?.value) : item?.value) / 100}
                color="primary"
                backgroundColor="F9F4ED"
              />
            </Flex>
          );
        })}
      <Tooltip
        id="Card-tooltip"
        content={toolTip}
        place="bottom"
        style={{ zIndex: 999999 }}
      />
    </Card>
  );
};

interface ProgressBarCard2props {
  title?: string;
  listItems?: Array<{
    listItemTitle: string,
    listItemSubTitle?: string,
    value: string | number,
  }>;
  isLoader?: boolean;
  count?: string | number;
  toolTip?: string
  link?: To;
}
export const ProgressBarCard2: React.FC<ProgressBarCard2props> = ({
  title = "",
  listItems = [],
  isLoader,
  count,
  toolTip,
  link,
}) => {
  return (
    <Card backgroundColor="white" py={20} px={3} variant="DashboardCard">
      <Box sx={{ borderBottom: "1px solid #ECE1D7" }} mb={20}>
        <Flex
          data-tooltip-id="Card-tooltip"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
          mb={12}
        >
          <Text variant="Maison12RegularNormalLh" color="black">
            {title}
          </Text>
          {link && (
            <Link to={link}>
              <Image src="/fluent_document-data-16-regular.svg" alt="img" />
            </Link>
          )}
        </Flex>
        {isLoader ? null : count ? (
          <Text variant="Maison18DemiNormalLh" color="black">
            {count}
          </Text>
        ) : null}
      </Box>
      {isLoader ?
        <LoaderChartCard />
        : listItems.map((item, i) => {
          return (
            <Flex
              backgroundColor="F9F4ED"
              p="6px"
              sx={{ flexDirection: "column", borderRadius: 8 }}
              mb={listItems?.length - 1 === i ? 0 : 3}
              key={i}
            >
              <Flex
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                pb={10}
              >
                <Text variant="Maison12MediumNormalLh" color="black">
                  {item?.listItemTitle}
                </Text>
                <Flex sx={{ flexDirection: "column" }}>
                  <Text variant="Maison12RegularNormalLh" color="grey12">
                    {item?.value} %
                  </Text>
                  {item?.listItemSubTitle &&
                    <Text variant="Maison12Regular14lh" color="grey12">
                      {item?.listItemSubTitle}
                    </Text>
                  }
                </Flex>
              </Flex>
              <Progress
                max={1}
                value={(typeof item?.value === "string" ? parseFloat(item?.value) : item?.value) / 100}
                color="primaryGreen"
                backgroundColor="ECE1D7"
                style={{ height: 9 }}
              />
            </Flex>
          );
        })}
      <Tooltip
        id="Card-tooltip"
        content={toolTip}
        place="bottom"
        style={{ zIndex: 999999 }}
      />
    </Card>
  );
};
interface ProgressBarCard3props {
  title?: string;
  listItems?: Array<{
    listItemTitle: string,
    listItemSubTitle?: string,
    value: string | number,
  }>;
  listItemSx?: ThemeUIStyleObject;
  isLoader?: boolean;
  count?: string | number,
  toolTip?: string,
  link?: To,
}
export const ProgressBarCard3: React.FC<ProgressBarCard3props> = ({
  title = "",
  listItems = [],
  listItemSx,
  isLoader,
  count,
  toolTip,
  link
}) => {
  return (
    <Card backgroundColor="white" py={20} px={3} variant="DashboardCard">
      <Box sx={{ borderBottom: "1px solid #ECE1D7" }} mb={20}>
        <Flex
          data-tooltip-id="Card-tooltip"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
          mb={12}
        >
          <Text variant="Maison12RegularNormalLh" color="black">
            {title}
          </Text>
          {link && (
            <Link to={link}>
              <Image src="/fluent_document-data-16-regular.svg" alt="img" />
            </Link>
          )}
        </Flex>
        {isLoader ? null : count ? (
          <Text variant="Maison18DemiNormalLh" color="black">
            {count}
          </Text>
        ) : null}
      </Box>
      {isLoader ?
        <LoaderChartCard />
        : listItems.map((item, i) => {
          return (
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                gap: 4,
                ...listItemSx,
              }}
              mb={listItems?.length - 1 === i ? 0 : 28}
              key={i}
            >
              <Text
                variant="Maison12Regular14lh"
                color="grey12"
                sx={{ width: 88 }}
              >
                {item?.listItemTitle}
              </Text>
              <Progress
                max={1}
                value={(typeof item?.value === "string" ? parseFloat(item?.value) : item?.value) / 100}
                color="primaryGreen"
                backgroundColor="ECE1D7"
                style={{ height: 9, flex: 1, width: 173 }}
              />
              <Flex sx={{ flexDirection: "column" }}>
                <Text variant="Maison12RegularNormalLh" color="grey12">
                  {item?.value} %
                </Text>
                {item?.listItemSubTitle &&
                  <Text variant="Maison12Regular14lh" color="grey12">
                    {item?.listItemSubTitle}
                  </Text>
                }
              </Flex>
            </Flex>
          );
        })}
      <Tooltip
        id="Card-tooltip"
        content={toolTip}
        place="bottom"
        style={{ zIndex: 999999 }}
      />
    </Card>
  );
};

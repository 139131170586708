import moment from "moment";

export function calculatePreviousDates(startDateString, endDateString) {
    // Convert input date strings to Date objects
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    // Calculate the duration between start and end dates in days
    const duration = (endDate - startDate) / (1000 * 60 * 60 * 24);

    // Calculate the previous dates based on the given duration
    const previousEndDate = new Date(startDate - 1);
    const previousStartDate = new Date(previousEndDate - duration * (24 * 60 * 60 * 1000));
    return {
        previousStartDate,
        previousEndDate
    };
}
export function getlabels(date) {
    if (parseInt(date) < 25) {
        const hour = parseInt(date, 10);
        const orderTime = moment().hour(hour).minute(0).second(0);
        if (parseInt(date) === 24) {
            return "24:00"
        }
        return orderTime.format('HH:mm')
    } else if (moment(date, "YYYY-MM-DD", true).isValid() || moment(date, "YYYY/MM/DD", true).isValid()) {
        return moment(date).format("DD-MMM")
    } else {
        return date
    }

}
export function sortNumber(number) {
    if (typeof number !== 'number') {
        return 'Invalid input';
    } else if (number >= 1000000) {
        return (number / 1000000).toLocaleString('en-US', { currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'M';
    } else if (number >= 1000) {
        return (number / 1000).toLocaleString('en-US', { currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2 }) + 'K';
    } else {
        return number.toLocaleString('en-US', { currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2 });
    }
}
export function formatNumber(number) {
    if (typeof number !== 'number') {
        const newNumber = parseFloat(number || "0")
        return newNumber.toLocaleString('en-US', { currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2 });
    }
    return number.toLocaleString('en-US', { currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2 })
}
export function SumFromArray(arrayOfObjects, value) {
    return arrayOfObjects.reduce((accumulator, currentObject) => {
        return accumulator + currentObject[value];
    }, 0)
}
export function convertToTitleCase(inputString) {
    let words = inputString.split('_');
    let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    let result = capitalizedWords.join(' ');
    return result;
}

import React from "react";
import Skeleton from "react-loading-skeleton";
import { Card } from "theme-ui";
interface LoaderChartCardprops {
}
export const LoaderChartCard: React.FC<LoaderChartCardprops> = ({
}) => {
    return (
        <Card px={3} variant="DashboardCard" sx={{ minHeight: 200 }}>
            {/* <Box sx={{ borderBottom: "1px solid #ECE1D7" }} mb={10} pb={2}>
                <Skeleton style={{ width: "100%" }} />
                <Skeleton style={{ width: "100%" }} />
            </Box> */}
            <Skeleton style={{ width: "100%", height: '70%', }} />
            <Skeleton style={{ width: "100%" }} />
        </Card>
    )
};

import React from "react";
import { Helmet } from "react-helmet";

interface TitleTagprops {
    title?: string;
}
export const TitleTag: React.FC<TitleTagprops> = ({ title = "" }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title} | Delicut Analytical</title>
        </Helmet>

    )
}

import React from "react";
import { Box, Container, Flex } from "theme-ui";
import { LoginCard } from "../Components/Cards/LoginCard";

const Login = () => {
  return (
    <React.Fragment>
      <Box as="section" className="login-page">
        <Container px={3}>
          <Flex sx={{ justifyContent: "center" }}>
            <LoginCard />
          </Flex>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Login;

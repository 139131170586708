import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Flex, Image, Select, Text } from "theme-ui";
import { RootState } from "../../../@types/reducer";
import {
  setAllDateRange,
  setComDateRange,
} from "../../store/action/app.action";
import { calculatePreviousDates } from "../../utils/functions";
import { DasktopTabs } from "../../utils/globle";
import { DatePickerModal } from "../DatePickers/DatePickers";
import { Searchbar } from "../Inputs/Inputs";
import { DashboardBtn } from "./Btns";
const CohortType = [
  { item: "Subscription", val: "subscription" },
  { item: "NDD", val: "NDD" },
];
const CohortGraphType = [
  { item: "Percent", val: "percent" },
  { item: "Count", val: "count" },
];
interface TopBtnsprops {
  selectedTab?: string;
  setSelectedTab?: any;
  selectedCohortType?: string;
  setSelectedCohortType?: any;
  selectedCohortGraphType?: string;
  setSelectedCohortGraphType?: any;
  cohortTypeParam?: any;
  setCohortTypeParam?: any;
  cohortGraphTypeParam?: any;
  setCohortGraphTypeParam?: any;
  showFilter?: boolean;
  showCohortAnalysisFilter?: boolean;
  showSearch?: boolean;
  searchonChange?: ((e: string) => void) | undefined,
  searchValue?: string,
  nocom?: boolean
}
export const TopBtns: React.FC<TopBtnsprops> = ({
  selectedTab,
  setSelectedTab,
  selectedCohortType,
  setSelectedCohortType,
  selectedCohortGraphType,
  setSelectedCohortGraphType,
  cohortTypeParam,
  setCohortTypeParam,
  cohortGraphTypeParam,
  setCohortGraphTypeParam,
  showFilter = false,
  showCohortAnalysisFilter = false,
  showSearch = false,
  searchonChange,
  searchValue,
  nocom
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearchValue] = useState("");
  const [openComModal, setOpenComModal] = useState(false);
  const { dateRange, comDateRange } = useSelector(
    (state: RootState) => state.app
  );
  const dispatch = useDispatch();
  const Today =
    moment(dateRange?.[0]?.startDate).format("DD MMM YYYY") ===
    moment(new Date()).format("DD MMM YYYY") &&
    moment(dateRange?.[0]?.endDate).format("DD MMM YYYY") ===
    moment(new Date()).format("DD MMM YYYY");
  const isSameDay =
    moment(dateRange?.[0]?.startDate).format("DD MMM YYYY") ===
    moment(dateRange?.[0]?.endDate).format("DD MMM YYYY");
  return (
    <React.Fragment>
      <Box className="row" mx={-15}>
        <Box className="col-md-9">
          <Flex
            py={[2, 20]}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: ["wrap", null, "nowrap"],
              gap: 3,
            }}
          >
            <Flex
              sx={{
                alignItems: "center",
                gap: [3, null, 24],
                flexWrap: ["wrap", null, "nowrap"],
                width: "100%",
              }}
            >
              <DashboardBtn
                // title="1 Jan 2023 - 18 Nov 2023"
                title={
                  Today
                    ? "Today"
                    : isSameDay
                      ? moment(dateRange?.[0]?.startDate).format("DD MMM YYYY")
                      : moment(dateRange?.[0]?.startDate).format("DD MMM YYYY") +
                      " - " +
                      moment(dateRange?.[0]?.endDate).format("DD MMM YYYY")
                }
                showIcon={true}
                onClick={() => setOpenModal(true)}
              />
              {nocom ? null : <DashboardBtn
                showIcon
                title={
                  Today
                    ? "Yesterday"
                    : isSameDay
                      ? "Compare: " +
                      moment(comDateRange?.[0]?.startDate).format("DD MMM YYYY")
                      : "Compare: " +
                      moment(comDateRange?.[0]?.startDate).format(
                        "DD MMM YYYY"
                      ) +
                      " - " +
                      moment(comDateRange?.[0]?.endDate).format("DD MMM YYYY")
                }
                onClick={() => setOpenComModal(true)}
              />}

              {showFilter ? (
                <div
                  className="dropdown"
                  style={{ width: "auto", height: "100%" }}
                >
                  <Button
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    variant="DashboardBtn"
                    sx={{ height: "100%", width: [50, "auto"] }}
                  >
                    <Image
                      src="icons/filter.png"
                      sx={{
                        width: 20,
                      }}
                    />
                  </Button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      {DasktopTabs?.map((item) => {
                        return (
                          <Text
                            key={item}
                            className="dropdown-item"
                            variant="Maison12Demi34lh"
                            color={selectedTab === item ? "Black" : "grey11"}
                            sx={{
                              fontWeight: selectedTab === item ? 600 : 400,
                            }}
                            onClick={() => {
                              setSelectedTab(item);
                            }}
                          >
                            {item}
                          </Text>
                        );
                      })}
                    </li>
                  </ul>
                </div>
              ) : null}
              {showCohortAnalysisFilter ? (
                <React.Fragment>
                  <Select
                    variant="Select"
                    backgroundColor="white"
                    value={cohortTypeParam}
                    sx={{}}
                    onChange={(e) => {
                      setSelectedCohortType(e?.target?.value);
                      setCohortTypeParam(e?.target?.value);
                    }}
                  >
                    {CohortType?.map((item: any) => {
                      return (
                        <option key={item?.item} value={item?.val}>
                          {item?.item}
                        </option>
                      );
                    })}
                  </Select>
                  <Select
                    variant="Select"
                    backgroundColor="white"
                    value={cohortGraphTypeParam}
                    sx={{}}
                    onChange={(e) => {
                      setSelectedCohortGraphType(e?.target?.value);
                      setCohortGraphTypeParam(e?.target?.value);
                    }}
                  >
                    {CohortGraphType?.map((item: any) => {
                      return (
                        <option key={item?.item} value={item?.val}>
                          {item?.item}
                        </option>
                      );
                    })}
                  </Select>
                </React.Fragment>
              ) : null}
              {showSearch &&
                <div className="flex-fill">
                  <Searchbar
                    placeholder="Search"
                    searchBoxStyles={{}}
                    onChange={(e) => {
                      setSearchValue(e.target.value)
                      if (e.target.value == "" && searchonChange) {
                        searchonChange("")
                      }
                    }}
                    handleSubmit={() => {
                      if (searchonChange)
                        searchonChange(search)
                    }}
                    onBlur={(e: any) => {
                      if (searchonChange)
                        searchonChange(search)
                    }}
                    value={search || searchValue}
                  />
                </div>}
            </Flex>
            {/* <Flex
          sx={{
            gap: [3, 30],
            alignItems: "center",
            flexWrap: ["wrap", null, "nowrap"],
            width: ["100%", "auto"],
          }}
        >
          <Box>
            <Label>
              <Checkbox defaultChecked={true} />
              <Text variant="Maison14Medium23lh" color="grey11">
                Auto-refresh
              </Text>
            </Label>
          </Box>
          <DashboardBtn title="Customize" />
        </Flex> */}
          </Flex>
          <DatePickerModal
            dateRange={dateRange}
            openModal={openModal}
            onChange={(ranges) => {
              dispatch(setAllDateRange([ranges]));
              const preDate = calculatePreviousDates(
                ranges.startDate,
                ranges.endDate
              );
              dispatch(
                setComDateRange([
                  {
                    startDate: preDate.previousStartDate,
                    endDate: preDate.previousEndDate,
                    key: "selection",
                  },
                ])
              );
              setOpenModal(false);
            }}
            closeModal={() => {
              setOpenModal(false);
            }}
          />
          <DatePickerModal
            dateRange={comDateRange}
            openModal={openComModal}
            onChange={(ranges) => {
              dispatch(setComDateRange([ranges]));
              setOpenComModal(false);
            }}
            closeModal={() => {
              setOpenComModal(false);
            }}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

import NetworkClient from "../utils/NetworkClient";

export async function getOverAllSalesApi(obj) {
    return NetworkClient.post(`v1/dashboard/overall_sales`, obj);
}
export async function getSalesByCustomerApi(obj) {
    return NetworkClient.post(`v1/dashboard/sales_by_customers`, obj);
}
export async function getSalesByCategoryApi(obj) {
    return NetworkClient.post(`v1/dashboard/sales_by_category`, obj);
}
export async function getSalesByLocationApi(obj) {
    return NetworkClient.post(`v1/dashboard/sales_by_location`, obj);
}
export async function getSessionsApi(obj) {
    return NetworkClient.post(`v1/dashboard/session`, obj);
}
export async function getConversionRateApi(obj) {
    return NetworkClient.post(`v1/dashboard/conversion_rate`, obj);
}
export async function getSessionsByLocationApi(obj) {
    return NetworkClient.post(`v1/dashboard/session_by_location`, obj);
}
export async function getOrderApi(obj) {
    return NetworkClient.post(`v1/dashboard/total_orders`, obj);
}
export async function getAbandonedCartsApi(obj) {
    return NetworkClient.post(`v1/dashboard/abandoned_cart`, obj);
}
export async function getAvgSalesApi(obj) {
    return NetworkClient.post(`v1/dashboard/avg_sales`, obj);
}
export async function getNonRenewalApi(obj) {
    return NetworkClient.post(`v1/dashboard/non_renewals`, obj);
}
export async function getSessionsByDeviceApi(obj) {
    return NetworkClient.post(`v1/dashboard/session_by_device`, obj);
}
export async function getSessionsBySocialSourceApi(obj) {
    return NetworkClient.post(`v1/dashboard/session_by_social_source`, obj);
}
export async function getSalesBySocialSourceApi(obj) {
    return NetworkClient.post(`v1/dashboard/sales_by_social_source`, obj);
}
export async function getSessionsByTrafficSourceApi(obj) {
    return NetworkClient.post(`v1/dashboard/sales_by_traffic_source`, obj);
}
export async function getSalesByTrafficSourceApi(obj) {
    return NetworkClient.post(`v1/dashboard/session_by_browser`, obj);
}
export async function getActiveVsTotalCustomerApi(obj) {
    return NetworkClient.post(`v1/dashboard/active_customer`, obj);
}
export async function getDeliveriesApi(obj) {
    return NetworkClient.post(`v1/dashboard/total_delivery`, obj);
}
export async function getActiveOrderByMealsApi(obj) {
    return NetworkClient.post(`v1/dashboard/active_order_by_no_of_meals`, obj);
}
export async function getAutoSelectionApi(obj) {
    return NetworkClient.post(`v1/dashboard/auto_selection_delivery`, obj);
}
export async function getCustomerMigrationApi(obj) {
    return NetworkClient.post(`v1/dashboard/customer_migration`, obj);
}
export async function getMonthlyNonRenewalsApi(obj) {
    return NetworkClient.get(`v1/dashboard/monthly_plan_non_renewals`);
}
export async function getNDDFrequencyApi() {
    return NetworkClient.get(`v1/dashboard/ndd_order_frequency`);
}
export async function getNDDDropOutsApi(obj) {
    return NetworkClient.get(`v1/dashboard/ndd_drop_outs`, obj);
}
export async function getNDDMixApi(obj) {
    // return NetworkClient.post(`v1/dashboard/`, obj);
    return null
}
export async function getCustomersIngDislikeApi(obj) {
    return NetworkClient.post(`v1/dashboard/customer_ingredient_dislike`, obj);
}
export async function getDeliveryByMealSizeApi(obj) {
    return NetworkClient.post(`v1/dashboard/deliveries_by_meal_size`, obj);
}
export async function getCustomerAutoSelectApi(obj) {
    return NetworkClient.post(`v1/dashboard/customer_auto_select`, obj);
}
export async function getSkipDeliveriesApi(obj) {
    return NetworkClient.post(`v1/dashboard/skip_deliveries`, obj);
}
export async function getSkippedDeliveryApi(obj) {
    return NetworkClient.post(`v1/dashboard/skipped_delivery`, obj);
}
export async function getTopIngredientDislikeApi(obj) {
    return NetworkClient.post(`v1/dashboard/top_ingredient_dislike`, obj);
}
export async function getFailedOrdersApi(obj) {
    return NetworkClient.post(`v1/dashboard/failed_orders`, obj);
}
export async function getReportsBySlugApi(slug, obj) {
    return NetworkClient.post(`v1/report/${slug}`, obj);
}
export async function getCohortAnalysisApi(obj) {
    return NetworkClient.post(`v1/dashboard/cohert_analysis`, obj);
}
export async function getCancelOrderApi(obj) {
    return NetworkClient.post(`v1/dashboard/cancel_order`, obj);
}
export async function getReportsFilterApi(slug) {
    return NetworkClient.get(`v1/${slug}`);
}

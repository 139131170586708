import { GET_ABANDONED_CARTS_SUCCESS, GET_ACTIVE_ORDER_BY_MEALS_SUCCESS, GET_ACTIVE_VS_TOTAL_CUSTOMER_SUCCESS, GET_ALL_DASHBOARD_DATA, GET_AUTO_SELECTION_SUCCESS, GET_AVG_SALES_SUCCESS, GET_CANCEL_ORDER_SUCCESS, GET_COHORT_ANALYSIS, GET_COHORT_ANALYSIS_SUCCESS, GET_COMPARE_AVG_SALES_SUCCESS, GET_COMPARE_CONVERSION_RATE_SUCCESS, GET_COMPARE_DASHBOARD_DATA, GET_COMPARE_DELIVERIES, GET_COMPARE_DELIVERIES_SUCCESS, GET_COMPARE_FAILED_ORDERS_SUCCESS, GET_COMPARE_NON_RENEWALS_SUCCESS, GET_COMPARE_ORDER_SUCCESS, GET_COMPARE_SESSIONS_SUCCESS, GET_COMPARE_SKIPPED_DELIVERY_SUCCESS, GET_CONVERSION_RATE_SUCCESS, GET_CUSTOMER_AUTO_SELECTION_SUCCESS, GET_CUSTOMER_ING_DISLIKE_SUCCESS, GET_CUSTOMER_MIGRATION_SUCCESS, GET_DELIVERIES, GET_DELIVERIES_SUCCESS, GET_DELIVERY_BY_MEAL_SIZE_SUCCESS, GET_FAILED_ORDERS_SUCCESS, GET_MONTHLY_NON_RENEWALS_SUCCESS, GET_NDD_DROP_OUT_SUCCESS, GET_NDD_FREQUENCY_SUCCESS, GET_NDD_MIX_SUCCESS, GET_NON_RENEWALS_SUCCESS, GET_ORDER_SUCCESS, GET_OVERALL_COMPARE_SALES_SUCCESS, GET_OVERALL_SALES_SUCCESS, GET_SALES_BY_CATEGORY_SUCCESS, GET_SALES_BY_CUSTOMER_SUCCESS, GET_SALES_BY_LOCATION_SUCCESS, GET_SALES_BY_SOCIAL_SOURCE_SUCCESS, GET_SALES_BY_TRAFFIC_SOURCE_SUCCESS, GET_SESSIONS_BY_DEVICE_SUCCESS, GET_SESSIONS_BY_LOCATION_SUCCESS, GET_SESSIONS_BY_SOCIAL_SOURCE_SUCCESS, GET_SESSIONS_SUCCESS, GET_SESSION_BY_BROWSER_SUCCESS, GET_SKIPPED_DELIVERY_SUCCESS, GET_SKIP_DELIVERIES_SUCCESS, GET_TOP_ING_DISLIKE_SUCCESS } from "../types";

export const getAllDashboardData = (payload, isFirst) => ({
    type: GET_ALL_DASHBOARD_DATA,
    payload,
    isFirst
});
export const getOverAllSalesSuccess = (payload, dates) => ({
    type: GET_OVERALL_SALES_SUCCESS,
    payload,
    dates
});
export const getSalesByCustomerSuccess = (payload) => ({
    type: GET_SALES_BY_CUSTOMER_SUCCESS,
    payload
});
export const getCompareDashboardData = (payload) => ({
    type: GET_COMPARE_DASHBOARD_DATA,
    payload
});
export const getOverAllCompareSalesSuccess = (payload, dates) => ({
    type: GET_OVERALL_COMPARE_SALES_SUCCESS,
    payload,
    dates
});
export const getSalesByCategorySuccess = (payload) => ({
    type: GET_SALES_BY_CATEGORY_SUCCESS,
    payload
});
export const getSalesByLocationSuccess = (payload) => ({
    type: GET_SALES_BY_LOCATION_SUCCESS,
    payload
});
export const getSessionsSuccess = (payload, dates) => ({
    type: GET_SESSIONS_SUCCESS,
    payload, dates
});
export const getCompareSessionsSuccess = (payload, dates) => ({
    type: GET_COMPARE_SESSIONS_SUCCESS,
    payload, dates
});
export const getConversionRateSuccess = (payload) => ({
    type: GET_CONVERSION_RATE_SUCCESS,
    payload
});
export const getCompareConversionRateSuccess = (payload) => ({
    type: GET_COMPARE_CONVERSION_RATE_SUCCESS,
    payload
});
export const getSessionsByLocationSuccess = (payload) => ({
    type: GET_SESSIONS_BY_LOCATION_SUCCESS,
    payload
});
export const getOrderSuccess = (payload, dates) => ({
    type: GET_ORDER_SUCCESS,
    payload,
    dates
});
export const getCompareOrderSuccess = (payload, dates) => ({
    type: GET_COMPARE_ORDER_SUCCESS,
    payload,
    dates
});
export const getAbandonedCartsSuccess = (payload) => ({
    type: GET_ABANDONED_CARTS_SUCCESS,
    payload
});
export const getAvgSalesSuccess = (payload, dates) => ({
    type: GET_AVG_SALES_SUCCESS,
    payload,
    dates
});
export const getCompareAvgSalesSuccess = (payload, dates) => ({
    type: GET_COMPARE_AVG_SALES_SUCCESS,
    payload,
    dates
});
export const getNonRenewalSuccess = (payload, dates) => ({
    type: GET_NON_RENEWALS_SUCCESS,
    payload,
    dates
});
export const getCompareNonRenewalSuccess = (payload, dates) => ({
    type: GET_COMPARE_NON_RENEWALS_SUCCESS,
    payload,
    dates
});
export const getSessionsByDeviceSuccess = (payload) => ({
    type: GET_SESSIONS_BY_DEVICE_SUCCESS,
    payload
});
export const getSessionsBySocialSourceSuccess = (payload) => ({
    type: GET_SESSIONS_BY_SOCIAL_SOURCE_SUCCESS,
    payload
});
export const getSalesBySocialSourceSuccess = (payload) => ({
    type: GET_SALES_BY_SOCIAL_SOURCE_SUCCESS,
    payload
});
export const getSalesByTrafficSourceSuccess = (payload) => ({
    type: GET_SALES_BY_TRAFFIC_SOURCE_SUCCESS,
    payload
});
export const getSalesByBrowserSourceSuccess = (payload) => ({
    type: GET_SESSION_BY_BROWSER_SUCCESS,
    payload
});
export const getActiveVsTotalCustomerSuccess = (payload) => ({
    type: GET_ACTIVE_VS_TOTAL_CUSTOMER_SUCCESS,
    payload
});
export const getDeliveries = (payload) => ({
    type: GET_DELIVERIES,
    payload
});
export const getDeliveriesSuccess = (payload, dates) => ({
    type: GET_DELIVERIES_SUCCESS,
    payload,
    dates
});
export const getCompareDeliveries = (payload) => ({
    type: GET_COMPARE_DELIVERIES,
    payload
});
export const getCompareDeliveriesSuccess = (payload, dates) => ({
    type: GET_COMPARE_DELIVERIES_SUCCESS,
    payload,
    dates
});
export const getActiveOrderByMealsSuccess = (payload) => ({
    type: GET_ACTIVE_ORDER_BY_MEALS_SUCCESS,
    payload
});
export const getAutoSelectionSuccess = (payload) => ({
    type: GET_AUTO_SELECTION_SUCCESS,
    payload
});
export const getCustomerMigrationSuccess = (payload) => ({
    type: GET_CUSTOMER_MIGRATION_SUCCESS,
    payload
});
export const getMonthlyNonRenewalsSuccess = (payload) => ({
    type: GET_MONTHLY_NON_RENEWALS_SUCCESS,
    payload
});
export const getNDDFrequencySuccess = (payload) => ({
    type: GET_NDD_FREQUENCY_SUCCESS,
    payload
});
export const getNDDDropOutsSuccess = (payload) => ({
    type: GET_NDD_DROP_OUT_SUCCESS,
    payload
});
export const getNDDMixSuccess = (payload) => ({
    type: GET_NDD_MIX_SUCCESS,
    payload
});
export const getCustomersIngDislikeSuccess = (payload) => ({
    type: GET_CUSTOMER_ING_DISLIKE_SUCCESS,
    payload
});
export const getDeliveryByMealSizeSuccess = (payload) => ({
    type: GET_DELIVERY_BY_MEAL_SIZE_SUCCESS,
    payload
});
export const getCustomerAutoSelectSuccess = (payload) => ({
    type: GET_CUSTOMER_AUTO_SELECTION_SUCCESS,
    payload
});
export const getSkippedDeliverySuccess = (payload) => ({
    type: GET_SKIPPED_DELIVERY_SUCCESS,
    payload
});
export const getCompareSkippedDeliverySuccess = (payload) => ({
    type: GET_COMPARE_SKIPPED_DELIVERY_SUCCESS,
    payload
});
export const getTopIngredientDislikeSuccess = (payload) => ({
    type: GET_TOP_ING_DISLIKE_SUCCESS,
    payload
});
export const getFailedOrdersSuccess = (payload) => ({
    type: GET_FAILED_ORDERS_SUCCESS,
    payload
});
export const getCompareFailedOrdersSuccess = (payload) => ({
    type: GET_COMPARE_FAILED_ORDERS_SUCCESS,
    payload
});
export const getCohortAnalysis = (payload) => ({
    type: GET_COHORT_ANALYSIS,
    payload
});
export const getCohortAnalysisSuccess = (payload) => ({
    type: GET_COHORT_ANALYSIS_SUCCESS,
    payload
});
export const getCancelOrderSuccess = (payload) => ({
    type: GET_CANCEL_ORDER_SUCCESS,
    payload
});
export const getSkipDeliveriesSuccess = (payload) => ({
    type: GET_SKIP_DELIVERIES_SUCCESS,
    payload
});

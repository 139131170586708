
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Container, Divider, Flex, Image } from "theme-ui";
import { RootState } from "../../@types/reducer";
import { TopBtns } from "../Components/Buttons/TopBtns";
import { TitleTag } from "../Components/Helmet";
import { getReportsBySlug, getReportsBySlugSuccess } from "../store/action/reports.action";
import { convertToTitleCase } from "../utils/functions";
const item = ["urchase", 'total_sales', 'total_orders', "Total_Monthly_Order", "Total_Trial_Order"]
const CampingReport = () => {
  const dispatch = useDispatch();
  const { dateRange } = useSelector((state: RootState) => state.app);
  const data: any = useSelector((state: RootState) => state.reports.report_by_slug);
  const isSameDay =
    moment(dateRange?.[0]?.startDate).format("DD MMM YYYY") ===
    moment(dateRange?.[0]?.endDate).format("DD MMM YYYY");
  const convertToCSV = (data: any[]) => {
    const header = ["", ...data.map((e, i) => "campaign " + (i + 1))]
    const data_type = Object.keys(data?.[0]).filter((e) => e !== "utm_campaign")
    var rows = data_type?.map((e) => {
      return [0, 1, 2, 3, 4, 5, 6].map((d) => {
        const find = data.find((e) => e.utm_campaign == "WP_" + d)
        if (d == 0) {
          return convertToTitleCase(e)
        }
        if (find) {
          return find[e]
        }
        return ""
      })
    })
    return [header, ...rows].join('\n');
  };
  const downloadCSV = () => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    if (isSameDay) {
      link.download = `convertion_rate_by_campaign(${moment(dateRange?.[0].startDate).format('DD MMM YYYY')}).csv`;
    }
    else
      link.download = `convertion_rate_by_campaign(${moment(dateRange?.[0].startDate).format('DD MMM YYYY')} - ${moment(dateRange?.[0].endDate).format('DD MMM YYYY')}).csv`;
    link.click();
  };
  const apicall = useMemo(() => () => {
    dispatch(
      getReportsBySlug('convertion_rate_by_campaign', {
        start_date: moment(dateRange?.[0]?.startDate).format(
          "YYYY/MM/DD"
        ),
        end_date: moment(dateRange?.[0]?.endDate).format("YYYY/MM/DD"),
      })
    )
  }, [dispatch, dateRange]);
  useEffect(() => {
    apicall();
    return () => {
      dispatch(getReportsBySlugSuccess([]))
    }
  }, [apicall, dispatch]);

  return (
    <Box as="section">
      <Container px={3} >
        <TitleTag title={convertToTitleCase('convertion_rate_by_campaign')} />
        <Card backgroundColor="white" py={20} px={3} variant="DashboardCard" >
          <TopBtns nocom />
          <Box>
            <Flex sx={{ justifyContent: "flex-end", alignItems: "center" }}>
              <Image src="/print.svg" alt="print" sx={{ cursor: "pointer", backgroundColor: "black", borderRadius: 5 }}
                onClick={() => {
                  downloadCSV()
                }}
              />
            </Flex>
          </Box>
          <Divider mt={2} mb={3} color="ECE1D7" />
          <Box className="analytics-table fixed-col" sx={{
            overflow: "auto", whiteSpace: 'nowrap', maxHeight: '80vh',
          }}>
            <table style={{ width: 'auto' }}>
              <thead>
                <tr>
                  <th colSpan={3} >Camping Table</th>
                </tr>
                <tr>
                  <th ></th>
                  {data.map((item: any, index: number) => {
                    return (
                      <th style={{ position: "relative", zIndex: 0, backgroundColor: "white" }} key={'type ' + index}>
                        Camping {index + 1}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 && Object.keys(data?.[0]).filter((e) => e !== "utm_campaign")?.map((k) => {
                  const find = item.find((e) => e === k)
                  return (
                    <React.Fragment key={k}>
                      {find ?
                        <tr key={k + "sec"}>
                          <td></td>
                          {data.map((item: any, index: number) => {
                            return (
                              <td key={k + "-free-" + index}>
                              </td>
                            )
                          })}
                        </tr> : null
                      }
                      <tr key={k + "first"}>
                        <td
                          style={{
                            backgroundColor: find ? "#f2f2f2" : "white",
                            minWidth: "250px"
                          }}
                        >{convertToTitleCase(k)}</td>
                        {data.map((item: any, index: number) => {
                          return (
                            <td key={k + " - " + index} style={{ textAlign: 'center', backgroundColor: find ? "#f2f2f2" : "white", minWidth: "200px" }} >
                              {typeof item[k] === "object" ?
                                <table style={{ display: "inline-table" }}>
                                  <tbody>
                                    {item[k]?.map((data: any, indexofk: number) => {
                                      var total = 0
                                      total = item[k]?.[0]?.[Object.keys(item[k]?.[0] || {})?.[0]]
                                      return Object.keys(data).map((subItem) => {
                                        return (
                                          <tr key={k + " - " + index + " - " + subItem}>
                                            <td>{convertToTitleCase(subItem)}</td>
                                            <td>{data[subItem]}</td>
                                            {total > 0 ? <td>{((data[subItem] * 100) / total).toFixed(2)} %</td> : null}
                                          </tr>
                                        )
                                      })
                                    })}
                                  </tbody>
                                </table> :
                                item[k]
                              }
                            </td>
                          )
                        })}
                      </tr>

                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </Box>
        </Card>

      </Container>
    </Box>
  );
};

export default CampingReport;

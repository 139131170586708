import { ChartData, Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

ChartJS.register(...registerables);
interface barPrps {
    data: ChartData<"bar", number[], number>;
    isHorizontal?: boolean,
}
export const BarChart: React.FC<barPrps> = ({
    data,
    isHorizontal = false
}) => {
    return (
        <div style={{ maxWidth: 360, margin: "0 auto", }}>
            <Bar
                style={{
                    minHeight: 200
                }}
                options={{
                    indexAxis: isHorizontal ? 'y' : 'x',
                    maintainAspectRatio: true,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom'
                        },
                        title: {
                            display: false,
                            // text: 'Chart.js Bar Chart',
                        },
                        datalabels: {
                            display: false,
                            font: {
                                size: 10,
                                weight: 400,
                                family: 'Maison',
                            },
                            color: "#99968B",
                            anchor: 'end',
                            align: "top",
                            offset: -3,
                            formatter: (value, context) => {
                                const datasetArray = [] as any;
                                context.chart.data.datasets.forEach((dataset) => {
                                    if (dataset.data[context.dataIndex] != undefined) {
                                        datasetArray.push(dataset.data[context.dataIndex]);

                                    }
                                })
                                function totalSum(total: any, datapoint: any) {
                                    return total + datapoint
                                }

                                let sum = datasetArray.reduce(totalSum, 0)
                                if (context.datasetIndex === datasetArray.length - 1) {
                                    return sum;

                                } else {
                                    return "";

                                }
                            },
                        },
                        tooltip: {
                            titleColor(ctx, options) {
                                return "black"
                            },
                            bodyColor(ctx, options) {
                                return "black"
                            },
                            backgroundColor(ctx, options) {
                                return "rgba(240, 240, 240, 0.9)"
                            },
                            borderColor(ctx, options) {
                                return "black"
                            },
                        }
                    },
                    scales: {
                        x: {
                            display: true,
                            ticks: {
                                font: {
                                    size: 10,
                                    weight: 400,
                                    family: 'Maison',
                                },
                                color: "#000000"
                            },
                            stacked: true,
                            grid: {
                                display: false
                            }
                        },
                        y: {
                            display: isHorizontal ? true : false,
                            stacked: true,

                            grid: {
                                display: false
                            },
                            ticks: {
                                font: {
                                    size: 10,
                                    weight: 400,
                                    family: 'Maison',
                                },
                                color: "#000000",
                                crossAlign: "far",
                                align: 'center',
                            },
                        }
                    },

                }}
                data={data}
                height={225}
                plugins={[ChartDataLabels]} />
        </div>
    );
};

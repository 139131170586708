export const dataList = [
  {
    id: "Total Sales",
    filter: ["All", "Sales", "Marketing"],
  },
  {
    id: "Sales by Customers",
    filter: ["All", "Sales", "Marketing"],
  },
  {
    id: "Sales by Category",
    filter: ["All", "Sales", "Marketing"],
  },
  {
    id: "Sales by Location",
    filter: ["All", "Sales", "Marketing", "Customer Behavior"],
  },
  {
    id: "Sessions",
    filter: ["All", "Sales", "Marketing", "Customer Behavior"],
  },
  {
    id: "Conversion Rate",
    filter: ["All", "Sales", "Marketing", "Customer Behavior", "Conversion"],
  },
  {
    id: "Sessions by Location",
    filter: ["All", "Sales", "Marketing", "Customer Behavior"],
  },
  {
    id: "Orders",
    filter: ["All", "Sales", "Marketing", "Customer Behavior", "Order Mix"],
  },
  {
    id: "failed_orders",
    filter: ["All", "Sales", "Marketing", "Customer Behavior", "Order Mix"],
  },
  {
    id: "cancel_order",
    filter: ["All", "Sales", "Marketing", "Customer Behavior", "Order Mix"],
  },
  {
    id: "Cohort Analysis",
    filter: ["All"],
  },
  {
    id: "Abandoned Carts",
    filter: ["All", "Sales", "Marketing", "Customer Behavior"],
  },
  {
    id: "Average Sales Meal Plan & NDD",
    filter: [
      "All",
      "Sales",
      "Marketing",
      "Customer Behavior",
      "Category",
      "Order Mix",
    ],
  },
  {
    id: "Non-Renewals",
    filter: ["All", "Sales", "Marketing", "Customer Behavior", "Order Mix"],
  },
  {
    id: "Sessions by Device Type",
    filter: ["All", "Sales", "Marketing", "Customer Behavior", "Conversion"],
  },
  {
    id: "Sessions by Social Source",
    filter: ["All", "Sales", "Marketing", "Customer Behavior", "Conversion"],
  },
  {
    id: "Sales by Social Source",
    filter: ["All", "Sales", "Marketing", "Customer Behavior", "Conversion"],
  },
  {
    id: "Sessions by Traffic Source",
    filter: ["All", "Sales", "Marketing", "Customer Behavior", "Conversion"],
  },
  {
    id: "Sales by Traffic Source",
    filter: ["All", "Sales", "Marketing", "Customer Behavior", "Conversion"],
  },
  {
    id: "Session by Browser",
    filter: ["All", "Customer Behavior"],
  },
  {
    id: "Active Customers V/S Total Customers Base",
    filter: ["All", "Customer Behavior", "Category", "Order Mix"],
  },
  {
    id: "Deliveries",
    filter: ["All", "Customer Behavior", "Category", "Order Mix"],
  },
  {
    id: "skipped_delivery",
    filter: ["All", "Customer Behavior", "Category", "Order Mix"],
  },
  {
    id: "Deliveries By Meal Size",
    filter: ["All", "Customer Behavior", "Category", "Order Mix"],
  },
  {
    id: "Skip Deliveries",
    filter: ["All"],
  },
  {
    id: "Active Orders by No. of meals",
    filter: ["All", "Customer Behavior", "Category", "Order Mix"],
  },
  {
    id: "Customer Migration",
    filter: ["All", "Customer Behavior", "Category", "Order Mix"],
  },
  {
    id: "Monthly Plans Non Renewals",
    filter: ["All", "Customer Behavior", "Category", "Order Mix"],
  },
  {
    id: "NDD Order Frequency",
    filter: ["All", "Customer Behavior", "Category", "Order Mix"],
  },
  {
    id: "NDD Drop Outs",
    filter: ["All", "Customer Behavior", "Category", "Order Mix"],
  },
  {
    id: "NDD Mix",
    filter: ["All", "Customer Behavior"],
  },
  {
    id: "Auto Selection",
    filter: ["All", "Customer Behavior"],
  },
  {
    id: "customer_auto_select",
    filter: ["All", "Customer Behavior"],
  },
  {
    id: "Customers with ingredient dislikes",
    filter: ["All", "Customer Behavior"],
  },
  {
    id: "top_ingredient_dislike",
    filter: ["All", "Customer Behavior"],
  },
];
export const cohortData = {
  months: {
    "Jan 2023": [380, 164, 104, 102, 85, 103, 35, 40, 20, 25, 45, 16, 0],
    "Feb 2023": [380, 164, 104, 102, 85, 103, 35, 73, 40, 30, 60, 0, 0],
    "Mar 2023": [380, 164, 104, 102, 85, 103, 35, 33, 47, 90, 0, 0, 0],
    "Apr 2023": [380, 164, 104, 102, 85, 103, 35, 18, 43, 0, 0, 0, 0],
    "May 2023": [380, 164, 104, 102, 85, 103, 35, 3, 0, 0, 0, 0, 0],
    "Jun 2023": [380, 164, 104, 102, 85, 103, 35, 0, 0, 0, 0, 0, 0],
    "Jul 2023": [380, 164, 104, 102, 85, 103, 35, 0, 0, 0, 0, 0, 0],
    "Aug 2023": [560, 258, 171, 140, 113, 50, 0, 0, 0, 0, 0, 0, 0],
    "Sep 2023": [766, 337, 223, 186, 78, 0, 0, 0, 0, 0, 0, 0, 0],
    "Oct 2023": [872, 347, 243, 73, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    "Nov 2023": [907, 420, 93, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    "Dec 2023": [679, 235, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
};

import moment from "moment";
import { dashboardReducer } from "../../../@types/reducer";
import { formatNumber, getlabels } from "../../utils/functions";
import {
    FETCH_ERROR,
    GET_ABANDONED_CARTS_SUCCESS,
    GET_ACTIVE_ORDER_BY_MEALS_SUCCESS,
    GET_ACTIVE_VS_TOTAL_CUSTOMER_SUCCESS,
    GET_ALL_DASHBOARD_DATA,
    GET_AUTO_SELECTION_SUCCESS,
    GET_AVG_SALES_SUCCESS,
    GET_CANCEL_ORDER_SUCCESS,
    GET_COHORT_ANALYSIS,
    GET_COHORT_ANALYSIS_SUCCESS,
    GET_COMPARE_AVG_SALES_SUCCESS,
    GET_COMPARE_CONVERSION_RATE_SUCCESS,
    GET_COMPARE_DELIVERIES,
    GET_COMPARE_DELIVERIES_SUCCESS,
    GET_COMPARE_FAILED_ORDERS_SUCCESS,
    GET_COMPARE_NON_RENEWALS_SUCCESS,
    GET_COMPARE_ORDER_SUCCESS,
    GET_COMPARE_SESSIONS_SUCCESS,
    GET_COMPARE_SKIPPED_DELIVERY_SUCCESS,
    GET_CONVERSION_RATE_SUCCESS,
    GET_CUSTOMER_AUTO_SELECTION_SUCCESS,
    GET_CUSTOMER_ING_DISLIKE_SUCCESS,
    GET_CUSTOMER_MIGRATION_SUCCESS,
    GET_DELIVERIES,
    GET_DELIVERIES_SUCCESS,
    GET_DELIVERY_BY_MEAL_SIZE_SUCCESS,
    GET_FAILED_ORDERS_SUCCESS,
    GET_MONTHLY_NON_RENEWALS_SUCCESS,
    GET_NDD_DROP_OUT_SUCCESS,
    GET_NDD_FREQUENCY_SUCCESS,
    GET_NDD_MIX_SUCCESS,
    GET_NON_RENEWALS_SUCCESS,
    GET_ORDER_SUCCESS,
    GET_OVERALL_COMPARE_SALES_SUCCESS, GET_OVERALL_SALES_SUCCESS,
    GET_SALES_BY_CATEGORY_SUCCESS,
    GET_SALES_BY_CUSTOMER_SUCCESS,
    GET_SALES_BY_LOCATION_SUCCESS,
    GET_SALES_BY_SOCIAL_SOURCE_SUCCESS,
    GET_SALES_BY_TRAFFIC_SOURCE_SUCCESS,
    GET_SESSIONS_BY_DEVICE_SUCCESS,
    GET_SESSIONS_BY_LOCATION_SUCCESS,
    GET_SESSIONS_BY_SOCIAL_SOURCE_SUCCESS,
    GET_SESSIONS_SUCCESS,
    GET_SESSION_BY_BROWSER_SUCCESS,
    GET_SKIPPED_DELIVERY_SUCCESS,
    GET_SKIP_DELIVERIES_SUCCESS,
    GET_TOP_ING_DISLIKE_SUCCESS
} from "../types";

const INIT_STATE = {
    loader: false,
    apiCallList: [],
    overAllSales: {
        "result": [],
        "totalNewAmount": 0
    },
    compareAllSales: {
        "result": [],
        "totalNewAmount": 0
    },
    salesByCustomers: {
        label: [],
        data: [],
    },
    sales_by_category: {
        label: [],
        data: [],
    },
    avg_sales: {
        result: [],
        totalNewAmount: 0
    },
    avg_cmp_sales: {
        result: [],
        totalNewAmount: 0
    },
    total_orders: {
        result: [],
        totalNewAmount: 0
    },
    total_Cmp_orders: {
        result: [],
        totalNewAmount: 0
    },
    total_delivery: {
        result: [],
        totalNewAmount: 0
    },
    total_cmp_delivery: {
        result: [],
        totalNewAmount: 0
    },
    sales_by_location: {
        data: [],
        label: [],
    },
    sessionsBylocation: {
        totalSession: 0,
        data: [],
        label: [],
    },
    auto_selection_delivery: {
        data: [],
        label: []
    },
    abandoned_cart: {
        data: [],
        label: []
    },
    ndd_order_frequency: [],
    customer_ingredient_dislike: {
        data: [],
        labels: [],
        resultForCustomer: {
            total_customer: 0,
            avoid_category_customer: 0
        }
    },
    active_customer: {
        listItems: [],
        total_customers: 0
    },
    customer_migration: {
        result: {
            total_customer: 0,
            mp_to_ndd: 0,
            ndd_to_mp: 0,
            weekly_to_monthly: 0,
            monthly_to_weekly: 0,
        }
    },
    sessionData: {
        session: [],
        totalSessionsCount: 0
    },
    cmp_sessionData: {
        session: [],
        totalSessionsCount: 0
    },
    conversion_rate: {
        ratio: 0,
        conversion_rate_list: [],
        totalVisitorsCount: 0
    },
    cmp_conversion_rate: {
        conversion_rate_list: [],
        totalVisitorsCount: {
            total_Sessions: 0
        }
    },
    sessionByDevice: {
        totalSession: 0,
        data: [],
        label: [],
    },
    deliveries_by_meal_size: {
        data: [],
        label: [],
        totalDeliveries: 0
    },
    session_by_social_source: [],
    sales_by_social_source: [],
    ndd_drop_outs: {
        listItems: [],
        total_customers: 0
    },
    monthly_plan_non_renewals: {
        listItems: [],
        total_customers: 0
    },
    active_order_by_no_of_meals: [],
    non_renewals: {
        result: [],
        totalCustomers: 0,
        totalPlans: 0
    },
    cmp_non_renewals: {
        result: [],
        totalCustomers: 0
    },
    session_by_browser: [],
    sales_by_traffic_source: [],
    AllSalesData: {
        datasets: [
            {
                id: 'previous',
                data: [],
                borderColor: "#068413",
                borderWidth: 0.5,
                backgroundColor: '#00A611',
                borderDash: [5, 5],
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
            {
                id: 'current',
                data: [],
                borderColor: "#068413",
                borderWidth: 1,
                backgroundColor: '#068413',
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
        ],
        labels: [],
    },
    orders: {
        datasets: [
            {
                id: 'previous',
                data: [],
                borderColor: "#068413",
                borderWidth: 0.5,
                backgroundColor: '#00A611',
                borderDash: [5, 5],
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
            {
                id: 'current',
                data: [],
                borderColor: "#068413",
                borderWidth: 1,
                backgroundColor: '#068413',
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
        ],
        labels: [],
    },
    averageSalesData: {
        labels: [],
        datasets: []
    },
    nonRenewalsData: {
        datasets: [
            {
                id: 'previous',
                data: [],
                borderColor: "#068413",
                borderWidth: 0.5,
                backgroundColor: '#00A611',
                borderDash: [5, 5],
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
            {
                id: 'current',
                data: [],
                borderColor: "#068413",
                borderWidth: 1,
                backgroundColor: '#068413',
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
        ],
        labels: [],
    },
    dataSession: {
        datasets: [
            {
                id: 'previous',
                data: [],
                borderColor: "#068413",
                borderWidth: 0.5,
                backgroundColor: '#00A611',
                borderDash: [5, 5],
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
            {
                id: 'current',
                data: [],
                borderColor: "#068413",
                borderWidth: 1,
                backgroundColor: '#068413',
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
        ],
        labels: [],
    },
    deliveries: {
        datasets: [
            {
                id: 'previous',
                data: [],
                borderColor: "#068413",
                borderWidth: 0.5,
                backgroundColor: '#00A611',
                borderDash: [5, 5],
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
            {
                id: 'current',
                data: [],
                borderColor: "#068413",
                borderWidth: 1,
                backgroundColor: '#068413',
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
        ],
        labels: [],
    },
    customer_auto_select: {
        label: [],
        data: []
    },
    skip_deliveries: {
        total_deliveries: 0,
        skipped_deliveries: 0,
        percentage_skipped: ""
    },
    skipped_delivery_data: {
        result: [],
        totalNewAmount: 0
    },
    cmp_skipped_delivery_data: {
        result: [],
        totalNewAmount: 0
    },
    skipped_delivery: {
        datasets: [
            {
                id: 'previous',
                data: [],
                borderColor: "#068413",
                borderWidth: 0.5,
                backgroundColor: '#00A611',
                borderDash: [5, 5],
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
            {
                id: 'current',
                data: [],
                borderColor: "#068413",
                borderWidth: 1,
                backgroundColor: '#068413',
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
        ],
        labels: [],
    },
    top_ingredient_dislike: {
        dislike_plan: 0,
        total_plan: 0,
        list: []
    },
    cmp_failed_orders_data: {
        result: [],
        totalNewAmount: 0
    },
    failed_orders_data: {
        result: [],
        totalNewAmount: 0,
    },
    failed_orders: {
        datasets: [
            {
                id: 'previous',
                data: [],
                borderColor: "#068413",
                borderWidth: 0.5,
                backgroundColor: '#00A611',
                borderDash: [5, 5],
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
            {
                id: 'current',
                data: [],
                borderColor: "#068413",
                borderWidth: 1,
                backgroundColor: '#068413',
                pointBorderWidth: 1,
                pointRadius: 0.5,
                pointHoverBorderWidth: 1,
                Label: [],
            },
        ],
        labels: []
    },
    cohert_analysis: {
        months: {}
    },
    cancel_order: {
        "total_amount": 0,
        "total_count": 0,
        "cancelled": {
            "total_amount": 0,
            "total_count": 0,
            "data": []
        },
        "partially_cancelled": {
            "total_amount": 0,
            "total_count": 0,
            "data": []
        }
    }
} as dashboardReducer;

function dashboard(state = INIT_STATE, action: {
    dates: any;
    isFirst: any; type: any; payload: any;
}) {
    switch (action.type) {
        case FETCH_ERROR: {
            return {
                ...state,
                loader: false
            };
        }

        case GET_ALL_DASHBOARD_DATA: {
            return {
                ...state,
                loader: true,
                apiCallList: action?.isFirst ? [
                    GET_OVERALL_SALES_SUCCESS,
                    GET_OVERALL_COMPARE_SALES_SUCCESS,
                    GET_SALES_BY_CUSTOMER_SUCCESS,
                    GET_SALES_BY_CATEGORY_SUCCESS,
                    GET_SALES_BY_LOCATION_SUCCESS,
                    GET_SESSIONS_SUCCESS,
                    GET_COMPARE_SESSIONS_SUCCESS,
                    GET_CONVERSION_RATE_SUCCESS,
                    GET_COMPARE_CONVERSION_RATE_SUCCESS,
                    GET_SESSIONS_BY_LOCATION_SUCCESS,
                    GET_ORDER_SUCCESS,
                    GET_COMPARE_ORDER_SUCCESS,
                    GET_ABANDONED_CARTS_SUCCESS,
                    GET_AVG_SALES_SUCCESS,
                    GET_COMPARE_AVG_SALES_SUCCESS,
                    GET_NON_RENEWALS_SUCCESS,
                    GET_COMPARE_NON_RENEWALS_SUCCESS,
                    GET_SESSIONS_BY_DEVICE_SUCCESS,
                    GET_SESSIONS_BY_SOCIAL_SOURCE_SUCCESS,
                    GET_SALES_BY_SOCIAL_SOURCE_SUCCESS,
                    GET_SALES_BY_TRAFFIC_SOURCE_SUCCESS,
                    GET_SESSION_BY_BROWSER_SUCCESS,
                    GET_ACTIVE_VS_TOTAL_CUSTOMER_SUCCESS,
                    GET_ACTIVE_ORDER_BY_MEALS_SUCCESS,
                    GET_AUTO_SELECTION_SUCCESS,
                    GET_CUSTOMER_MIGRATION_SUCCESS,
                    GET_MONTHLY_NON_RENEWALS_SUCCESS,
                    GET_NDD_FREQUENCY_SUCCESS,
                    GET_NDD_DROP_OUT_SUCCESS,
                    GET_NDD_MIX_SUCCESS,
                    GET_CUSTOMER_ING_DISLIKE_SUCCESS,
                    GET_DELIVERY_BY_MEAL_SIZE_SUCCESS,
                    GET_CUSTOMER_AUTO_SELECTION_SUCCESS,
                    GET_SKIP_DELIVERIES_SUCCESS,
                    GET_SKIPPED_DELIVERY_SUCCESS,
                    GET_COMPARE_SKIPPED_DELIVERY_SUCCESS,
                    GET_TOP_ING_DISLIKE_SUCCESS,
                    GET_FAILED_ORDERS_SUCCESS,
                    GET_COMPARE_FAILED_ORDERS_SUCCESS,
                    GET_CANCEL_ORDER_SUCCESS,
                ] : state.apiCallList
            };
        }
        case GET_OVERALL_SALES_SUCCESS: {
            var findInd = state.AllSalesData.datasets.findIndex((e) => e.id === 'current') || 0
            state.AllSalesData.datasets[findInd] = {
                ...state.AllSalesData.datasets[findInd],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((i: { net_amount: string; }) => parseFloat(i.net_amount).toFixed(2)) || [],
                    Label: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            }
            return {
                ...state,
                loader: false,
                overAllSales: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_OVERALL_SALES_SUCCESS),
                AllSalesData: {
                    datasets: state.AllSalesData.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: action.payload?.result?.length >= state.compareAllSales?.result?.length ?
                        action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)) :
                        state.compareAllSales?.result?.map((i) => getlabels(i.order_date)),
                }
            };
        }
        case GET_OVERALL_COMPARE_SALES_SUCCESS: {
            var findInd2 = state.AllSalesData.datasets.findIndex((e) => e.id === 'previous') || 0
            state.AllSalesData.datasets[findInd2] = {
                ...state.AllSalesData.datasets[findInd2],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((i: { net_amount: string; }) => parseFloat(i.net_amount).toFixed(2)) || [],
                    Label: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            }
            return {
                ...state,
                loader: false,
                compareAllSales: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_OVERALL_COMPARE_SALES_SUCCESS),
                AllSalesData: {
                    datasets: state.AllSalesData.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: state.overAllSales?.result?.length >= action.payload?.result?.length ?
                        state.overAllSales?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)) :
                        action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            };
        }
        case GET_SALES_BY_CUSTOMER_SUCCESS: {
            return {
                ...state,
                loader: false,
                salesByCustomers: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_SALES_BY_CUSTOMER_SUCCESS)
            };
        }
        case GET_AVG_SALES_SUCCESS: {
            state.averageSalesData.datasets[0] = {
                ...state.averageSalesData.datasets[0],
                ...{
                    label: action?.dates?.start_date === action?.dates?.end_date ?
                        moment(action?.dates?.start_date).format("DD MMM YYYY")
                        : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((e: { avg: any; }) => e.avg || 0) || [],
                    backgroundColor: '#043F12',
                    stack: 'Stack 0',
                }
            }
            return {
                ...state,
                avg_sales: action.payload,
                averageSalesData: {
                    ...state.averageSalesData,
                    ...{
                        labels: action.payload?.result?.map((i: { title: any; }) => i.title) || [],
                        datasets: state.averageSalesData.datasets
                    }
                },
                apiCallList: state.apiCallList.filter((e) => e !== GET_AVG_SALES_SUCCESS),
            };
        }
        case GET_COMPARE_AVG_SALES_SUCCESS: {
            state.averageSalesData.datasets[1] = {
                ...state.averageSalesData.datasets[1],
                ...{
                    label: action?.dates?.start_date === action?.dates?.end_date ?
                        moment(action?.dates?.start_date).format("DD MMM YYYY")
                        : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((e: { avg: any; }) => e.avg || 0) || [],
                    backgroundColor: 'rgba(4, 63, 18, 0.2)',
                    stack: 'Stack 1',
                }
            }
            return {
                ...state,
                avg_cmp_sales: action.payload,
                averageSalesData: {
                    ...state.averageSalesData,
                    ...{
                        datasets: state.averageSalesData.datasets
                    }
                },
                apiCallList: state.apiCallList.filter((e) => e !== GET_COMPARE_AVG_SALES_SUCCESS),
            };
        }
        case GET_ORDER_SUCCESS: {
            const findInd = state.orders.datasets.findIndex((e) => e.id === 'current') || 0
            state.orders.datasets[findInd] = {
                ...state.orders.datasets[findInd],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((i: { amount: string; }) => parseFloat(i.amount).toFixed(2)) || [],
                    Label: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            }
            return {
                ...state,
                total_orders: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_ORDER_SUCCESS),
                orders: {
                    datasets: state.orders.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: action.payload?.result?.length >= state.total_Cmp_orders?.result?.length ?
                        action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)) :
                        state.total_Cmp_orders?.result?.map((i) => getlabels(i.order_date)),
                }
            };
        }
        case GET_COMPARE_ORDER_SUCCESS: {
            const findInd = state.orders.datasets.findIndex((e) => e.id === 'previous') || 0
            state.orders.datasets[findInd] = {
                ...state.orders.datasets[findInd],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((i: { amount: string; }) => parseFloat(i.amount).toFixed(2)) || [],
                    Label: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            }
            return {
                ...state,
                total_Cmp_orders: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_COMPARE_ORDER_SUCCESS),
                orders: {
                    datasets: state.orders.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: state?.total_orders?.result?.length >= action.payload?.result?.length ?
                        state?.total_orders?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)) :
                        action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            };
        }
        case GET_DELIVERIES: {
            return {
                ...state,
                apiCallList: [...state.apiCallList, ...[GET_DELIVERIES_SUCCESS]]
            }
        }
        case GET_DELIVERIES_SUCCESS: {
            const findInd = state.deliveries.datasets.findIndex((e) => e.id === 'current') || 0
            state.deliveries.datasets[findInd] = {
                ...state.deliveries.datasets[findInd],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((i: { amount: string; }) => i.amount) || [],
                    Label: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            }
            return {
                ...state,
                total_delivery: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_DELIVERIES_SUCCESS),
                deliveries: {
                    datasets: state.deliveries.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: action.payload?.result?.length >= state.total_cmp_delivery?.result?.length ?
                        action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)) :
                        state.total_cmp_delivery?.result?.map((i) => getlabels(i.order_date)),
                }
            };
        }
        case GET_COMPARE_DELIVERIES: {
            return {
                ...state,
                apiCallList: [...state.apiCallList, ...[GET_COMPARE_DELIVERIES_SUCCESS]]
            }
        }
        case GET_COMPARE_DELIVERIES_SUCCESS: {
            const findInd = state.deliveries.datasets.findIndex((e) => e.id === 'previous') || 0
            state.deliveries.datasets[findInd] = {
                ...state.deliveries.datasets[findInd],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((i: { amount: string; }) => i.amount) || [],
                    Label: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            }
            return {
                ...state,
                total_cmp_delivery: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_COMPARE_DELIVERIES_SUCCESS),
                deliveries: {
                    datasets: state.deliveries.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: state?.total_delivery?.result?.length >= action.payload?.result?.length ?
                        state?.total_delivery?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)) :
                        action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            };
        }
        case GET_SALES_BY_LOCATION_SUCCESS: {
            return {
                ...state,
                sales_by_location: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_SALES_BY_LOCATION_SUCCESS)
            };
        }
        case GET_SESSIONS_BY_LOCATION_SUCCESS: {
            return {
                ...state,
                sessionsBylocation: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_SESSIONS_BY_LOCATION_SUCCESS)
            };
        }
        case GET_AUTO_SELECTION_SUCCESS: {
            return {
                ...state,
                auto_selection_delivery: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_AUTO_SELECTION_SUCCESS)
            };
        }
        case GET_SALES_BY_CATEGORY_SUCCESS: {
            return {
                ...state,
                sales_by_category: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_SALES_BY_CATEGORY_SUCCESS)
            };
        }
        case GET_ABANDONED_CARTS_SUCCESS: {
            return {
                ...state,
                abandoned_cart: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_ABANDONED_CARTS_SUCCESS)
            };
        }
        case GET_NDD_FREQUENCY_SUCCESS: {
            return {
                ...state,
                apiCallList: state.apiCallList.filter((e) => e !== GET_NDD_FREQUENCY_SUCCESS),
                ndd_order_frequency: action?.payload?.map((item: { type: any; count: any; aed: any; }) => {
                    return {
                        listItemTitle: item.type,
                        listItemSubTitle: (item.count || 0) + " Customers",
                        price: "AED " + (formatNumber(parseFloat(item.aed)) || 0),
                    }
                }) || [],
            };
        }
        case GET_CUSTOMER_ING_DISLIKE_SUCCESS: {
            return {
                ...state,
                customer_ingredient_dislike: {
                    data: action.payload?.finalresultForChart?.map((i: { percent: any; }) => i.percent) || [],
                    labels: action.payload?.finalresultForChart?.map((i: { name: string; customer_count: any; percent: any; }) => i.name + " " + (i.customer_count || 0) + " (" + i.percent.toFixed(2) + " %)") || [],
                    resultForCustomer: action.payload?.resultForCustomer
                },
                apiCallList: state.apiCallList.filter((e) => e !== GET_CUSTOMER_ING_DISLIKE_SUCCESS)
            };
        }
        case GET_ACTIVE_VS_TOTAL_CUSTOMER_SUCCESS: {
            return {
                ...state,
                active_customer: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_ACTIVE_VS_TOTAL_CUSTOMER_SUCCESS)
            };
        }
        case GET_CUSTOMER_MIGRATION_SUCCESS: {
            return {
                ...state,
                customer_migration: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_CUSTOMER_MIGRATION_SUCCESS)
            };
        }
        case GET_SESSIONS_SUCCESS: {
            const findInd = state.dataSession.datasets.findIndex((e) => e.id === 'current') || 0
            state.dataSession.datasets[findInd] = {
                ...state.dataSession.datasets[findInd],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.session?.map((i: { count: string; }) => parseFloat(i.count).toFixed(2)) || [],
                    Label: action.payload?.session?.map((i: { path: any; }) => getlabels(i.path)),
                }
            }
            return {
                ...state,
                sessionData: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_SESSIONS_SUCCESS),
                dataSession: {
                    datasets: state.dataSession.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: action.payload?.session?.length >= state.cmp_sessionData.session?.length ?
                        action.payload?.session?.map((i: { path: any; }) => getlabels(i.path)) :
                        state.cmp_sessionData.session?.map((i) => getlabels(i.path)),
                }
            };
        }
        case GET_COMPARE_SESSIONS_SUCCESS: {
            const findInd = state.dataSession.datasets.findIndex((e) => e.id === 'previous') || 0
            state.dataSession.datasets[findInd] = {
                ...state.dataSession.datasets[findInd],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.session?.map((i: { count: string; }) => parseFloat(i.count).toFixed(2)) || [],
                    Label: action.payload?.session?.map((i: { path: any; }) => getlabels(i.path)),
                }
            }
            return {
                ...state,
                cmp_sessionData: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_COMPARE_SESSIONS_SUCCESS),
                dataSession: {
                    datasets: state.dataSession.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: state.sessionData?.session?.length >= action.payload.session?.length ?
                        state.sessionData?.session?.map((i: { path: any; }) => getlabels(i.path)) :
                        action.payload.session?.map((i: { path: any; }) => getlabels(i.path)),
                }
            };
        }
        case GET_CONVERSION_RATE_SUCCESS: {
            return {
                ...state,
                conversion_rate: {
                    ratio: action.payload?.ratio,
                    totalVisitorsCount: action.payload?.totalVisitorsCount,
                    conversion_rate_list: action.payload?.conversion_rate_list.map((item: { path: any; count: any; percentage: number }) => {
                        return {
                            listItemTitle: item.path,
                            listItemSubTitle: (item.count || 0) + " Customers",
                            price: item.percentage + " %"
                        }
                    })
                },
                apiCallList: state.apiCallList.filter((e) => e !== GET_CONVERSION_RATE_SUCCESS)
            };
        }
        case GET_COMPARE_CONVERSION_RATE_SUCCESS: {
            return {
                ...state,
                cmp_conversion_rate: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_COMPARE_CONVERSION_RATE_SUCCESS)
            };
        }
        case GET_SESSIONS_BY_DEVICE_SUCCESS: {
            return {
                ...state,
                sessionByDevice: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_SESSIONS_BY_DEVICE_SUCCESS)
            };
        }
        case GET_DELIVERY_BY_MEAL_SIZE_SUCCESS: {
            return {
                ...state,
                deliveries_by_meal_size: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_DELIVERY_BY_MEAL_SIZE_SUCCESS)
            };
        }
        case GET_SESSIONS_BY_SOCIAL_SOURCE_SUCCESS: {
            return {
                ...state,
                session_by_social_source: action.payload?.map((item: { platform: any; sessions: string; }) => {
                    return {
                        listItemTitle: item.platform,
                        listItemSubTitle: item.sessions + " sessions",
                    }
                }),
                apiCallList: state.apiCallList.filter((e) => e !== GET_SESSIONS_BY_SOCIAL_SOURCE_SUCCESS)
            };
        }
        case GET_SALES_BY_SOCIAL_SOURCE_SUCCESS: {
            return {
                ...state,
                sales_by_social_source: action.payload?.map((i: { utm_source: any; net_amount: any; }) => {
                    return {
                        listItemTitle: i.utm_source,
                        listItemSubTitle: `AED  ${(formatNumber(i.net_amount) || 0)}`
                    }
                }),
                apiCallList: state.apiCallList.filter((e) => e !== GET_SALES_BY_SOCIAL_SOURCE_SUCCESS)
            };
        }
        case GET_NDD_DROP_OUT_SUCCESS: {
            return {
                ...state,
                ndd_drop_outs: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_NDD_DROP_OUT_SUCCESS)
            };
        }
        case GET_MONTHLY_NON_RENEWALS_SUCCESS: {
            return {
                ...state,
                monthly_plan_non_renewals: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_MONTHLY_NON_RENEWALS_SUCCESS)
            };
        }
        case GET_ACTIVE_ORDER_BY_MEALS_SUCCESS: {
            return {
                ...state,
                active_order_by_no_of_meals: action.payload?.map((e: { meal: any; customer: any; }) => {
                    return {
                        listItemTitle: e.meal,
                        listItemSubTitle: (e.customer || 0) + " Customers",
                        // price: "AED " + (e.aed || 0),
                    }
                }) || [],
                apiCallList: state.apiCallList.filter((e) => e !== GET_ACTIVE_ORDER_BY_MEALS_SUCCESS)
            };
        }
        case GET_NON_RENEWALS_SUCCESS: {
            const findInd = state.nonRenewalsData.datasets.findIndex((e) => e.id === 'current') || 0
            state.nonRenewalsData.datasets[findInd] = {
                ...state.nonRenewalsData.datasets[findInd],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((i: { total: string; }) => parseFloat(i.total).toFixed(2)) || [],
                    Label: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            }
            return {
                ...state,
                non_renewals: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_NON_RENEWALS_SUCCESS),
                nonRenewalsData: {
                    datasets: state.nonRenewalsData.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: action.payload?.result?.length >= state.cmp_non_renewals?.result?.length ?
                        action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)) :
                        state.cmp_non_renewals?.result?.map((i) => getlabels(i.order_date)),
                }
            };
        }
        case GET_COMPARE_NON_RENEWALS_SUCCESS: {
            const findInd = state.nonRenewalsData.datasets.findIndex((e) => e.id === 'previous') || 0
            state.nonRenewalsData.datasets[findInd] = {
                ...state.nonRenewalsData.datasets[findInd],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((i: { total: string; }) => parseFloat(i.total).toFixed(2)) || [],
                    Label: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            }
            return {
                ...state,
                cmp_non_renewals: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_COMPARE_NON_RENEWALS_SUCCESS),
                nonRenewalsData: {
                    datasets: state.nonRenewalsData.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: state.non_renewals?.result?.length >= action.payload?.result?.length ?
                        state.non_renewals?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)) :
                        action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            };
        }
        case GET_SALES_BY_TRAFFIC_SOURCE_SUCCESS: {
            return {
                ...state,
                sales_by_traffic_source: action.payload?.map((item: { utm_source: any; net_amount: any; utm_campaign: any }) => {
                    return {
                        listItemTitle: item.utm_source,
                        price: `AED ${formatNumber(item.net_amount)}`,
                        listItemSubTitle: item.utm_campaign
                    }
                }),
                apiCallList: state.apiCallList.filter((e) => e !== GET_SALES_BY_TRAFFIC_SOURCE_SUCCESS)
            };
        }
        case GET_SESSION_BY_BROWSER_SUCCESS: {
            return {
                ...state,
                session_by_browser: action.payload?.map((item: { browser: any; count: any; }) => {
                    return {
                        listItemTitle: item.browser,
                        listItemSubTitle: item.count + " sessions",
                    }
                }),
                apiCallList: state.apiCallList.filter((e) => e !== GET_SESSION_BY_BROWSER_SUCCESS)
            };
        }
        case GET_CUSTOMER_AUTO_SELECTION_SUCCESS: {
            return {
                ...state,
                customer_auto_select: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_CUSTOMER_AUTO_SELECTION_SUCCESS)
            };
        }        
        case GET_SKIP_DELIVERIES_SUCCESS: {
            return {
                ...state,
                skip_deliveries: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_SKIP_DELIVERIES_SUCCESS)
            };
        }
        case GET_SKIPPED_DELIVERY_SUCCESS: {
            const findInd = state.skipped_delivery.datasets.findIndex((e) => e.id === 'current') || 0
            state.skipped_delivery.datasets[findInd] = {
                ...state.skipped_delivery.datasets[findInd],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((i: { amount: string; }) => parseFloat(i.amount).toFixed(2)) || [],
                    Label: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            }
            return {
                ...state,
                skipped_delivery_data: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_SKIPPED_DELIVERY_SUCCESS),
                skipped_delivery: {
                    datasets: state.skipped_delivery.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: action.payload?.result?.length >= state.cmp_skipped_delivery_data?.result?.length ?
                        action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)) :
                        state.cmp_skipped_delivery_data?.result?.map((i) => getlabels(i.order_date)),
                }
            };
        }
        case GET_COMPARE_SKIPPED_DELIVERY_SUCCESS: {
            const findInd = state.skipped_delivery.datasets.findIndex((e) => e.id === 'previous') || 0
            state.skipped_delivery.datasets[findInd] = {
                ...state.skipped_delivery.datasets[findInd],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((i: { amount: string; }) => parseFloat(i.amount).toFixed(2)) || [],
                    Label: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            }
            return {
                ...state,
                cmp_skipped_delivery_data: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_COMPARE_SKIPPED_DELIVERY_SUCCESS),
                skipped_delivery: {
                    datasets: state.skipped_delivery.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: state?.skipped_delivery_data?.result?.length >= action.payload?.result?.length ?
                        state?.skipped_delivery_data?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)) :
                        action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            };
        }
        case GET_TOP_ING_DISLIKE_SUCCESS: {
            return {
                ...state,
                top_ingredient_dislike: {
                    ...action.payload,
                    ...{
                        list: action.payload.list.map((item: { name: any; count: any; }) => {
                            return {
                                listItemTitle: item.name,
                                listItemSubTitle: (item.count || 0),
                            }
                        })
                    }
                },
                apiCallList: state.apiCallList.filter((e) => e !== GET_TOP_ING_DISLIKE_SUCCESS)
            }
        }
        case GET_FAILED_ORDERS_SUCCESS: {
            const findInd = state.failed_orders.datasets.findIndex((e) => e.id === 'current') || 0
            state.failed_orders.datasets[findInd] = {
                ...state.failed_orders.datasets[findInd],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((i: { amount: string; }) => parseFloat(i.amount).toFixed(2)) || [],
                    Label: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            }
            return {
                ...state,
                failed_orders_data: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_FAILED_ORDERS_SUCCESS),
                failed_orders: {
                    datasets: state.failed_orders.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: action.payload?.result?.length >= state.cmp_skipped_delivery_data?.result?.length ?
                        action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)) :
                        state.cmp_skipped_delivery_data?.result?.map((i) => getlabels(i.order_date)),
                }
            };
        }
        case GET_COMPARE_FAILED_ORDERS_SUCCESS: {
            const findInd = state.failed_orders.datasets.findIndex((e) => e.id === 'previous') || 0
            state.failed_orders.datasets[findInd] = {
                ...state.failed_orders.datasets[findInd],
                ...{
                    label:
                        action?.dates?.start_date === action?.dates?.end_date ?
                            moment(action?.dates?.start_date).format("DD MMM YYYY")
                            : moment(action?.dates?.start_date).format("DD MMM YYYY") + " - " + moment(action?.dates?.end_date).format("DD MMM YYYY"),
                    data: action.payload?.result?.map((i: { amount: string; }) => parseFloat(i.amount).toFixed(2)) || [],
                    Label: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            }
            return {
                ...state,
                cmp_failed_orders_data: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_COMPARE_FAILED_ORDERS_SUCCESS),
                failed_orders: {
                    datasets: state.failed_orders.datasets,
                    labels: action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                    xLabels: state?.failed_orders_data?.result?.length >= action.payload?.result?.length ?
                        state?.failed_orders_data?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)) :
                        action.payload?.result?.map((i: { order_date: any; }) => getlabels(i.order_date)),
                }
            };
        }
        case GET_COHORT_ANALYSIS: {
            return {
                ...state,
                apiCallList: [...state.apiCallList, ...[GET_COHORT_ANALYSIS_SUCCESS]]
            }
        }
        case GET_COHORT_ANALYSIS_SUCCESS: {
            return {
                ...state,
                cohert_analysis: action.payload,
                apiCallList: state.apiCallList.filter((e) => e !== GET_COHORT_ANALYSIS_SUCCESS)
            };
        }
        case GET_CANCEL_ORDER_SUCCESS: {
            return {
                ...state,
                cancel_order: action.payload?.finalData,
                apiCallList: state.apiCallList.filter((e) => e !== GET_CANCEL_ORDER_SUCCESS)
            };
        }
        default: {
            return state;
        }
    }
};

export default dashboard;

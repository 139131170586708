import React from "react";
import { useSelector } from "react-redux";
import { Link, To } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Box, Card, Divider, Flex, Image, Text } from "theme-ui";
import { RootState } from "../../../@types/reducer";
import { LoaderChartCard } from "./LoderChartCard";
interface ListItemsCardprops {
    isLoader?: boolean;
    toolTip?: string;
    link?: To;
}
export const Cancel_orderCard: React.FC<ListItemsCardprops> = ({
    isLoader,
    toolTip,
    link,
}) => {
    const { cancel_order } = useSelector((state: RootState) => state.dashboard)
    return (
        <Card backgroundColor="white" py={20} px={3} variant="DashboardCard" sx={{ flexDirection: "column", display: 'flex' }}>
            <Box sx={{ borderBottom: "1px solid #ECE1D7" }} mb={20}>
                <Flex
                    data-tooltip-id="list-Card-tooltip"
                    sx={{ justifyContent: "space-between", alignItems: "center" }}
                    mb={12}
                >
                    <Text variant="Maison12RegularNormalLh" color="black" >
                        {`${cancel_order.total_count} Cancellations (AED ${cancel_order.total_amount})`}
                    </Text>
                    {link && (
                        <Link to={link}>
                            <Image src="/fluent_document-data-16-regular.svg" alt="img" />
                        </Link>
                    )}
                </Flex>
            </Box>
            {isLoader ?
                <LoaderChartCard />
                : <Flex sx={{ flexDirection: "column" }}>
                    <Text variant="Masion16Medium20lh" color="black">
                        {`${cancel_order.partially_cancelled.total_count} Partial Cancellation (AED ${cancel_order.partially_cancelled.total_amount})`}
                    </Text>
                    {cancel_order.partially_cancelled.data.map((item) => {
                        return (
                            <Text key={item.reason} variant="Maison12RegularNormalLh" color="grey11" mt={2} ml={2}>
                                {`${item.total_count} due to ${item.reason} (AED ${item.total_amount})`}
                            </Text>
                        )
                    })}
                    <Divider />
                    <Text variant="Masion16Medium20lh" color="black" >
                        {`${cancel_order.cancelled.total_count} Complete Cancellation (AED ${cancel_order.cancelled.total_amount})`}
                    </Text>
                    {cancel_order.cancelled.data.map((item) => {
                        return (
                            <Text key={item.reason} variant="Maison12RegularNormalLh" color="grey11" mt={2} ml={2}>
                                {`${item.total_count} due to ${item.reason} (AED ${item.total_amount})`}
                            </Text>
                        )
                    })}
                </Flex>
            }
            <Tooltip
                id="list-Card-tooltip"
                content={toolTip}
                place="bottom"
                style={{ zIndex: 999999 }}
            />
        </Card>
    );
};

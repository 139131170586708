import React from "react";
import { Link, Pathname } from "react-router-dom";
import { Box, Card, Flex, Image, Text } from "theme-ui";
import { CohortChart } from "../Charts/cohort-chart";
import { LoaderChartCard } from "./LoderChartCard";
interface CohortChartCardprops {
  title?: string;
  isLoader?: boolean;
  data?: Object;
  link?: Pathname;
}
export const CohortChartCard: React.FC<CohortChartCardprops> = ({
  title = "",
  isLoader,
  data = {},
  link,
}) => {
  return (
    <Card
      backgroundColor="white"
      py={20}
      px={3}
      variant="DashboardCard"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Box sx={{ borderBottom: "1px solid #ECE1D7" }} mb={10}>
        <Flex
          sx={{ justifyContent: "space-between", alignItems: "center" }}
          mb={2}
        >
          <Text variant="Maison12RegularNormalLh" color="black">
            {title}
          </Text>
          {link && (
            <Link
              to={{
                pathname: link,
                search: "?cohert_type=subscription&cohert_graph_type=percent",
              }}
            >
              <Image src="/fluent_document-data-16-regular.svg" alt="img" />
            </Link>
          )}
        </Flex>
      </Box>
      {isLoader ? <LoaderChartCard /> : <CohortChart data={data} />}
      <Flex
        sx={{ justifyContent: "space-between", alignItems: "center" }}
        mt={20}
      >
        <Text variant="Maison12Medium14lh" color="black">
          Last Year
        </Text>
        {/* <Text variant="Maison12Regular14lh" color="grey12">
          November 2022 - October 2023
        </Text> */}
      </Flex>
    </Card>
  );
};

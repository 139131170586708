import { call, put, takeLatest } from "redux-saga/effects";
import { fetchApiError } from "../action/app.action";
import { getCohortAnalysisReportsBySlugSuccess, getReportsBySlugSuccess, getReportsFilterSuccess } from "../action/reports.action";
import { getReportsBySlugApi, getReportsFilterApi } from "../api";
import { GET_COHORT_ANALYSIS_REPORTS_BY_SLUG, GET_REPORTS_BY_SLUG, GET_REPORTS_FILTER } from "../types";

function* getReportsBySlugSaga(action) {
    try {
        const response = yield call(getReportsBySlugApi, action.slug, action.payload);
        if (response?.data?.data) {
            yield put(getReportsBySlugSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getReportsBySlugSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}

function* getCohortAnalysisReportsBySlugSaga(action) {
    try {
        const response = yield call(getReportsBySlugApi, action.slug, action.payload);
        if (response?.data?.data) {
            yield put(getCohortAnalysisReportsBySlugSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getReportsBySlugSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
function* getReportsFilterSaga(action) {
    try {
        const response = yield call(getReportsFilterApi, action.slug);
        if (response?.data?.data) {
            yield put(getReportsFilterSuccess(response?.data?.data));
        }
    } catch (error) {
        console.log("getReportsFilterSaga API Error:- ", error?.response?.data);
        yield put(fetchApiError());
    }
}
export function* watchReportsSagas() {
    return [
        yield takeLatest(GET_REPORTS_BY_SLUG, getReportsBySlugSaga),
        yield takeLatest(GET_COHORT_ANALYSIS_REPORTS_BY_SLUG, getCohortAnalysisReportsBySlugSaga),
        yield takeLatest(GET_REPORTS_FILTER, getReportsFilterSaga),
    ]
}

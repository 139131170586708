import React from "react";
import { MenuItem, menuClasses } from "react-pro-sidebar";
import { Image } from "theme-ui";
import { Icon } from "./Icon";

interface ListItemprops {
  active?: boolean;
  iconUrl?: string;
  title?: string;
  onClick?: () => void;
}

export const ListItem: React.FC<ListItemprops> = ({
  active = false,
  title = "",
  iconUrl = "",
  onClick = () => null,
}) => {
  return (
    <MenuItem
      onClick={onClick}
      rootStyles={{
        [`.${menuClasses.icon}`]: {
          minWidth: 24,
          width: 24,
          height: 24,
          marginRight: 12,
        },
        [`.${menuClasses.suffix}`]: {
          height: 24,
        },
        [`.${menuClasses.label}`]: {
          fontSize: active ? 14 : 16,
          fontWeight: active ? 600 : 500,
          lineHeight: active ? "normal" : "19.2px",
          marginTop: 2
        },
      }}
      active={active}
      icon={<Icon fill={active ? "black" : "grey12"} title={title} />}
      suffix={
        active ? (
          <Image src="/icons/ion_chevron-back-sharp.svg" alt="chevron" sx={{ width: 18, height: 18 }} />
        ) : null
      }
    >
      {title}
    </MenuItem>
  );
};

import React from "react";
import { Box, Select, Text, ThemeUIStyleObject } from "theme-ui";
interface FormSelectProps {
  label?: string | undefined;
  disabled?: boolean | undefined;
  data?: Array<{ item: string, val: string }>;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  customSx?: ThemeUIStyleObject;
}
export const FormSelect: React.FC<FormSelectProps> = ({
  label = "",
  value = "",
  disabled = undefined,
  data = [],
  onChange = () => null,
  customSx,
}) => {
  return (
    <React.Fragment>
      <Box mb={20}>
        {label ? (
          <Text variant="Maison16Demi16lh" color={"grey12"}>
            {label}
          </Text>
        ) : null}
        <Select
          variant="Select"
          backgroundColor="white"
          disabled={disabled}
          value={value}
          sx={customSx}
          onChange={onChange}
        >
          <option value={""}>
            select
          </option>
          {data?.map((item: any) => {
            return (
              <option key={item?.item} value={item?.val}>
                {item?.item}
              </option>
            );
          })}
        </Select>
      </Box>
    </React.Fragment>
  );
};

import { GET_COHORT_ANALYSIS_REPORTS_BY_SLUG, GET_COHORT_ANALYSIS_REPORTS_BY_SLUG_SUCCESS, GET_REPORTS_BY_SLUG, GET_REPORTS_BY_SLUG_SUCCESS, GET_REPORTS_FILTER, GET_REPORTS_FILTER_SUCCESS } from "../types";

const INIT_STATE = {
    report_by_slug: [],
    cohort_analysis_report_by_slug: {
        months: {}
    },
    loader: false,
    filterData: undefined
} as any;

function reports(state = INIT_STATE, action: { type: any; payload: any; }) {
    switch (action.type) {
        case GET_REPORTS_BY_SLUG: {
            return {
                ...state,
                loader: true
            };
        }
        case GET_REPORTS_BY_SLUG_SUCCESS: {
            return {
                ...state,
                loader: false,
                report_by_slug: action.payload
            };
        }
        case GET_COHORT_ANALYSIS_REPORTS_BY_SLUG: {
            return {
                ...state,
                loader: true
            };
        }
        case GET_COHORT_ANALYSIS_REPORTS_BY_SLUG_SUCCESS: {
            return {
                ...state,
                loader: false,
                cohort_analysis_report_by_slug: action.payload
            };
        }
        case GET_REPORTS_FILTER: {
            return {
                ...state,
                filterData: undefined
            };
        }
        case GET_REPORTS_FILTER_SUCCESS: {
            return {
                ...state,
                filterData: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default reports;

import { ChartData } from "chart.js";
import moment from "moment";
import React, { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, To } from "react-router-dom";
import { Tooltip } from 'react-tooltip';
import { Box, Card, Flex, Image, Text } from "theme-ui";
import { RootState } from "../../../@types/reducer";
import { formatNumber } from "../../utils/functions";
import LineChart from "../Charts/line-chart";
import { LoaderChartCard } from "./LoderChartCard";
interface LineChartCardprops {
  title?: string;
  count?: string | number;
  showPercentage?: boolean;
  data?: ChartData<"line", number[], string> | any;
  comparePer?: number;
  isPlus?: boolean;
  isLoader?: boolean;
  link?: To;
  isSingleDate?: boolean;
  id?: string;
  filterBtn?: React.ReactNode
  toolTip?: string
  autoSkip?: boolean
  onClick?: React.MouseEventHandler<HTMLImageElement> | undefined
}
const LineChartCard: React.ForwardRefRenderFunction<HTMLDivElement, LineChartCardprops> = ({
  title = "",
  count = "",
  showPercentage = true,
  data = { labels: [], datasets: [] },
  comparePer,
  isPlus,
  isLoader,
  link,
  isSingleDate = false,
  id = "",
  filterBtn = undefined,
  toolTip,
  autoSkip,
  onClick
}, ref) => {
  const { dateRange, comDateRange } = useSelector(
    (state: RootState) => state.app
  );
  useEffect(() => {
    if (title === "Total Sales") {
      console.log("data ", data);

    }
  }, [data, title])
  return (
    <Card
      backgroundColor="white"
      py={20}
      px={3}
      variant="DashboardCard"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Box sx={{ borderBottom: "1px solid #ECE1D7" }} mb={10} pb={2}>
        <Flex
          data-tooltip-id="LineChartCard-tooltip"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
          mb={3.765}
        >
          <Text variant="Maison12RegularNormalLh" color="black">
            {title}
          </Text>
          <Flex sx={{ gap: 15 }}>
            {link && (
              <Link to={link} style={{ alignItems: 'center', display: "flex" }}>
                <Image src="/fluent_document-data-16-regular.svg" alt="img" sx={{ height: 25 }} />
              </Link>
            )}
            {onClick !== undefined ? <Image
              onClick={onClick}
              src="/print.svg"
              alt="print"
              sx={{ backgroundColor: "black", borderRadius: 5, cursor: "pointer" }} /> : null}
          </Flex>
        </Flex>
        {isLoader ? null : (
          <Flex sx={{ alignItems: "center", gap: 2 }}>
            <Text variant="Maison18DemiNormalLh" color="black">
              {count}
            </Text>
            {showPercentage ? (
              <Flex sx={{ alignItems: "center", gap: 1, flex: 1 }}>
                {isPlus ? (
                  <Image src="/iconoir_arrow-bl.svg" alt="img" />
                ) : (
                  <Image src="/down.svg" alt="img" />
                )}
                <Text
                  variant="Maison11Regular13lh"
                  color={isPlus ? "green" : "red"}
                >
                  {comparePer?.toFixed(0)} %
                </Text>
              </Flex>
            ) : null}
            {filterBtn ? <Flex sx={{ justifyContent: 'flex-end', flex: 1 }}>
              {filterBtn}
            </Flex> : null}
          </Flex>
        )}
      </Box>
      {isLoader ? (
        <LoaderChartCard />
      ) : isSingleDate ? (
        <Flex
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            gap: 4,
          }}
        >
          {data?.datasets?.map((item: { id: any; data: any }) => {
            return (
              <Flex
                key={item?.id}
                sx={{ flexDirection: "column", alignItems: "center" }}
              >
                <Text variant="Maison14Medium23lh" mb={1}>
                  {item?.id === "previous"
                    ? moment(comDateRange?.[0]?.startDate).format("DD MMM YY")
                    : moment(dateRange?.[0]?.startDate).format("DD MMM YY")}
                </Text>
                <Text variant="Maison18DemiNormalLh">
                  Total {formatNumber(item?.data?.[0])}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      ) : (
        <React.Fragment>
          {/* <LineChart2 data={data} id={id} /> */}
          <LineChart data={data} autoSkip={autoSkip} ref={ref} />
        </React.Fragment>
      )}
      <Tooltip
        id="LineChartCard-tooltip"
        content={toolTip}
        place="bottom"
        style={{ zIndex: 999999 }}
      />
    </Card>
  );
};
export default forwardRef(LineChartCard);

import React, { useState } from "react";
import { Menu, Sidebar, sidebarClasses } from "react-pro-sidebar";
import { Divider } from "theme-ui";
import { ListItem } from "./ListItem";
const MenuItems = [
  {
    title: "Home",
    onClick: () => {
      window.location.href = "/"
    }
  },
  {
    title: "Campaigns",
    onClick: () => {
      window.location.href = "/report/convertion_rate_by_campaign"
    }
  },
  // {
  //   title: "Products",
  // },
  {
    title: "Price Analytics",
    onClick: () => {
      window.location.href = "/report/convertion_rate_by_campaign"
    }
  },
  // {
  //   title: "Customers",
  // },
  // {
  //   title: "Content",
  // },
  // {
  //   title: "Marketing",
  // },
  // {
  //   title: "Discounts",
  // },
  // {
  //   title: "Calendar",
  // },
  // {
  //   title: "Report Calendar",
  // },
  {
    title: "Log Out",
    onClick: () => {
      localStorage.removeItem('token')
      window.location.href = "/login"
    }
  },
];

interface SideBarprops {
  toggled?: boolean;
  setBroken?: ((broken: boolean) => void) | undefined;
  onBackdropClick?: (() => void) | undefined;
}

export const SideBar: React.FC<SideBarprops> = ({
  toggled = false,
  setBroken,
  onBackdropClick = () => null,
}) => {
  const [selectedTab, setSelectedTab] = useState("");
  return (
    <Sidebar
      toggled={toggled}
      customBreakPoint="1039px"
      onBreakPoint={setBroken}
      onBackdropClick={onBackdropClick}
      rootStyles={{
        position: "sticky",
        top: 0,
        maxHeight: "100vh",
        overflow: "auto",
        [`.${sidebarClasses.container}`]: {
          backgroundColor: "white",
          padding: 20,
          paddingTop: 30,
          height: "100vh"
        },
      }}
    >
      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            return {
              color: active ? "#000000" : "#99968B",
              backgroundColor: active ? "#F9F4ED" : undefined,
              borderRadius: 8,
              height: 43,
              padding: "9px 12px",
              marginBottom: 8,
            };
          },
        }}
      >
        {MenuItems?.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <ListItem
                title={item?.title}
                active={false}
                onClick={item?.onClick}
              />
              {item?.title === "Discounts" ? (
                <Divider my={30} color="ECE1D7" />
              ) : null}
            </React.Fragment>
          );
        })}
      </Menu>
    </Sidebar>
  );
};

import React from "react";
import { Button, Image, Text, ThemeUIStyleObject } from "theme-ui";
interface DashboardBtnprops {
  title?: string;
  onClick?: () => void;
  sx?: ThemeUIStyleObject;
  showIcon?: boolean;
  textsx?: ThemeUIStyleObject;
}
export const DashboardBtn: React.FC<DashboardBtnprops> = ({
  title = "",
  onClick = () => null,
  sx,
  showIcon = false,
  textsx
}) => {
  return (
    <Button onClick={onClick} variant="DashboardBtn" sx={sx}>
      {showIcon ? (
        <Image
          sx={{ width: 20, height: 20 }}
          mr={2}
          src="/daterange.svg"
          alt="img"
          mt="-1px"
        />
      ) : null}
      <Text variant="Maison14Medium23lh" color="grey11" sx={textsx} >
        {title}
      </Text>
    </Button>
  );
};

import React, { useEffect, useState } from "react";
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import { Flex } from "theme-ui";
import { DashboardBtn } from "../Buttons/Btns";
import { CustomModal } from "../Layouts/CustomModal";
interface DatePickerprops {
  dateRange?: Array<any>;
  onChange?: ((rangesByKey: RangeKeyDict) => void) | undefined;
}
export const DatePicker: React.FC<DatePickerprops> = ({
  dateRange = [],
  onChange,
}) => {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <DateRangePicker
      className="font-family-maison"
      rangeColors={["#EA5D29"]}
      ranges={dateRange}
      onChange={onChange}
      // showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={width < 992 ? 1 : 2}
      direction={width < 992 ? 'vertical' : 'horizontal'}
    />
  );
};
interface DatePickerModalprops {
  dateRange?: Array<any>;
  onChange: ((rangesByKey: RangeKeyDict) => void);
  openModal: boolean;
  closeModal: () => void
}
export const DatePickerModal: React.FC<DatePickerModalprops> = ({
  dateRange = [],
  onChange,
  openModal,
  closeModal
}) => {
  const [date, setDate] = useState<Array<any>>(dateRange)
  useEffect(() => {
    if (openModal)
      setDate(dateRange)
    return () => {
      setDate([])
    }
  }, [dateRange, openModal])
  return (
    <CustomModal
      styles={{ modal: { maxWidth: 940, borderRadius: 8, width: 'auto' } }}
      isOpen={openModal}
      closeModal={() => {
        closeModal()
      }}
      showCloseIcon={false}
    >
      <Flex sx={{ flexDirection: 'column' }}>
        <DatePicker
          dateRange={date}
          onChange={(ranges) => {
            setDate([ranges.selection])
          }} />
        <Flex sx={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 4 }}>
          <DashboardBtn
            title="Cancel"
            textsx={{ color: 'black' }}
            onClick={closeModal}
          />
          <DashboardBtn
            title="OK"
            textsx={{ color: 'black' }}
            onClick={() => {
              onChange(date?.[0])
            }}
          />
        </Flex>
      </Flex>
    </CustomModal>
  );
};

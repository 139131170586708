import { GET_COHORT_ANALYSIS_REPORTS_BY_SLUG, GET_COHORT_ANALYSIS_REPORTS_BY_SLUG_SUCCESS, GET_REPORTS_BY_SLUG, GET_REPORTS_BY_SLUG_SUCCESS, GET_REPORTS_FILTER, GET_REPORTS_FILTER_SUCCESS } from "../types";

export const getReportsBySlug = (slug, payload) => ({
    type: GET_REPORTS_BY_SLUG,
    slug,
    payload
});
export const getReportsBySlugSuccess = (payload) => ({
    type: GET_REPORTS_BY_SLUG_SUCCESS,
    payload
});

export const getCohortAnalysisReportsBySlug = (slug, payload) => ({
    type: GET_COHORT_ANALYSIS_REPORTS_BY_SLUG,
    slug,
    payload
});
export const getCohortAnalysisReportsBySlugSuccess = (payload, dates) => ({
    type: GET_COHORT_ANALYSIS_REPORTS_BY_SLUG_SUCCESS,
    payload,
    dates
});
export const getReportsFilter = (slug) => ({
    type: GET_REPORTS_FILTER,
    slug
});
export const getReportsFilterSuccess = (payload) => ({
    type: GET_REPORTS_FILTER_SUCCESS,
    payload,

});

export const GET_ALL_DASHBOARD_DATA = 'GET_ALL_DASHBOARD_DATA';
export const GET_COMPARE_DASHBOARD_DATA = 'GET_COMPARE_DASHBOARD_DATA';
export const GET_OVERALL_COMPARE_SALES_SUCCESS = 'GET_OVERALL_COMPARE_SALES_SUCCESS';
export const GET_OVERALL_SALES_SUCCESS = 'GET_OVERALL_SALES_SUCCESS';
export const GET_SALES_BY_CUSTOMER_SUCCESS = 'GET_SALES_BY_CUSTOMER_SUCCESS';
export const GET_SALES_BY_CATEGORY_SUCCESS = 'GET_SALES_BY_CATEGORY_SUCCESS';
export const GET_SALES_BY_LOCATION_SUCCESS = 'GET_SALES_BY_LOCATION_SUCCESS';
export const GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS';
export const GET_COMPARE_SESSIONS_SUCCESS = 'GET_COMPARE_SESSIONS_SUCCESS';
export const GET_CONVERSION_RATE_SUCCESS = 'GET_CONVERSION_RATE_SUCCESS';
export const GET_COMPARE_CONVERSION_RATE_SUCCESS = 'GET_COMPARE_CONVERSION_RATE_SUCCESS';
export const GET_SESSIONS_BY_LOCATION_SUCCESS = 'GET_SESSIONS_BY_LOCATION_SUCCESS';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_COMPARE_ORDER_SUCCESS = 'GET_COMPARE_ORDER_SUCCESS';
export const GET_ABANDONED_CARTS_SUCCESS = 'GET_ABANDONED_CARTS_SUCCESS';
export const GET_AVG_SALES_SUCCESS = 'GET_AVG_SALES_SUCCESS';
export const GET_COMPARE_AVG_SALES_SUCCESS = 'GET_COMPARE_AVG_SALES_SUCCESS';
export const GET_NON_RENEWALS_SUCCESS = 'GET_NON_RENEWALS_SUCCESS';
export const GET_COMPARE_NON_RENEWALS_SUCCESS = 'GET_COMPARE_NON_RENEWALS_SUCCESS';
export const GET_SESSIONS_BY_DEVICE_SUCCESS = 'GET_SESSIONS_BY_DEVICE_SUCCESS';
export const GET_SESSIONS_BY_SOCIAL_SOURCE_SUCCESS = 'GET_SESSIONS_BY_SOCIAL_SOURCE_SUCCESS';
export const GET_SALES_BY_SOCIAL_SOURCE_SUCCESS = 'GET_SALES_BY_SOCIAL_SOURCE_SUCCESS';
export const GET_SALES_BY_TRAFFIC_SOURCE_SUCCESS = 'GET_SALES_BY_TRAFFIC_SOURCE_SUCCESS';
export const GET_SESSION_BY_BROWSER_SUCCESS = 'GET_SESSION_BY_BROWSER_SUCCESS';
export const GET_ACTIVE_VS_TOTAL_CUSTOMER_SUCCESS = 'GET_ACTIVE_VS_TOTAL_CUSTOMER_SUCCESS';
export const GET_DELIVERIES = 'GET_DELIVERIES';
export const GET_DELIVERIES_SUCCESS = 'GET_DELIVERIES_SUCCESS';
export const GET_COMPARE_DELIVERIES = 'GET_COMPARE_DELIVERIES';
export const GET_COMPARE_DELIVERIES_SUCCESS = 'GET_COMPARE_DELIVERIES_SUCCESS';
export const GET_ACTIVE_ORDER_BY_MEALS_SUCCESS = 'GET_ACTIVE_ORDER_BY_MEALS_SUCCESS';
export const GET_AUTO_SELECTION_SUCCESS = 'GET_AUTO_SELECTION_SUCCESS';
export const GET_CUSTOMER_MIGRATION_SUCCESS = 'GET_CUSTOMER_MIGRATION_SUCCESS';
export const GET_MONTHLY_NON_RENEWALS_SUCCESS = 'GET_MONTHLY_NON_RENEWALS_SUCCESS';
export const GET_NDD_FREQUENCY_SUCCESS = 'GET_NDD_FREQUENCY_SUCCESS';
export const GET_NDD_DROP_OUT_SUCCESS = 'GET_NDD_DROP_OUT_SUCCESS';
export const GET_NDD_MIX_SUCCESS = 'GET_NDD_MIX_SUCCESS';
export const GET_CUSTOMER_ING_DISLIKE_SUCCESS = 'GET_CUSTOMER_ING_DISLIKE_SUCCESS';
export const GET_DELIVERY_BY_MEAL_SIZE_SUCCESS = 'GET_DELIVERY_BY_MEAL_SIZE_SUCCESS';
export const GET_CUSTOMER_AUTO_SELECTION_SUCCESS = 'GET_CUSTOMER_AUTO_SELECTION_SUCCESS';
export const GET_SKIP_DELIVERIES_SUCCESS = 'GET_SKIP_DELIVERIES_SUCCESS';
export const GET_SKIPPED_DELIVERY_SUCCESS = 'GET_SKIPPED_DELIVERY_SUCCESS';
export const GET_COMPARE_SKIPPED_DELIVERY_SUCCESS = "GET_COMPARE_SKIPPED_DELIVERY_SUCCESS";
export const GET_TOP_ING_DISLIKE_SUCCESS = "GET_TOP_ING_DISLIKE_SUCCESS";
export const GET_FAILED_ORDERS_SUCCESS = "GET_FAILED_ORDERS_SUCCESS";
export const GET_COMPARE_FAILED_ORDERS_SUCCESS = "GET_COMPARE_FAILED_ORDERS_SUCCESS";
export const SET_ALL_DATE_RANGE = 'SET_ALL_DATE_RANGE';
export const SET_COM_DATE_RANGE = 'SET_COM_DATE_RANGE';
export const FETCH_ERROR = 'FETCH_ERROR';
export const GET_REPORTS_BY_SLUG = 'GET_REPORTS_BY_SLUG';
export const GET_REPORTS_BY_SLUG_SUCCESS = 'GET_REPORTS_BY_SLUG_SUCCESS';
export const GET_COHORT_ANALYSIS = 'GET_COHORT_ANALYSIS';
export const GET_COHORT_ANALYSIS_SUCCESS = 'GET_COHORT_ANALYSIS_SUCCESS';
export const GET_COHORT_ANALYSIS_REPORTS_BY_SLUG = 'GET_COHORT_ANALYSIS_REPORTS_BY_SLUG';
export const GET_COHORT_ANALYSIS_REPORTS_BY_SLUG_SUCCESS = 'GET_COHORT_ANALYSIS_REPORTS_BY_SLUG_SUCCESS';
export const GET_SESSION_BY_TRAFFIC_SUCCESS = 'GET_SESSION_BY_TRAFFIC_SUCCESS';
export const GET_CANCEL_ORDER_SUCCESS = 'GET_CANCEL_ORDER_SUCCESS';
export const GET_REPORTS_FILTER = 'GET_REPORTS_FILTER';
export const GET_REPORTS_FILTER_SUCCESS = 'GET_REPORTS_FILTER_SUCCESS';

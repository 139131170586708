export const colorPalettes = [
    "#EA5D29", "#EF9700", "#043F12", "#8DD0E8", "#477C7C", "#88ADAD", "#6B8439", "#C4AD3A", "#917136", "#CB946F", "#D57903", "#AC7950", "#DCD5D7",
]
export const DasktopTabs = [
    "All",
    "Sales",
    "Marketing",
    "Category",
    "Conversion",
    "Order Mix",
    "Customer Behavior",
];

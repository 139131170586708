import React from "react";
import { Flex, Text } from "theme-ui";

interface Tabsprops {
  isSelected: boolean;
  name: string;
  onClick: () => void;
}
interface TabsTextprops {
  isSelected: boolean;
  name: string;
  onClick?: () => void;
  className?: string | undefined
}
export const TabText: React.FC<TabsTextprops> = ({ isSelected = false, className, onClick, name }) => {
  return (
    <Text
      className={className}
      variant="Maison12Demi34lh"
      color={isSelected ? "white" : "grey11"}
      sx={{ fontWeight: isSelected ? 600 : 400 }}
      onClick={onClick}
    >
      {name}
    </Text>
  )
}
export const Tabs: React.FC<Tabsprops> = ({
  isSelected = false,
  name = "",
  onClick = () => null,
}) => {
  return (
    <Flex
      onClick={onClick}
      sx={{
        backgroundColor: isSelected ? "#000000" : "transparent",
        borderRadius: 8,
        justifyContent: "center",
        alignItems: "center",
        border: isSelected ? 0 : "0.5px solid #7F7F7F",
        height: 38,
        cursor: "pointer",
      }}
      px={20}
      py="2px"
    >
      <TabText
        isSelected={isSelected}
        name={name}
      />
    </Flex>
  );
};

import React from "react";
import { Tooltip } from "react-tooltip";
import { Box, Card, Flex, Image, Text } from "theme-ui";
import { DoughnutChart } from "../Charts/pie-chart";
import { LoaderChartCard } from "./LoderChartCard";
import { NoDataCard } from "./NoData";
import { Link, To } from "react-router-dom";
interface DoughnutChartCardprops {
  title?: string;
  subTitle?: string;
  labels?: Array<string>;
  Data?: Array<number>;
  id?: string;
  isLoader?: boolean;
  toolTip?: string
  link?: To;
}
export const DoughnutChartCard: React.FC<DoughnutChartCardprops> = ({
  title = "",
  subTitle = "",
  labels = [],
  Data = [],
  id = "",
  isLoader,
  toolTip,
  link,
}) => {
  return (
    <Card
      backgroundColor="white"
      py={20}
      px={3}
      variant="DashboardCard"
      sx={{ display: "flex", flexDirection: "column", position: 'relative' }}
    >
      <Box sx={{ borderBottom: "1px solid #ECE1D7" }} pb={2} mb={12}>
        <Flex data-tooltip-id="Doughnut-Card-tooltip" sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Text variant="Maison12RegularNormalLh" color="black">
            {title}
          </Text>
          {link && (
            <Link to={link}>
              <Image src="/fluent_document-data-16-regular.svg" alt="img" />
            </Link>
          )}
        </Flex>
        {!isLoader && subTitle ? (
          <Text variant="Maison18DemiNormalLh" color="black" mt={3.765} as="h4">
            {subTitle}
          </Text>
        ) : null}
      </Box>
      {isLoader ? (
        <LoaderChartCard />
      ) : (
        <React.Fragment>
          {Data.length > 0 && Data.filter(e => e).length > 0 ? (
            <DoughnutChart labels={labels} Data={Data} id={id} />
          ) : (
            <NoDataCard />
          )}
        </React.Fragment>
      )}
      <Tooltip
        id="Doughnut-Card-tooltip"
        content={toolTip}
        place="bottom"
        style={{ zIndex: 999999 }}
      />
    </Card>
  );
};
